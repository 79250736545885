import {
    Button,
    TextField,
    IconButton,
    Box,
    ButtonGroup,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { Add, Close, Delete, Edit, Save, Visibility } from "@mui/icons-material";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Paper from "@mui/material/Paper";
  import { toast, ToastContainer } from "react-toastify";
  import { useEffect } from "react";
  import { postRequest,getRequest } from "../../request";
  import DeleteModal from "./deleteModal";
  import {useTheme}  from "@mui/material";
  const styles = {
    box2: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "45rem",
      display: "flex",
      flexDirection: "column",
    },
    box3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "38rem",
      display: "flex",
      flexDirection: "column",
    },
    industryBox3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "32rem",
      display: "flex",
      flexDirection: "column",
    },
    unitBox: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      padding: "0.5rem",
    },
    unit: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    innerBox: {
      display: "grid",
      width: "100%",
      padding: "1rem",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
    },
    table2: {
      margin: "0rem 5rem",
      width: "auto",
      maxHeight: "23rem",
      overflow: "scrollY",
    },
    table3: {
      margin: "0rem 1rem",
      width: "auto",
      maxHeight: "35rem",
      overflow: "scrollY",
    },
    newclass1: {
      position: "relative",
      width: "unset",
      background: "none",
      top:'-1.2rem',
      left:'1rem',
    },
    newclass2: {
      position: "relative",
      width: "unset",
      background: "none",
      top:'-1.2rem',
      left:'1rem',
    },
    myclass:{
      position: "absolute",
      width: "unset",
      background: "none",
      left: "75%",
      top: "34.5%",
    },
  };
function DefineIndustry({industryList, setIndustryList, selectedIndustryCategory}) {
    const [selectedIndustry, setselectedIndustry] = useState(-1);
    const theme = useTheme();
    const code = (Math.random() * 1000000).toFixed(0);
    const [industry, setIndustry] = useState({
      industryCode: `industry-${code}`,
      industryName: "",
      industryLocalName: "",
    });
    const [openModal, setOpenModal] = useState(false);
    const [deletedIndustryId, setDeletedIndustryId] = useState(-1);
    const [deleteSuccessful, setDeleteSuccessful] = useState(false);
    const handleChange = (event) => {
      setIndustry({
        ...industry,
        [event.target.name]: event.target.value,
      });
    };
    useEffect(() => {
      if(deleteSuccessful){
        setIndustryList(prevstate=>[...prevstate.filter(x=>x.industryId !== deletedIndustryId)]);
        setDeleteSuccessful(false);
        setDeletedIndustryId(-1);
      }
    }, [deleteSuccessful]);

    const handleRemoveIndustry = async(id) => {
      setOpenModal(true);
      setDeletedIndustryId(id);
    };    
  
    const handleEdit = async() => {
      const currentIndustry= {
        ...industry,
        industryId:selectedIndustry,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Industry/edit`,currentIndustry)
      
      if(message === 'ok'){
        industryList[
          industryList.findIndex(
            (x) => x.industryId === selectedIndustry
          )
        ] = industry;
        setIndustryList(industryList);
        setselectedIndustry(-1);
        setIndustry({
          industryCode: `industry-${code}`,
          industryName: "",
          industryLocalName: "",
        });
        toast.success('industry edited successfully!')
      }
    };
  
    const addIndustry=async()=>{
      const currentIndustry= {
        ...industry,
        inductrySubCategoryId:selectedIndustryCategory,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Industry`,currentIndustry)
      if(message === 'ok'){
        industry.industryId=data;
        setIndustryList(prevstate=>[
          ...prevstate,industry
        ]);
        setIndustry({
          industryCode: `industry-${(Math.random() * 1000000).toFixed(0)}`,
          industryName: "",
          industryLocalName: "",
        });
        toast.success('industry added successfully!');
      }
    }
  return (
    <div>
        <Box>
            <Box sx={ styles.box3 }>
              <Typography variant="h5" color="initial" sx={selectedIndustryCategory?styles.newclass1:styles.newclass2}>
                <span>Industry</span>
              </Typography>
              <div style={styles.innerBox}>
                <TextField
                  variant="outlined"
                  label="Code"
                  name="industryCode"
                  value={industry.industryCode}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  label="Name"
                  name="industryName"
                  value={industry.industryName}
                  onChange={handleChange}
                />
              </div>
              <div style={styles.innerBox}>
                <TextField
                  variant="outlined"
                  label="localName"
                  name="industryLocalName"
                  value={industry.industryLocalName}
                  onChange={handleChange}
                />
                <div style={{ width: "211px" }}></div>
              </div>
              <div style={styles.innerBox}>
                <ButtonGroup>
                  {selectedIndustry !== -1 ? null : (
                    <Button
                      variant="contained"
                      disabled={
                        !industry.industryLocalName ||
                        !industry.industryName ||
                        !industry.industryCode
                      }
                      color="primary"
                      sx={{ color: "#fff" }}
                      onClick={addIndustry}
                    >
                      save <Save />
                    </Button>
                  )}
                  {selectedIndustry !==-1 ? (
                    <Button
                      variant="contained"
                      color="edit"
                      sx={{ color: "#fff" }}
                      onClick={handleEdit}
                    >
                      edit <Edit />
                    </Button>
                  ) : null}
                  {selectedIndustry !==-1 ?<Button
                    variant="contained"
                    color="danger"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setselectedIndustry(-1);
                      setIndustry(
                        {
                          industryCode: `industry-${code}`,
                          industryName: "",
                          industryLocalName: "",
                        }
                      );
                    }}
                  >
                    cancel <Close />
                  </Button>:null}
                </ButtonGroup>
                <div style={{ width: "211px" }}></div>
              </div>
  
              {industryList.length?(
                <TableContainer
                  sx={ styles.table3 }
                  component={Paper}
                >
                  <Table
                    sx={{ width: "95%", margin: "0.5rem" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>code</TableCell>
                        <TableCell align="right">name</TableCell>
                        <TableCell align="right">edit</TableCell>
                        <TableCell align="right">remove</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {industryList.map((row) => (
                        <TableRow
                          key={row.industryId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.industryCode}
                          </TableCell>
                          <TableCell align="right">
                            {row.industryName}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                setselectedIndustry(row.industryId);
                                setIndustry(
                                  industryList.find(
                                    (x) =>
                                      x.industryId ===
                                      row.industryId
                                  )
                                );
                              }}
                            >
                              <Edit color="edit"/>
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label=""
                              onClick={() =>
                                handleRemoveIndustry(
                                  row.industryId
                                )
                              }
                            >
                              <Delete color='danger'/>
                            </IconButton>
                          </TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ):null}
              
            </Box>
          </Box>
          {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="industry" deleteURL={`${process.env.REACT_APP_BASE_URL}/Industry/delete/${deletedIndustryId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
            :null}
    </div>
  )
}

export default DefineIndustry