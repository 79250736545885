import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton, Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link, useHistory } from "react-router-dom";
import { AccountCircle, Delete, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Box, useTheme } from "@mui/system";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import DeleteModal from '../components/deleteModal';
import { getRequest } from "../../request";
import SideBar from "../components/sideBar";
import jwtDecode from "jwt-decode";


const styles = {
  container:{
    margin:'0 15rem',
    height:'85vh'
  },
  btn:{
    margin:'2rem 0rem'
  }
}
const bg = require("./../../images/banner/bnr5.jpg");
function ProducerList() {
  const theme = useTheme()
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedProducer, setSelectedProducer] = useState(-1);
  const [producerList, setProducerList] = useState([]);
  const token = localStorage.getItem('token');
  let decode={};
  if(token){
    decode = jwtDecode(token);
  } 
  useEffect(() => {
    fetchProducers();
  }, []);
  const fetchProducers = async () => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Producer`)
      if(data && Object.keys(data).length){
        setProducerList(data);
      }else{
        toast.error(error);
      }
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  return (
    <div >
      <DeleteModal deleteURL={`${process.env.REACT_APP_BASE_URL}/Producer/delete/${selectedProducer}`} setOpenDeleteModal={setIsOpen} parameterName="producer" openDeleteModal={isOpen}/>
      <Header12/>
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Unit Type</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Unit Type</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      <Box sx={styles.container}>
        <Box sx={{width:'100%',display:'flex',justifyContent:'space-between'}}>
          <Button sx={styles.btn} variant="contained" color="primary" onClick={()=>history.push('/producer/0')}>
            new producer <AddIcon/>
          </Button>
          {decode.role === 'SuperAdmin'?
          <Button color='secondary' sx={styles.btn}  variant='contained' onClick={toggleDrawer( true)} >user options <AccountCircle/></Button>
          :null}
        </Box>
        {producerList?.length? (
          <TableContainer
          sx={{maxHeight:'78vh'}}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead sx={{backgroundColor:theme.palette.primary.main}}>
              <TableRow>
                <TableCell sx={{color:'#fff'}}>code</TableCell>
                <TableCell sx={{color:'#fff'}}>name</TableCell>
                <TableCell sx={{color:'#fff'}}>email</TableCell>
                <TableCell sx={{color:'#fff'}}>mobile</TableCell>
                <TableCell sx={{color:'#fff'}} align="right">remove</TableCell>
                <TableCell sx={{color:'#fff'}} align="right">show details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {producerList?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.producerCode}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.producerName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.mobileNumebr}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="danger"
                      onClick={()=>{
                        setSelectedProducer(row.producerId);
                        setIsOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={()=>history.push(`producer/${row.producerId}`)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        ):null}
      </Box>
      <SideBar open={open} toggleDrawer={toggleDrawer}/>
      <Footer/>
    </div>

  );
}

export default ProducerList;
