import jwtDecode from 'jwt-decode';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './element/login';
import { Header12 } from './layout/header12';
import CreateNewProduct from './pages/CreateNewProduct';
import About2 from './pages/about-2';
import Agents from './pages/agents';
import AgentsList from './pages/agentsList';
import AllProducts from './pages/allProducts';
import BlogSingle from './pages/blog-single';
import BlogList from './pages/blogList';
import Contact4 from './pages/contact-4';
import ContinentLocations from './pages/continentLocations';
import DefineSpecType from './pages/defineSpecType';
import Error404 from './pages/error-404';
import Faq1 from './pages/faq-1';
import Faq2 from './pages/faq-2';
import Home from './pages/home';
import Industries from './pages/industries';
import MagazineList from './pages/magazine';
import MagazineSingle from './pages/magazine-single';
import Measures from './pages/measures';
import Orders from './pages/orders';
import PriceTable from './pages/priceTable';
import Producer from './pages/producer';
import ProducerGroups from './pages/producerGroup';
import ProducerList from './pages/producerList';
import Product from './pages/product';
import ProductCategories from './pages/productCategories';
import ProductGroups from './pages/productGroups';
import Profiles from './pages/profiles';
import RegisterOrder from './pages/registerOrder';
import ShowProduct from './pages/showProduct';
import TreeView from './pages/treeView';
import UnitType from './pages/unitType';
import userProfile from './pages/userProfile';

function Router()  {
    const token = localStorage.getItem("token");
    let decode = {};
    if (token) {
        decode = jwtDecode(token);
    } 
    return (
		//<BrowserRouter>
		<BrowserRouter>
        <Header12 />
            <div className="page-wraper">

                <Switch>
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/agentList' component={AgentsList} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/agents/:agentId' component={Agents} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/orders' component={Orders} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/industries' component={Industries} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/industries' component={Industries} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/producerList' component={ProducerList}/>:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/productProperties/:productId' component={CreateNewProduct}/>:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/productProperties/:productId' component={CreateNewProduct}/>:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/spec/:specTypeId' component={DefineSpecType} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/producerGroups' component={ProducerGroups} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/productGroups' component={ProductGroups} />:null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/locations' component={ContinentLocations} />
                        :null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/unitType' component={UnitType} />
                        :null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/productCategories' component={ProductCategories}/>
                        :null
                    }
                    {
                        decode.role === 'SuperAdmin'?
                        <Route path='/producer/:producerId' component={Producer}/>:null
                    }
                    
                    <Route path='/userProfile/:userId' component={userProfile} />
                    <Route path='/' exact component={Home} />
                    {/* <Route path='/about-1' component={About1} /> */}
                    <Route path='/aboutUs' component={About2} />
                    <Route path='/login' component={Login} />
                    <Route path='/products/:page' component={AllProducts}/>
                    <Route path='/product/:productId' component={Product}/>
                    <Route path='/singleProduct/:productId' component={ShowProduct}/>
                    <Route path='/registerOrder/:productId' component={RegisterOrder}/>
                    <Route path='/measures' component={Measures}/>
                    <Route path='/treeView' component={TreeView}/>
                    <Route path='/prices' component={PriceTable}/>
					<Route path='/blog/:blogId' component={BlogSingle} />
                    <Route path='/faq-1' component={Faq1} />
                    <Route path='/faq-2' component={Faq2} />
                    <Route path='/contactUs' component={Contact4} />
                    <Route path='/blogList' component={BlogList} />
                    {/* <Route path='/team' component={Team} /> */}
                    <Route path='/profiles' component={Profiles} />
                    <Route path='/shop/:productId' component={ShowProduct} />
                    <Route path='/magazine/:magazineId' component={MagazineSingle} />
                    <Route path='/magazineList' component={MagazineList} />
                    <Route  component={Error404} />
                    
                    {/* <Route path='/shop-sidebar' component={ShopSidebar} /> */}
                    {/* <Route path='/blog-grid-2-sidebar' component={BlogGrid2Sidebar} />
                    <Route path='/blog-grid-3' component={BlogGrid3} />
					<Route path='/blog-grid-2' component={BlogGrid2} />
					<Route path='/blog-grid-2-sidebar' component={BlogGrid2Sidebar} />
					<Route path='/blog-grid-2-sidebar-left' component={BlogGrid2SidebarLeft} />
					<Route path='/blog-grid-3' component={BlogGrid3} />
					<Route path='/blog-grid-3-sidebar' component={BlogGrid3Sidebar} />
					<Route path='/blog-grid-3-sidebar-left' component={BlogGrid3SidebarLeft} />
					<Route path='/blog-grid-4' component={BlogGrid4} />
					<Route path='/blog-half-img' component={BlogHalfImg} />
					<Route path='/blog-half-img-left-sidebar' component={BlogHalfImgSidebarLeft} />
					<Route path='/blog-half-img-sidebar' component={BlogHalfImgSidebar} />
					<Route path='/blog-large-img' component={BlogLargeImg} />
					<Route path='/blog-large-img-left-sidebar' component={BlogLargeImgSidebarLeft} />
					<Route path='/blog-large-img-sidebar' component={BlogLargeImgSidebar} /> */}
					{/* <Route path='/blog-single-left-sidebar' component={BlogSingleSidebarLeft} />
					<Route path='/blog-single-sidebar' component={BlogSingleSidebar} />
					<Route path='/coming-soon-1' component={ComingSoon1} />
                    <Route path='/contact-1' component={Contact1} />
                    <Route path='/contact-2' component={Contact2} />
                    <Route path='/contact-3' component={Contact3} />
                    <Route path='/error-403' component={Error403} />
                    <Route path='/error-404' component={Error404} />
                    <Route path='/error-405' component={Error405} /> */}
                    {/* <Route path='/help-desk' component={HelpDesk} />
                    <Route path='/portfolio-details' component={PortfolioDetails} />
                    <Route path='/portfolio-grid-2' component={PortfolioGrid2} />
                    <Route path='/portfolio-grid-3' component={PortfolioGrid3} />
                    <Route path='/portfolio-grid-4' component={PortfolioGrid4} />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/services-1' component={Services1} />
                    <Route path='/services-2' component={Services2} />
                    <Route path='/services-details' component={ServicesDetails} />
                    <Route path='/shop-cart' component={ShopCart} />
                    <Route path='/shop-checkout' component={ShopCheckout} />
                    <Route path='/shop-login' component={ShopLogin} />
                    <Route path='/shop-product-details' component={shopProductDetails} />
                    <Route path='/shop-register' component={ShopRegister} /> */}
                    {/* <Route path='/shop-wishlist' component={ShopWishlist} />
                    <Route path='/shop' component={Shop} />
                    <Route path='/team-1' component={Team1} />
                    <Route path='/team-2' component={Team2} />
                    <Route path='/index-2' component={Index2} />
                    <Route path='/index-3' component={Index3} />
                    <Route path='/index-4' component={Index4} />
                    <Route path='/index-5' component={Index5} />
                    <Route path='/index-6' component={Index6} />
                    <Route path='/index-7' component={Index7} />
                    <Route path='/index-8' component={Index8} />
                    <Route path='/index-9' component={Index9} />
                    <Route path='/index-10' component={Index10} />
                    <Route path='/index-11' component={Index11} />
                    <Route path='/index-12' component={Index12} />
                    <Route path='/index-13' component={Index13} />
                    <Route path='/index-14' component={Index14} />
                    <Route path='/index-15' component={Index15} />
                    <Route path='/shortcode-buttons' component={shortButtons} />
                    <Route path='/shortcode-alert-box' component={shortAlertBox} />
                    <Route path='/shortcode-icon-box-styles' component={shortIconBoxStyles} />
                    <Route path='/shortcode-icon-box' component={shortIconBox} />
                    <Route path='/shortcode-pricing-table' component={shortPricingTable} />
                    <Route path='/shortcode-dividers' component={shortDividers} />
                    <Route path='/shortcode-list-group' component={shortListGroup} />
                    <Route path='/shortcode-title-separators' component={shortTitleSeparators} />
                    <Route path='/shortcode-image-box-content' component={shortImageBoxContent} />
                    <Route path='/shortcode-pagination' component={shortPagination} />
                    <Route path='/shortcode-counters' component={shortCounters} />
                    <Route path='/shortcode-testimonials' component={shortTestimonials} />
                    <Route path='/shortcode-images-effects' component={shortImagesEffects} />
                    <Route path='/shortcode-shop-widgets' component={shopWidgets} />
                    <Route path='/shortcode-team' component={shortTeam} /> */}
                </Switch>
            </div>
        </BrowserRouter>
    )
}


export default Router;