import {
    Button,
    TextField,
    IconButton,
    Box,
    ButtonGroup,
    Typography,
  } from "@mui/material";
  import React, { useState, Fragment } from "react";
  import { Add, Close, Delete, Edit, Save, Visibility } from "@mui/icons-material";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Paper from "@mui/material/Paper";
  import { toast, ToastContainer } from "react-toastify";
  import DefineIndustry from "./defineIndustry";
  import { getRequest, postRequest } from "../../request";
  import DeleteModal from "./deleteModal";
  import { useEffect } from "react";
  import {useTheme} from "@mui/material";
  
  const styles = {
    box2: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "45rem",
      display: "flex",
      flexDirection: "column",
    },
    box3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "38rem",
      display: "flex",
      flexDirection: "column",
    },
    industryBox3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width: "32rem",
      display: "flex",
      flexDirection: "column",
    },
    unitBox: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      padding: "0.5rem",
    },
    unit: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    innerBox: {
      display: "grid",
      width: "100%",
      padding: "1rem",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
    },
    table2: {
      margin: "0rem 5rem",
      width: "auto",
      maxHeight: "23rem",
      overflow: "scrollY",
    },
    table3: {
      margin: "0rem 1rem",
      width: "auto",
      maxHeight: "35rem",
      overflow: "scrollY",
    },
    newclass1: {
      position: "relative",
      width: "unset",
      background: "none",
      top:'-1.2rem',
      left:'1rem',
    },
    newclass2: {
      position: "relative",
      width: "unset",
      background: "none",
      top:'-1.2rem',
      left:'1rem',
    },
    myclass:{
      position: "absolute",
      width: "unset",
      background: "none",
      left: "75%",
      top: "34.5%",
    },
  };
  function DefineIndustrySubCategory({
    industrySubCategoryList,
    setIndustrySubCategoryList,
    selectedIndustryCategory,
    isShowIndustry,
    setIsShowIndustry
  }) {
    const code = (Math.random() * 1000000).toFixed(0);
    const theme = useTheme();
    const [industryList, setIndustryList] = useState([]);
    const [selectedSubCat, setselectedSubCat] = useState(-1);
    const [selectedIndustry, setSelectedIndustry] = useState(-1);
    const [openModal, setOpenModal] = useState(false);
    const [deletedIndustrySubCatId, setDeletedIndustrySubCatId] = useState(-1);
    const [deleteSuccessful, setDeleteSuccessful] = useState(false);
    const [industrySubCategory, setIndustrySubCategory] = useState({
      industrySubCategoryCode: `industry-subcategory-${code}`,
      industrySubCategoryName: "",
      industrySubCategoryLocalName: "",
    });
    useEffect(() => {
      if(deleteSuccessful){
        setIndustrySubCategoryList(prevstate=>[...prevstate.filter(x=>x.industrySubCategoryId !== deletedIndustrySubCatId)])
        setDeleteSuccessful(false);
        setDeletedIndustrySubCatId(-1);
      }
    }, [deleteSuccessful])
    const handleChange = (event) => {
      setIndustrySubCategory({
        ...industrySubCategory,
        [event.target.name]: event.target.value,
      });
    };
  
    const handleRemoveSubCategory = async(id) => {
      setOpenModal(true);
      setDeletedIndustrySubCatId(id);        
    };
    
  
    const handleEditSubCategory = async() => {
      const currentIndustrySubCategory= {
        ...industrySubCategory,
        industrySubCategoryId:selectedSubCat,
        industryCategoryId:selectedIndustryCategory
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/IndustrySubCategory/edit`,currentIndustrySubCategory)
      if(message ==='ok'){
        industrySubCategoryList[
          industrySubCategoryList.findIndex(
            (x) => x.industrySubCategoryCode === selectedSubCat
          )
        ] = industrySubCategory;
        setIndustrySubCategoryList(industrySubCategoryList);
        setselectedSubCat(-1);
        setIndustrySubCategory({
          industrySubCategoryCode: `industry-subcategory-${code}`,
          industrySubCategoryName: "",
          industrySubCategoryLocalName: "",
        });
        toast.success('SubCategory edited successfully!')
      }
    };
  
    const handleShowIndustries = async (id) => {
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/IndustrySubCategory/industries/${id}`)
      if(data){
        setIndustryList(data);
        setIsShowIndustry(true);
        setSelectedIndustry(id);
      }else{
        toast.error(error);
      }
    };
    const addIndustrySubCategory=async()=>{
      const currentIndustrySubCategory= {
        ...industrySubCategory,
        industryCategoryId:selectedIndustryCategory
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/IndustrySubCategory`,currentIndustrySubCategory)
      if(message === 'ok'){
        currentIndustrySubCategory.industrySubCategoryId=data;
        setIndustrySubCategoryList(prevstate=>[
          ...prevstate,currentIndustrySubCategory
        ]);
        setIndustrySubCategory({
          industrySubCategoryCode: `industry-subcategory-${(Math.random() * 1000000).toFixed(0)}`,
          industrySubCategoryName: "",
          industrySubCategoryLocalName: "",
        });
        toast.success('industry subCategory added successfully!');
      }
    }
    return (
      <Fragment>
        {selectedIndustryCategory ? (
          <Box>
            <Box sx={isShowIndustry ? styles.box3 : styles.box2}>
              <Typography variant="h5" color="initial" sx={isShowIndustry?styles.newclass1:styles.newclass2}>
                <span>Industry Sub Categories</span>
              </Typography>
              <div style={styles.innerBox}>
                <TextField
                  variant="outlined"
                  label="Code"
                  name="industrySubCategoryCode"
                  value={industrySubCategory.industrySubCategoryCode}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  label="Name"
                  name="industrySubCategoryName"
                  value={industrySubCategory.industrySubCategoryName}
                  onChange={handleChange}
                />
              </div>
              <div style={styles.innerBox}>
                <TextField
                  variant="outlined"
                  label="localName"
                  name="industrySubCategoryLocalName"
                  value={industrySubCategory.industrySubCategoryLocalName}
                  onChange={handleChange}
                />
                <div style={{ width: "211px" }}></div>
              </div>
              <div style={styles.innerBox}>
                <ButtonGroup>
                  {selectedSubCat !==-1 ? null : (
                    <Button
                      variant="contained"
                      disabled={
                        !industrySubCategory.industrySubCategoryLocalName ||
                        !industrySubCategory.industrySubCategoryName ||
                        !industrySubCategory.industrySubCategoryCode
                      }
                      color="primary"
                      sx={{ color: "#fff" }}
                      onClick={addIndustrySubCategory}
                    >
                      save <Save />
                    </Button>
                  )}
                  {selectedSubCat!==-1 ? (
                    <Button
                      variant="contained"
                      color="edit"
                      sx={{ color: "#fff" }}
                      onClick={handleEditSubCategory}
                    >
                      edit <Edit />
                    </Button>
                  ) : null}
                  {selectedSubCat!==-1 ?<Button
                    variant="contained"
                    color="danger"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setselectedSubCat(-1);
                      setIndustrySubCategory(
                        {
                          industrySubCategoryCode: `industry-subcategory-${code}`,
                          industrySubCategoryName: "",
                          industrySubCategoryLocalName: "",
                        }
                      );
                    }}
                  >
                    cancel <Close />
                  </Button>:null}
                </ButtonGroup>
                <div style={{ width: "211px" }}></div>
              </div>
  
              
              {industrySubCategoryList.length ? (
                <TableContainer
                  sx={selectedIndustryCategory ? styles.table3 : styles.table2}
                  component={Paper}
                >
                  <Table
                    sx={{ width: "95%", margin: "0.5rem" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>code</TableCell>
                        <TableCell align="right">name</TableCell>
                        <TableCell align="right">edit</TableCell>
                        <TableCell align="right">remove</TableCell>
                        <TableCell align="right">show industry</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {industrySubCategoryList.map((row) => (
                        <TableRow
                          key={row.industrySubCategoryId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.industrySubCategoryCode}
                          </TableCell>
                          <TableCell align="right">
                            {row.industrySubCategoryName}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                setselectedSubCat(row.industrySubCategoryId);
                                setIndustrySubCategory(
                                  industrySubCategoryList.find(
                                    (x) =>
                                      x.industrySubCategoryId ===
                                      row.industrySubCategoryId
                                  )
                                );
                              }}
                            >
                              <Edit sx={{ color: theme.palette.edit.main }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label=""
                              onClick={() =>
                                handleRemoveSubCategory(
                                  row.industrySubCategoryId
                                )
                              }
                            >
                              <Delete sx={{ color: theme.palette.danger.main }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label=""
                              onClick={() =>
                                handleShowIndustries(
                                  row.industrySubCategoryId
                                )
                              }
                            >
                              <Visibility sx={selectedIndustry===row.industrySubCategoryId? { color:theme.palette.primary.main}:{ color:'inherit'}}/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Box>
          </Box>
        ) : null}
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        {isShowIndustry ? (
          <DefineIndustry 
            industryList={industryList} 
            setIndustryList={setIndustryList} 
            isShowIndustry={isShowIndustry} 
            setIsShowIndustry={setIsShowIndustry} 
            selectedIndustryCategory={selectedIndustry}
          />
          ):null} 
        {openModal?
       <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="industry category" deleteURL={`${process.env.REACT_APP_BASE_URL}/IndustrySubCategory/delete/${deletedIndustrySubCatId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
        :null}
      </Fragment>
    );
  }
  
  export default DefineIndustrySubCategory;
  