import { Cancel, Delete } from "@mui/icons-material";
import { Box, Modal, Typography,ButtonGroup, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../request";

const style = {
    modal:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    },
    btnGroup:{width:"100%",position:'relative',display:'flex',justifyContent:'flex-end',marginTop:'3rem'}
  };

function DeleteModal({openDeleteModal,setOpenDeleteModal,parameterName,deleteURL,destination,setIsSuccessful}) {
    const history = useHistory();
    const handleDelete=async()=>{
      const {data,error} = await getRequest(deleteURL)
      if((data && Object.keys(data).length) || (data==="")){
        toast.success('deleted Successfully!');
        setOpenDeleteModal(false)
        if(setIsSuccessful){
          setIsSuccessful(true);
        }
        if(destination !== 'NotLoad'){
          if(destination){
            history.push(destination);
          }
          else{
            window.location.reload();
          }
        }
      }else{
        toast.error(error);
      }
    }
    const handleClose = ()=>{
      setOpenDeleteModal(false);
    }
  return (
    <div>
      <Modal
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style.modal}>
          <Typography  variant="h6" component="h2">
            Are you sure you want to delete {parameterName} ?
          </Typography>
          <Box sx={style.btnGroup}>
            <ButtonGroup size="small" >
              <Button variant="contained" color="danger" onClick={handleDelete} sx={{color:'#fff'}}>
                delete <Delete/>
              </Button>
              <Button variant="contained" color="secondary" onClick={handleClose} sx={{color:'#fff'}}>
                cancel <Cancel/>
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteModal;
