import React,{useEffect,useState} from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
import Footer5 from "../layout/footer5";
import {Header12} from "../layout/header12";
import { AccountCircle, Close,  Edit, Save } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { getRequest, postRequest } from '../../request';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/sideBar';


  const styles = {
    containerBox2: {
      display: "grid",
      gridTemplateColumns: "auto ",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "82vh",
    },
    containerBox3: {
      display: "grid",
      gridTemplateColumns: "auto auto auto",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "81vh",
    },
    box3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      width: "37rem",
      height: "70vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      width: "50rem",
      height: "70vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto ",
      justifyContent: "space-around",
    },
    types: {
      backgroundColor: "#ccc",
      borderRadius: "0.5rem 0.5rem 0rem 0rem",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    newclass1: {
      position: "relative",
      width: "unset",
      background: "none",
      top:"-1.2rem",
      left:"1rem",
      fontWeight: "bold",
    },
    newclass2: {
      position: "relative",
      width: "unset",
      background: "none",
      top:"-1.2rem",
      left:"1rem",
      fontWeight: "bold",
    },
    btn:{
      position:'absolute',
      margin:'3rem 3rem'
    }
  };
  const bg = require("./../../images/banner/bnr5.jpg");
function Agents() {
    const history = useHistory();
    const params = useParams();
    const code = (Math.random()*1000000).toFixed(0);
    const [cityList, setCityList] = useState([]);
    const [continentList, setContinentList] = useState([]);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [agent, setAgent] = useState({
        agentAddressCity:"",
        agentAddressLine1:"",
        agentAddressLine2:"",
        agentCode:`Agent-${code}`,
        agentEmail:"",
        agentMobile:"",
        agentName:"",
        agentRegisterNumber:"",
        agentSiteURL:"",
        agentTelNumber1:"",
        agentTelNumber2:"",
        continentId: "",
        continent: "",
        countryId: "",
        country: "",
        addressCityId: "",
        city:"",
        stateId: "",
        state: "",
        });
        
    useEffect(() => {
        fetchContinent();
        if(params.agentId!=='0'){
            fetchSelectedProducer()
          }
    }, []);
    useEffect(() => {
        if (agent.continent && continentList.length) {
          const continentId = continentList.find(
            (x) => x.continentName == agent.continent
            ).continentId;
          if(!(+params.agentId)){
            setAgent({
              ...agent,
              continentId: continentId,
              country: "",
              state: "",
              addressCityId: "",
              city:""
            });
          }
          //   setAgent({ ...agent,country:"", state: "", city: "" });
          fetchCountry(continentId);
        } else {
          setCountryList([]);
        }
      }, [agent.continent]);
      useEffect(() => {
        if (agent.country && countryList.length ) {
          const countryId = countryList.find(
            (x) => x.countryName == agent.country
          ).countryId;
          if(!(+params.agentId)){
            setAgent({
              ...agent,
              countryId: countryId,
              state: "",
              addressCityId: "",
              city:""
            });
          }
          fetchState(countryId);
        } else {
          setStateList([]);
        }
      }, [agent.country,countryList.length]);
      useEffect(() => {
        
        if (agent.state && stateList.length) { 
          const stateId = stateList.find(
            (x) => x.stateName == agent.state
          ).stateId;
          if(!(+params.agentId)){
            setAgent({ ...agent, stateId: stateId, addressCityId: "",city:"" });
          }
          fetchCities(stateId);
        } else {
          setCityList([]);
        }
      }, [agent.state,stateList.length]);
      useEffect(() => {
        if(agent.city && cityList.length){
            const cityId = cityList.find(
                (x) => x.cityName == agent.city
            ).cityId;
            setAgent({...agent,addressCityId:cityId.toString()})
        }
      }, [agent.city]);
      const token = localStorage.getItem('token');
      let decode={};
      if(token){
        decode = jwtDecode(token);
      }
    const handleChange = (event) => {
        setAgent({
          ...agent,
          [event.target.name]: event.target.value,
        });
      };
    const fetchCities = async (stateId) => {
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/City`)
      if(data && Object.keys(data).length){
        setCityList(data.filter((x) => x.stateId == stateId));
      }else{
        toast.error(error);
      }
    };
    const fetchContinent = async () => {
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Continent`)
      if(data && Object.keys(data).length){
        setContinentList(data);
      }else{
        toast.error(error);
      }
    };
    const fetchCountry = async (continentId) => {
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Country`)
      if(data && Object.keys(data).length){
        setCountryList(data.filter((x) => x.continentId == continentId));
            }else{
        toast.error(error);
      }
    };
    const fetchState = async (countryId) => {
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Country/states/${countryId}`)
      if(data && Object.keys(data).length){
        setStateList(data.filter((x) => x.countryId == countryId));
      }else{
        toast.error(error);
      }
    };
    const fetchSelectedProducer=async()=>{
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Agent/${params.agentId}`)
      if(data && Object.keys(data).length){
        setAgent({...agent,
          agentAddressCity:data.agentAddressCity,
          agentAddressLine1:data.agentAddressLine1,
          agentAddressLine2:data.agentAddressLine2,
          agentCode:data.agentCode,
          agentEmail:data.agentEmail,
          agentMobile:data.agentMobile,
          agentName:data.agentName,
          agentRegisterNumber:data.agentRegisterNumber,
          agentSiteURL:data.agentSiteURL,
          agentTelNumber1:data.agentTelNumber1,
          agentTelNumber2:data.agentTelNumber2,
          continentId: data.city.state.country.continent.continentId,
          continent: data.city.state.country.continent.continentName,
          countryId: data.city.state.country.countryId,
          country: data.city.state.country.countryName,
          addressCityId: data.city.cityId,
          city:data.city.cityName,
          stateId: data.city.state.stateId,
          state: data.city.state.stateName,
      })
      }else{
        toast.error(error);
      }
    }
    const handleAddAgent = async()=>{
      const emailValidation = agent.agentEmail?.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      const mobileValidation = agent.agentMobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
      if(emailValidation && mobileValidation && emailValidation.length && mobileValidation.length){
        const tempData ={
          agentAddressCity:+agent.addressCityId,
          agentAddressLine1:agent.agentAddressLine1,
          agentAddressLine2:agent.agentAddressLine2,
          agentCode:agent.agentCode,
          agentEmail:agent.agentEmail,
          agentMobile:agent.agentMobile,
          agentName:agent.agentName,
          agentRegisterNumber:agent.agentRegisterNumber,
          agentSiteURL:agent.agentSiteURL,
          agentTelNumber1:agent.agentTelNumber1,
          agentTelNumber2:agent.agentTelNumber2,
        }
        const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Agent`,tempData);
        if(message === 'ok'){
          toast.success('agent added successfully!');
          history.push('/agentList')
        }
      }
    }
    const handleEditAgent = async()=>{
      const emailValidation = agent.agentEmail?.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      const mobileValidation = agent.agentMobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
      if(emailValidation && mobileValidation && emailValidation.length && mobileValidation.length){
        const tempData ={
          agentId:+params.agentId,
          agentAddressCity:+agent.addressCityId,
          agentAddressLine1:agent.agentAddressLine1,
          agentAddressLine2:agent.agentAddressLine2,
          agentCode:agent.agentCode,
          agentEmail:agent.agentEmail,
          agentMobile:agent.agentMobile,
          agentName:agent.agentName,
          agentRegisterNumber:agent.agentRegisterNumber,
          agentSiteURL:agent.agentSiteURL,
          agentTelNumber1:agent.agentTelNumber1,
          agentTelNumber2:agent.agentTelNumber2,
        }
        const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Agent/edit`,tempData);
        if(message === 'ok'){
          toast.success('agent Edited successfully!');
          history.push('/agentList')
        }
      }
    }
    const handleDeleteAgent = async()=>{
      const {error,data} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Agent/delete/${params.agentId}`);
      if(!error){
        toast.success('Agent Deleted Successfully!');
        history.push('/agentList')
      }
    }
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpenUserModal(open);
    };
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
          <div className="container">
              <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Agent</h1>
                  <div className="breadcrumb-row">
                      <ul className="list-inline">
                          <li><Link to="/">Home</Link></li>
                          <li>Agent</li>
                      </ul>
                  </div>
              </div>
          </div>
        </div>
        {decode.role === 'SuperAdmin'?
            <Button color='secondary' sx={styles.btn}  variant='contained' onClick={toggleDrawer( true)} >user options <AccountCircle/></Button>
        :null}
            <Box style={styles.containerBox2}>
            <Box sx={styles.box}>
              <Box sx={{width:'100%',display:'flex',justifyContent:'initial'}}>
                <Typography
                  variant="h5"
                  color="initial"
                  sx={styles.newclass1}
                >
                  <span>Agent</span>
                </Typography>
              </Box>
                <div style={styles.innerBox}>
                <TextField
                    variant="outlined"
                    label="Code"
                    sx={{ minWidth: 211 }}
                    name="agentCode"
                    value={agent.agentCode}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    label="Name"
                    sx={{ minWidth: 211 }}
                    name="agentName"
                    value={agent.agentName}
                    onChange={handleChange}
                />
                </div>
                <div style={styles.innerBox}>
                <TextField
                    variant="outlined"
                    label="Register Number"
                    sx={{ minWidth: 211 }}
                    name="agentRegisterNumber"
                    value={agent.agentRegisterNumber}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    label="Mobile"
                    sx={{ minWidth: 211 }}
                    name="agentMobile"
                    value={agent.agentMobile}
                    onChange={handleChange}
                />
                </div>
                <div style={styles.innerBox}>
                <TextField
                    variant="outlined"
                    label="Telephone1"
                    sx={{ minWidth: 211 }}
                    name="agentTelNumber1"
                    value={agent.agentTelNumber1}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    label="Telephone2"
                    sx={{ minWidth: 211 }}
                    name="agentTelNumber2"
                    value={agent.agentTelNumber2}
                    onChange={handleChange}
                />
                </div>
                <div style={styles.innerBox}>
                  <TextField
                      variant="outlined"
                      label="Email"
                      sx={{ minWidth: 211 }}
                      name="agentEmail"
                      value={agent.agentEmail}
                      onChange={handleChange}
                  />
                  <div style={{minWidth: 211}}></div>
                </div>
                <div style={styles.innerBox}>
                    <Box sx={{ minWidth: 211 }}>
                    <FormControl fullWidth>
                        <InputLabel id="continent">Continent</InputLabel>
                        <Select
                        required
                        label="Continent"
                        error={!agent.continent}
                        name="continent"
                        value={agent.continent}
                        onChange={handleChange}
                        >
                        {continentList.map((continent, index) => (
                            <MenuItem key={index} value={continent.continentName}>
                            {continent.continentName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 211 }}>
                    <FormControl fullWidth>
                        <InputLabel id="country">Country</InputLabel>
                        <Select
                        required
                        label="Country"
                        name="country"
                        error={!agent.country}
                        value={agent.country}
                        onChange={handleChange}
                        >
                        {countryList.map((Country, index) => (
                            <MenuItem key={index} value={Country.countryName}>
                            {Country.countryName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Box>
                </div>
                <div style={styles.innerBox}>
                    <Box sx={{ minWidth: 211 }}>
                    <FormControl fullWidth>
                        <InputLabel id="state">state</InputLabel>
                        <Select
                        required
                        label="state"
                        name="state"
                        error={!agent.state}
                        value={agent.state}
                        onChange={handleChange}
                        >
                        {stateList.map((state, index) => (
                            <MenuItem key={index} value={state.stateName}>
                            {state.stateName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 211 }}>
                    <FormControl fullWidth>
                        <InputLabel id="city">City</InputLabel>
                        <Select
                        required
                        error={!agent.city}
                        label="City"
                        name="city"
                        value={agent.city}
                        onChange={handleChange}
                        >
                        {cityList.map((city, index) => (
                            <MenuItem key={index} value={city.cityName}>
                            {city.cityName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Box>
                </div>
                <div style={{width:'100%',padding:'0rem 6rem'}}>
                <TextField
                  sx={{width:'100%',}}
                  value={agent.agentAddressLine1}
                  onChange={handleChange}
                  name='agentAddressLine1'
                  label="address1"
                  multiline
                  rows={2}
                  defaultValue="Default Value"
                />
                </div>
                <div style={{width:'100%',padding:'0rem 6rem'}}>
                <TextField
                  value={agent.agentAddressLine2}
                  sx={{width:'100%',}}
                  onChange={handleChange}
                  name='agentAddressLine2'
                  label="address2"
                  multiline
                  rows={2}
                  
                />
                <div style={{ width: "250px", marginTop:'10px' }}>
                    {params.agentId !=="0" ? (
                    <ButtonGroup>
                        <Button
                          variant="contained"
                          color="danger"
                          sx={{ color: "#efefef" }}
                          onClick={handleDeleteAgent}
                        >
                        delete
                        <Close />
                        </Button>
                        <Button
                          variant="contained"
                          color="edit"
                          sx={{ color: "#efefef" }}
                          onClick={handleEditAgent}
                        >
                        edit
                        <Edit />
                        </Button>
                    </ButtonGroup>
                    ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                        !agent.agentCode ||
                        !agent.agentEmail ||
                        !agent.agentMobile 
                        }
                        sx={{ color: "white" }}
                        onClick={handleAddAgent}
                    >
                        save
                        <Save />
                    </Button>
                    )}
                </div>
                
                </div>
            </Box>
            </Box>
        <Footer5/>
        <SideBar open={openUserModal} toggleDrawer={toggleDrawer} />
    </div>
  )
}

export default Agents