import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import React from "react";

function ProducerAgents({ agentsList, setSelectedAgents, selectedAgents }) {
  return (
    <div style={{margin:'0px 10px'}}>
      <Autocomplete
        title="Search Agents"
        disablePortal
        onChange={(e, value) => {
          if (value) {
            setSelectedAgents((prevstate) => [
              ...prevstate,
              { agentId: value.split(" ")[0], agentName: value.split(" ")[1] },
            ]);
          }
        }}
        options={agentsList.map((x) => x.agentId + " " + x.agentName)}
        sx={{ width: "100%", paddingBottom: "2rem" }}
        renderInput={(params) => (
          <TextField {...params} label={`Search Agents...`} />
        )}
      />
      {selectedAgents.length ? (
        <TableContainer
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Agent Id</TableCell>
                <TableCell>Agent Name</TableCell>
                <TableCell align="right">remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedAgents?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.agentId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.agentName}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="danger"
                      onClick={() =>
                        setSelectedAgents((prevstate) => [
                          ...prevstate.filter((x) => x.agentId != row.agentId),
                        ])
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
}

export default ProducerAgents;
