import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Add, Cancel } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Button, IconButton, InputLabel, MenuItem, Pagination, Select, TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { Stack } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import circle from '../../images/btn-export/circle.svg';
import { getRequest } from "../../request";
import Loading from "../components/loading";
import Footer from "../layout/footer5";
import { Header12 } from "../layout/header12";
const bnr = require("./../../images/banner/bnr5.jpg");
const NoProductImg = require("../../images/our-work/chemicals/No_Image_Available.jpg");
const productModal = require('../../images/our-work/chemicals/productImageModel.png')
function Product() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const params = useParams();
  const fetchId = params.productId?.split("&&")[0]?.split('=')[1];
  const notFoundProduct = require("../../images/our-work/chemicals/no-result.png");
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(0);
  const [header, setHeader] = useState([]);
  const [productSubCat, setProductSubCat] = useState('')
  const [filteredItem, setFilteredItem] = useState({ name: "" });
  const [pageCount, setPageCount] = useState(0);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const StyledPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
      backgroundImage: `url(${circle})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: "#fff"
    },
  });
  const fetchProductsByCategory = async (pPage) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/products/${fetchId}`)
    if (data && Object.keys(data).length) {

      data.slice(12 * (pPage - 1), 12 * (pPage - 1) + 12).map((x) => {
        setProductsData((prevstate) => [
          ...prevstate,
          {
            id: x.productId,
            name: x.productName,
            description: x.productDescription,
            productSubCategoryName: x.subCategory.productSubCategoryName,
            image: x.images[0]
              ? `${process.env.REACT_APP_IMG_URL}` + x.images[0]
              : NoProductImg,
          },
        ]);
      });
      console.log("data", data, data.length)
      setPageCount(Math.floor((data.length - 1) / 12) + 1);
    } else {
      toast.error(error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const res = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/${fetchId}`)
    if (res.data && Object.keys(res.data).length) {
      setHeader([{ data: res.data.productCategoryName, id: res.data.productCategoryId, type: 'productCategory' }]);
    } else {
      toast.error(res.error);
    }
  };
  const fetchProductsBySubCategory = async (pPage) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/products/${fetchId}`)
    if (data && Object.keys(data).length) {

      data.slice(12 * (pPage - 1), 12 * (pPage - 1) + 12).map((x) => {
        setProductsData((prevstate) => [
          ...prevstate,
          {
            id: x.productId,
            name: x.productName,
            description: x.productDescription,
            image: x.images[0]
              ? `${process.env.REACT_APP_IMG_URL}` + x.images[0]
              : NoProductImg,
          },
        ]);
      });
      setPageCount(Math.floor(data.length / 12) + 1);
    } else {
      toast.error(error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const res = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/${fetchId}`)
    if (res.data && Object.keys(res.data).length) {
      setProductSubCat(res.data.productSubCategoryName);
      setHeader([
        {
          data: res.data.productCategory.productCategoryName,
          id: res.data.productCategory.productCategoryId,
          type: 'productCategory'
        },
        {
          data: res.data.productSubCategoryName,
          id: res.data.productSubCategoryId,
          type: 'productSubCategory'
        }
      ]);
    } else {
      toast.error(res.error);
    }
  };
  const handleFilterProducts = async (value) => {
    setFilteredItem({ name: value });
    if (value === null) {
      setProductsData([]);
      setIsLoading(true);
      if (params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1) {
        fetchProductsBySubCategory(page);
      } else if (
        params.productId.split("=")[0].indexOf("ProductCategory") !== -1
      ) {
        fetchProductsByCategory(page);
      }
    } else {
      setProductsData(productsData.filter((x) => x.name === value));
    }
    // setFilteredItem({name:''});
  };
  useEffect(() => {
    setSelectedCategory(+fetchId);
    if (params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1) {
      const pageNumber = params.productId.split('&&')[1].split('=')[1];
      setPage(+pageNumber);
      // fetchProducts(+pageNumber);
      fetchProductsBySubCategory(+pageNumber);
      setProductsData([]);
    } else if (
      params.productId.split("=")[0].indexOf("ProductCategory") !== -1
    ) {
      const pageNumber = params.productId?.split('&&')[1]?.split('=')[1];
      setPage(+pageNumber);
      fetchProductsByCategory(+pageNumber);
      setProductsData([]);
    } else {
      setProductsData([]);
    }
    setIsLoading(true);
  }, [header[0]?.data, location]);


  useEffect(() => {
    fetchSubCategories();
  }, [])

  const fetchSubCategories = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory`);
    if (data && Object.keys(data).length) {
      setSubCategoryList(data);
    } else {
      toast.error(error);
    }
  }
  const handleClearFilter = () => {
    history.push('/products/page=1');
    setSelectedCategory('');
  }

  useEffect(() => {
    console.log("page", page, productsData.slice(4 * (page - 1), 4 * (page - 1) + 4))
  }, [page, productsData])

  return (
    <div style={{ backgroundColor: "#000" }}>
      <Header12 />
      <div
        className="dlab-bnr-inr overlay-black-middle"
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{header[0]?.data}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                {header?.map((x, ind) => (
                  <li key={ind} style={{ cursor: 'pointer' }} onClick={x.type === 'productCategory' ? () => history.push(`/product/:ProductCategory=${x.id}&&page=1`) : () => history.push(`/product/:ProductSubCategory=${x.id}&&page=1`)}>{x.data}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!isLoading ? (
        <div className="page-content">
          <div className="section-full">
            <div className="container">

              <div className="d-flex justify-content-center" style={{ background: "rgba(63,63,67, 0.70)", marginBottom: "15px", borderRadius: '5px' }}>
                <div className="col-2 justify-content-center align-items-center">
                  <Box sx={isDesktop ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: '4rem', width: '100%', alignItems: 'center' } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '7rem', width: '100%', alignItems: 'center' }}>

                    <p className="p-0 m-0 text-white">
                      <SearchIcon color="secondary" style={{ color: "#efefef" }} />
                      Search
                    </p>
                  </Box>
                </div>
                <div className="col-2">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {decode.role === "SuperAdmin" ? (
                      <Button
                        variant="contained" color="primary" size="small"
                        style={{ marginBottom: "1rem", height: '3rem', width: '17rem' }}
                        onClick={() => history.push(`/productProperties/0`)}
                      >
                        New Product
                        <Add />
                      </Button>
                    ) : null}
                  </Box>
                </div>

                <div className="col-4">
                  <Box sx={isDesktop ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: '4rem', width: '100%', alignItems: 'center' } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '7rem', width: '100%', alignItems: 'center' }}>
                    {productsData.length ? (
                      <Autocomplete
                        title="Search Product"
                        disablePortal
                        size="small"
                        value={filteredItem.name}
                        // getOptionSelected={(option, value) => option.name === value.name}
                        onChange={(e, value) => handleFilterProducts(value)}
                        options={productsData.map((x) => x.name)}
                        sx={{ width: "17rem", marginBottom: "0.5rem", height: '2rem' }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiInputBase-root": {
                                color: '#efefef'
                              },
                              "& .MuiButtonBase-root": {
                                color: '#efefef'
                              },
                              "& .MuiFormLabel-root": {
                                color: '#efefef'
                              },
                              "& .MuiFormLabel-root": {
                                color: '#efefef'
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: '#efefef'
                              },

                              '& .MuiOutlinedInput-root': {
                                "& .MuiAutocomplete-endAdornment": {
                                  color: '#efefef'
                                },
                                '& fieldset': {
                                  borderColor: '#efefef',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#efefef',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#efefef',
                                },
                              },
                            }}
                            {...params} label={`Search Product...`} />
                        )}
                      />
                    ) : null}

                  </Box>
                </div>
                <div className="col-4">
                  <Box sx={isDesktop ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: '4rem', width: '100%', alignItems: 'center' } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '7rem', width: '100%', alignItems: 'center' }}>
                    <FormControl size="small" sx={{ width: '17rem' }}>
                      <InputLabel style={{ color: "#efefef", }} >SubCategory</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory}
                        label="subCategory"
                        sx={{
                          "& .MuiSelect-select": {
                            color: '#efefef'
                          },
                          "& .MuiInputBase-root": {
                            color: '#efefef'
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            color: '#efefef',
                            borderColor: '#efefef !important'
                          },

                          "& .MuiFormLabel-root": {
                            color: '#efefef'
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: '#efefef'
                          },
                          "& .MuiSvgIcon-root ": {
                            color: "#fff"
                          },
                          "& .MuiFormLabel-root": {
                            color: '#efefef'
                          },
                          '& .MuiFormLabel-root ': {
                            "& .MuiAutocomplete-endAdornment": {
                              color: '#efefef'
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#fff',
                              },
                              '&:hover fieldset': {
                                borderColor: '#fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#fff',
                              },
                            },
                          },
                        }}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          history.push(`/product/ProductSubCategory=${e.target.value}&&page=1`)
                        }}
                      >
                        {subCategoryList.map((x, index) =>
                          <MenuItem key={index} value={x.productSubCategoryId}>{x.productSubCategoryName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {selectedCategory ?
                      <IconButton size="small" sx={{ height: '2.5rem', position: 'absolute', right: '1.5rem' }} onClick={handleClearFilter}>
                        <Cancel style={{ color: "red" }} />
                      </IconButton>
                      : null
                    }
                  </Box>

                </div>

              </div>

              <div className="product-list">
                {productsData.slice(12 * (page - 1), 12 * (page - 1) + 12)
                  .map((product, index) => (
                    <div className="product-item" style={{
                      background: "rgba(63,63,67, 0.70)",
                      maxWidth: '100vw', borderRadius: "2rem"
                    }} >
                      <img src={(product.image)} width={'100%'} style={{ aspectRatio: "3/2", borderRadius: "2rem 2rem 0 0" }} />
                      <div className="my-15" style={{ marginTop: '1rem', marginInline: '2rem', color: "#fff" }}>
                        <Link to={`/singleProduct/${product.id}`}
                        >
                          <h4 className="title"
                            style={{
                              width: '200px',
                              padding: 0,
                              overflow: 'hidden',
                              position: 'relative',
                              display: 'inlineBlock',
                              textDecoration: 'none',
                              textOverflow: 'ellipsis',
                              borderBottom: "1px solid #fff",
                              whiteSpace: 'nowrap',
                              color: '#fff',
                            }}>
                            {product.name} :
                          </h4>
                        </Link>
                        {product.description ? (
                          <p
                            style={{
                              fontSize: "14px",
                              height: "5.0rem",
                              overflowY: "hidden",
                            }}
                          >
                            {product.description?.slice(0, 60)}
                            <b>{"..."}</b>
                          </p>
                        ) : (
                          <p
                            style={{
                              height: "6.6rem",
                              overflowY: "hidden",
                            }}
                          >
                            <b>No Description Found!</b>
                          </p>
                        )}
                         <button style={{backgroundColor:"transparent"}}  className="details-button cursor-pointer" onClick={() => history.push(`/singleProduct/${product.id}`)}>
                          Show Product
                        </button>
                      </div>
                      {/* <div className="half-ray-circle"></div> */}
                    </div>
                  ))}
              </div>
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <Stack spacing={2}>
                  <StyledPagination
                    count={pageCount}
                    variant="outlined"
                    color="primary"
                    sx={{ marginBottom: '1rem' }}
                    page={page}
                    onChange={(e) => {
                      if (e.target.innerText) {
                        setPage(+e.target.innerText);
                        ///product/:ProductCategory=${x.id}&&page=1
                        history.push(`/product/:ProductCategory=${header[0].id}&&page=${e.target.innerText}`)
                      } else
                        if (
                          e.target.innerHTML ===
                          '<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>'
                        ) {
                          setPage(parseInt(page) - 1);
                          history.push(`/product/:ProductCategory=${header[0].id}&&page=${parseInt(page) - 1}`);
                        } else {
                          setPage(parseInt(page) + 1);
                          history.push(`/product/:ProductCategory=${header[0].id}&&page=${parseInt(page) + 1}`);
                        }
                    }}
                  />
                </Stack>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="cylon" color="#000" />
        </Box>
      )}
      <Footer />
    </div>
  );
}

export default Product;
