import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useState } from "react";
import Loading from "react-loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import circle from "../../images/btn-export/circle.svg";
import { getRequest } from "../../request";
import Sidebar from "../element/sidebar";

const bg = require("../../images/banner/bnr5.jpg");

function BlogList() {
  const [blogLists, setBlogLists] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [keyWords, setKeyWords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (blogLists.length) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [blogLists]);

  const fetchBlogs = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/BlogPost`
    );
    if (data && Object.keys(data).length) {
      const list = data;
      let d;
      for (let i = 0; i < list.length; i++) {
        const sectionResponse = await getRequest(
          `${process.env.REACT_APP_BASE_URL}/BlogPost/sections/${list[i].id}`
        );
        if (sectionResponse.data && Object.keys(sectionResponse.data).length) {
          const section = sectionResponse.data.filter((x) => x.type === 0)[0];
          d = new Date(list[i].updateDateTime);
          list[i].date = d.toString();
          list[i].section = section?.value;
        }
        const keyWordResponse = await getRequest(
          `${process.env.REACT_APP_BASE_URL}/BlogPost/keywords/${list[i].id}`
        );
        if (keyWordResponse.data && Object.keys(keyWordResponse.data).length) {
          for (let i = 0; i < keyWordResponse.data.length; i++) {
            setKeyWords((prevstate) => [
              ...prevstate,
              keyWordResponse.data[i].value,
            ]);
          }
        }
      }
      const blogData = list
        .reverse()
        .filter((x) =>
          x.title.toLowerCase().includes(searchWord.toLowerCase())
        );
      setBlogLists(blogData);
      setPageCount(Math.floor((blogData.length - 1) / 12) + 1);
    } else {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);


  console.log("searchWord", searchWord);

  
  useEffect(() => {
    console.log("searchWord", searchWord);
    const blogData = blogLists
      .reverse()
      .filter((x) => x.title.toLowerCase().includes(searchWord.toLowerCase()));
    setBlogLists(blogData);
	setPageCount(Math.floor((blogData.length - 1) / 12) + 1);
  }, [searchWord]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, pageCount]);

  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      backgroundColor: `#4D3E98`,
      backgroundImage: `url(${circle})`,
      border: "none",
      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "#fff",
    },
    "& .css-i37r6e-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      // border: "3px solid #406764",
      backgroundColor: `#406764`,
      color: "#fff",
    },
  });

  return (
    <div className="blogList">
      <div className="page-content bg-white mskBackground">
        {isLoading ? (
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading color="#fff" type="cylon" />
          </Box>
        ) : (
          <div className="content-area">
            <div className="container blogContainer">
              <Box className="blogTitle">
                <Typography
                  variant="h3"
                  sx={{ color: "#fff", borderBottom: "5px solid #fff" }}
                >
                  Blog List
                </Typography>
              </Box>
              <div className="row">
                <Sidebar
                  blogLists={blogLists}
                  setSearchWord={setSearchWord}
                  keyWords={keyWords}
                />
                <div className="col-lg-9">
                  {blogLists.length
                    ? blogLists
                        .slice(4 * (page - 1), 4 * (page - 1) + 4)
                        .map((x, item) => (
                          <div
                            className="blog-post blog-md clearfix blogListBackground"
                            key={item}
                          >
                            <div className="dlab-post-media dlab-img-effect zoom-slow">
                              <img
                                src={
                                  `${process.env.REACT_APP_IMG_URL}` + x.photo
                                }
                                alt=""
                              />
                            </div>
                            <div className="dlab-post-info">
                              <div className="dlab-post-meta">
                                <ul>
                                  <li className="post-date">
                                    {" "}
                                    <strong>
                                      {x.date.split(" ")[2]}{" "}
                                      {x.date.split(" ")[1]}
                                    </strong>{" "}
                                    <span> {x.date.split(" ")[3]}</span>{" "}
                                  </li>
                                  <li className="post-author">
                                    {" "}
                                    By <Link>{x.user}</Link>{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="dlab-post-title">
                                <h4 className="post-title">
                                  {x.title.width > 50 ? (
                                    <h4 className="post-title1 textInBlog">
                                      <Typography
                                        variant="h5"
                                        sx={{
                                          color: "#efefef",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {x.title.slice(0, 50)} <b>{"   ..."}</b>
                                      </Typography>
                                    </h4>
                                  ) : (
                                    <h4 className="post-title1 textInBlog">
                                      <Typography
                                        variant="h5"
                                        sx={{
                                          color: "#efefef",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {x.title}
                                      </Typography>
                                    </h4>
                                  )}
                                </h4>
                              </div>
                              <div className="dlab-post-text">
                                <p
                                  style={{
                                    maxHeight: "5.5rem",
                                    overflow: "hidden",
                                  }}
                                >
                                  {x.section}
                                </p>
                              </div>
                              <div className="dlab-post-readmore">
                                <button
                                  className={"readMoreBlogbtn"}
                                  onClick={() => history.push(`/blog/:${x.id}`)}
                                >
                                  READ MORE
                                </button>

                                {/* <Link to={`/blog/:${x.id}`} title="READ MORE" rel="bookmark" className="site-button">READ MORE
												<i className="ti-arrow-right"></i>
											</Link> */}
                              </div>
                            </div>
                          </div>
                        ))
                    : null}
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              <StyledPagination
                count={pageCount}
                variant="outlined"
                color="primary"
                sx={{ marginBottom: "1rem" }}
                page={page}
                onChange={(e) => {
                  if (e.target.innerText) {
                    setPage(+e.target.innerText);
                    history.push(`/blogList/page=${e.target.innerText}`);
                  } else if (
                    e.target.innerHTML ===
                    '<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>'
                  ) {
                    setPage(parseInt(page) - 1);
                    history.push(`/blogList/page=${parseInt(page) - 1}`);
                  } else {
                    setPage(parseInt(page) + 1);
                    history.push(`/blogList/page=${parseInt(page) + 1}`);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default BlogList;
