import { useTheme } from "@emotion/react";
import { Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Typography from '@mui/material/Typography';
import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from 'xlsx';
import { getRequest, postRequest } from "../../request";
import Loading from "../components/loading";
import ResponsiveTable from "../components/responsiveTable/ResponsiveTable";

const excelImg = require("../../images/our-work/excelTemplate.png");
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#fff',
  boxShadow: 24,
  borderRadius: '0.5rem',
  pt: 2,
  px: 4,
  pb: 3,
  padding: '3rem'
};
function PriceTable() {
  const theme = useTheme();
  const inputRef = useRef();
  const [priceData, setPriceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedFileText, setUploadedFileText] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const token = localStorage.getItem('token');
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const fetchData = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/prices`)
    if (data && Object.keys(data).length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i][0].type) {
          for (let j = 0; j < data[i].length; j++) {
            data[i][j].customerPrice = data[i][j].customerPrice?.toLocaleString();
          }
        }
      }
      setPriceData(data.filter(x => x[0].type));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const col = [
    "Product",
    "Type",
    "Quantity",
    "Packing",
    "DeliveryTerm",
    "Market",
    "Price",
  ];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        setUploadedFileText(inputRef.current.files[0].name);
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { range: 2 });
        const tempData = json.filter(x => x.product);
        const { error, message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/Product/prices`, tempData);
        if (message === 'ok') {
          toast.success('file uploaded successfully!');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(error);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }
  const handleCloseModal = () => {
    setShowInfo(false)
  }
  return (
    <div>
      <Box className='pricetable bg-dark' >
        <Box sx={{ marginTop: "150px" }}>
          <div className="container-product-list " style={{ marginTop: 0 }}>
            <div className="w-10 flex row justify-content-center">
              <h1 className="text-white text-center border-bottom px-auto" style={{ width: "150px" }}>Prices</h1>
            </div>
            <div className="ray-product1" />
            <div className="ray-product2" />
          </div>

          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '2rem', marginRight: '2rem', justifyContent: 'space-between', width: '95vw' }}>
            {decode.role === "SuperAdmin" ?
              <Box>
                <label htmlFor="upload" style={{ color: '#fff', fontWeight: 'normal', cursor: 'pointer' }}><img src={excelImg} alt="" />{uploadedFileText ? '' : ' Upload EXCEL File Here...'} </label>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={inputRef}
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
                <Typography variant="body1" style={{ color: '#fff' }}>{uploadedFileText}</Typography>
              </Box>
              : null}
          </Box>
        </Box>
        {!isLoading ? (
          <Box
            sx={{
              // position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingBottom: '2rem'
            }}
          >
            <div>
              {priceData?.map((row) => (
                <>
                  {console.log("row", row)}
                  <ResponsiveTable data={row} rows={['Product', 'Type', 'Min Quantity', 'Packing', '	Delivery Term	', 'Market', 'Offered Price']} />
                </>
              ))}
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "82.3vh",
            }}
          >
            <Loading type="cylon" color="#fff" />
          </Box>
        )}
        <div>
          end
        </div>
      </Box>

      <Modal
        open={showInfo}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 620, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '30rem' }}>
          <Typography variant="h6" color="initial">The Price is based on quantity as per below table:</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: theme.palette.primary.main }}>
                  <TableCell sx={{ color: '#fff' }} align="center">Price (USD)</TableCell>
                  <TableCell sx={{ color: '#fff' }} align="center">QTY (MT)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    -10
                  </TableCell>
                  <TableCell align="center">Over 220 MT</TableCell>

                </TableRow>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    -5
                  </TableCell>
                  <TableCell align="center">132-220</TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="body1" color="initial"><span style={{ fontWeight: 'bold' }}>Note1:</span> 20% in advanced, balance payment against Shipping documents</Typography>
          <Typography variant="body1" color="initial"><span style={{ fontWeight: 'bold' }}>Note2:</span> Payment term is negotiable based on the market/Delivery term</Typography>
          <Typography variant="body1" color="initial"><span style={{ fontWeight: 'bold' }}>Note3:</span> Currency Payment  should be in USD, AED or EURO</Typography>
        </Box>
      </Modal>
      {/* <div className="fixed">
      </div> */}

    </div>
  );
}

export default PriceTable;
