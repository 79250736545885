import { Delete } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import DeleteModal from "./deleteModal";
import { useState } from "react";
import { Box } from "@mui/system";
const commentImage = require("../../images/our-work/employee/manEmployee.png");

function CommentReplying({ comment, setRepliedCommentId, commentList }) {
  const token = localStorage.getItem("token");
  const [deletedComment, setDeletedComment] = useState(-1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  

  return (
    <div>
      <DeleteModal openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} parameterName="comment" deleteURL={`${process.env.REACT_APP_BASE_URL}/BlogComment/delete/${deletedComment}`} destination={null}/>
      <li
        className={comment.replayId === 0 ? "comment" : "replayComment"}
        key={comment.id}
      >
        <div className="comment-body">
          <div className="comment-author vcard">
            {" "}
            <img className="avatar photo" src={commentImage} alt="" />{" "}
            <cite className="fn">{comment.user}</cite>{" "}
            <span className="says">says:</span>{" "}
            <Box sx={{display:'flex',flexDirection:'column'}}>
              <Typography sx={{marginRight:'1.5rem !important'}}>
                <b>commentId:{comment.id}</b>
              </Typography>
              {comment.replayId !== 0 ? (
                <Typography>
                  <b>replayed comment {comment.replayId}</b>
                </Typography>
              ) : (null)}
            </Box>
          </div>
          <div className="comment-meta">
            {" "}
            <Link>
              {new Date(comment.createDateTime).toString().split("GMT")[0]}
            </Link>{" "}
          </div>
          <p style={{overflowWrap:'break-word'}}>{comment.value}</p>
          <div className="reply">
            {" "}
            <Link
              className="comment-reply-link"
              onClick={() => setRepliedCommentId(comment.id)}
            >
              Reply
            </Link>{" "}
          </div>
          {decode.role === "SuperAdmin" ? (
            <div className="DeleteComment">
              <IconButton
                aria-label=""
                onClick={() => {
                  setDeletedComment(comment.id);
                  setOpenDeleteModal(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          ) : null}
        </div>
      </li>
      {commentList
        .filter((x) => x.replayId === comment.id)
        .map((cnt) => {
          return (
            <CommentReplying
              key={cnt.id}
              comment={cnt}
              setRepliedCommentId={setRepliedCommentId}
              commentList={commentList.filter((x) => x.replayId === comment.id)}
            />
          );
        })}
    </div>
  );
}

export default CommentReplying;
