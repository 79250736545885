import { Send } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import Loading from "../components/loading";
import { Header12 } from "../layout/header12";
const bg = require("./../../images/banner/bnr5.jpg");

const costumerDataType = {
  companyName: "",
  name: "",
  telephone: "",
  email: "",
  address: "",
  country: "",
  city: "",
  state: "",
  description: "",
  producer: "",
  count: "",
};
const styles = {
  containerBox: {
    display: "flex",
    width: "80vw",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "50vh",
    marginBottom: "2rem",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gap: "5%",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between",
    height: "5rem",
  },
  innerBoxMobile: {
    display: "grid",
    width: "100%",
    // gap: "5%",
    gridTemplateColumns: "auto",
  },
  image: {
    width: "80%",
    display: 'flex',
    justifyContent: 'center',
    borderRadius: "5px",
  },
  imgMobile: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    margin: '2rem'
  }
};
const productImage = require("../../images/our-work/chemicals/chemical.png");
function RegisterOrder() {
  const params = useParams();
  const productId = params.productId.split(":")[1];
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [imageList, setimageList] = useState([]);
  const [product, setProduct] = useState({});
  const [producerList, setProducerList] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valueData, setValueData] = useState([]);
  const token = localStorage.getItem("token");
  let decode;
  if (token) {
    decode = jwtDecode(token);
  }
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });
  const [costumerData, setCostumerData] = useState(costumerDataType);
  const fetchData = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Product/${productId}`
    );
    if (data && Object.keys(data).length) {
      setProduct(data);
      setTimeout(() => {
        setIsLoading(true);
      }, 1500);
    } else {
      toast.error(error);
    }
  };
  const fetchCities = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/State/cities/${costumerData.state}`
    );
    if (data && Object.keys(data).length) {
      setCityList(data);
    } else {
      toast.error(error);
    }
  };
  const fetchSpecs = async () => {
    const unitList = [];
    const response = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Product/specs/${productId}`
    );
    if (response.data && Object.keys(response.data).length) {
      setSpecs(response.data);
      const res = [];
      for (let i = 0; i < response.data.length; i++) {
        res[i] = await getRequest(
          `${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${response.data[i].productSpecId}`
        );
        res[i].data.map((x) => {
          unitList.push(x);
        });
      }
    } else {
      toast.error(response.error);
    }
    setValueData(unitList);
    setimageList(unitList.filter((x) => x.unit.unitName === "image"));
  };

  const fetchCountry = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Country`
    );
    if (data && Object.keys(data).length) {
      setCountryList(data);
    } else {
      toast.error(error);
    }
  };
  const fetchState = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Country/states/${costumerData.country}`
    );
    if (data && Object.keys(data).length) {
      setStateList(data);
    } else {
      toast.error(error);
    }
  };
  const fetchProducer = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Product/producers/${productId}`
    );
    if (data && Object.keys(data).length) {
      setProducerList(data);
    } else {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchProducer();
    fetchCountry();
    fetchSpecs();
  }, []);
  useEffect(() => {
    if (costumerData.country) {
      setCostumerData({ ...costumerData, state: "", city: "" });
      fetchState();
    } else {
      setStateList([]);
    }
  }, [costumerData.country]);
  useEffect(() => {
    if (costumerData.state) {
      setCostumerData({ ...costumerData, city: "" });
      fetchCities();
    } else {
      setStateList([]);
    }
  }, [costumerData.state]);

  const handleChangeCostumer = (event) => {
    setCostumerData({
      ...costumerData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSendInfo = async () => {
    const myData = {
      productId: parseInt(productId),
      producerId: costumerData.producer,
      customerId: "8951612f-1a55-47a0-a1d6-2dd614b5f4a9",
      deliverCityId: 1,
      customerDescription: costumerData.description,
      amount: costumerData.count,
      qty: 0,
      updateDateTime: "2022-09-10T14:19:54.072Z",
      inserServerDateTime: "2022-09-10T14:19:54.072Z",
    };
    const { message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/Order`,
      myData
    );
    if (message === "ok") {
      setCostumerData(costumerDataType);
      toast.success("request has been sent successfully!");
    }
  };
  return (
    <div style={{ background: "#000" }}>
      <Header12 />
      <div className="dlab-bnr-inr overlay-black-middle" >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Register Order</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li><Link to="/">Home</Link></li>
                <li>Register Order</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isLoading ?
        <Box
          sx={isDesktop ? {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "81vh",
            margin: "2rem 15rem",
            "& .MuiSelect-select": {
              color: '#efefef'
            },
            "& .MuiInputBase-root": {
              color: '#efefef'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              color: '#efefef',
              borderColor: '#efefef !important'
            },

            "& .MuiFormLabel-root": {
              color: '#efefef'
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: '#efefef'
            },
            "& .MuiSvgIcon-root ": {
              color: "#fff"
            },
            "& .MuiFormLabel-root": {
              color: '#efefef'
            },
            '& .MuiFormLabel-root ': {
              "& .MuiAutocomplete-endAdornment": {
                color: '#efefef'
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#fff',
                },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                },
              },
            },
          } : {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiSelect-select": {
              color: '#efefef'
            },
            "& .MuiInputBase-root": {
              color: '#efefef'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              color: '#efefef',
              borderColor: '#efefef !important'
            },

            "& .MuiFormLabel-root": {
              color: '#efefef'
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: '#efefef'
            },
            "& .MuiSvgIcon-root ": {
              color: "#fff"
            },
            "& .MuiFormLabel-root": {
              color: '#efefef'
            },
            '& .MuiFormLabel-root ': {
              "& .MuiAutocomplete-endAdornment": {
                color: '#efefef'
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#fff',
                },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                },
              },
            },
          }}
        >
          <Box sx={isDesktop ? { display: "flex", width: "100%" } : { display: "flex", width: "100%", flexDirection: 'column' }}>
            <Box
              sx={isDesktop ? {
                width: "40%",
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center'
              } : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center'
              }}
            >
              <div style={isDesktop ? styles.image : styles.imgMobile}>
                <img className="rounded"
                  src={imageList[0]?.productSpecValueAmount ?
                    `${process.env.REACT_APP_IMG_URL}` +
                    imageList[0]?.productSpecValueAmount : `${process.env.REACT_APP_IMG_URL}` + productImage
                  }
                  alt=""
                  style={{ boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }}
                />
              </div>
              <div style={{ width: '80%', display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: "bold" }} className="text-light rounded">
                  {product.productName}
                </Typography>
                <Typography>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }} className="text-white rounded">
                    {valueData.find((x) => x.unit.unitName === "price")
                      ? "price: " +
                      valueData.find((x) => x.unit.unitName === "price")
                        .productSpecValueAmount +
                      " $"
                      : null}
                  </Typography>
                </Typography>
              </div>
            </Box>
            <Box sx={isDesktop ? { width: "60%" } : { width: "100%" }}>
              <div style={isDesktop ? styles.innerBox : styles.innerBoxMobile}>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <TextField
                    variant="outlined"
                    label="Telephone"
                    name="telephone" className=" rounded"
                    fullWidth
                    value={costumerData.telephone}
                    onChange={handleChangeCostumer}
                  />
                </Box>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <TextField
                    variant="outlined" className=" rounded"
                    label="Email"
                    fullWidth
                    name="email"
                    value={costumerData.email}
                    onChange={handleChangeCostumer}
                  />
                </Box>
              </div>
              <div style={isDesktop ? styles.innerBox : styles.innerBoxMobile} >
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <FormControl fullWidth className=" rounded" >
                    <InputLabel id="country" className="">Country</InputLabel>
                    <Select
                      label="Country"
                      name="country"
                      value={costumerData.country}
                      onChange={handleChangeCostumer}
                    >
                      {countryList.map((Country, index) => (
                        <MenuItem key={index} value={Country.countryId}>
                          {Country.countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <FormControl fullWidth className=" rounded">
                    <InputLabel id="state">state</InputLabel>
                    <Select
                      label="state"
                      name="state"
                      value={costumerData.state}
                      onChange={handleChangeCostumer}
                    >
                      {stateList.map((state, index) => (
                        <MenuItem key={index} value={state.stateId}>
                          {state.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div style={isDesktop ? styles.innerBox : styles.innerBoxMobile}>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <FormControl fullWidth className=" rounded">
                    <InputLabel id="city">City</InputLabel>
                    <Select
                      label="City"
                      name="city"
                      value={costumerData.city}
                      onChange={handleChangeCostumer}
                    >
                      {cityList.map((city, index) => (
                        <MenuItem key={index} value={city.cityId}>
                          {city.cityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}>
                  <FormControl fullWidth className=" rounded">
                    <InputLabel id="city">producer</InputLabel>
                    <Select
                      label="producer"
                      name="producer"
                      value={costumerData.producer}
                      onChange={handleChangeCostumer}
                    >
                      {producerList.map((prod, index) => (
                        <MenuItem key={index} value={prod.producerId}>
                          {prod.producerName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div style={isDesktop ? styles.innerBox : styles.innerBoxMobile}>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }} >
                  <TextField className=" rounded"
                    variant="outlined"
                    label="count"
                    name="count"
                    fullWidth
                    value={costumerData.count}
                    onChange={handleChangeCostumer}
                  />
                </Box>
                <Box sx={{ minWidth: 350, marginBottom: '8px' }}></Box>
              </div>
              <div >
                <TextField
                  sx={{ width: "100%", marginBottom: '1.5rem' }}
                  label="address"
                  multiline
                  name="address" className=" rounded"
                  value={costumerData.address}
                  onChange={handleChangeCostumer}
                  maxRows={4}
                  rows={2}
                />
              </div>
              <div >
                <TextField
                  sx={{ width: "100%" }}
                  label="description"
                  multiline
                  name="description" className=" rounded"
                  value={costumerData.description}
                  onChange={handleChangeCostumer}
                  maxRows={4}
                  rows={2}
                />
              </div>
            </Box>
          </Box>


          <Box
            sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: "0.5rem", width: "200px" }}
              onClick={handleSendInfo}
            >
              send Information <Send />
            </Button>
          </Box>
        </Box> :
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="cylon" color="#000" />
        </Box>
      }
    </div>
  );
}

export default RegisterOrder;
