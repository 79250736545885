import { useTheme } from "@emotion/react";
import { Add, Close, Delete } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import DefineSpeces from "../components/defineSpeces";
import DeleteModal from '../components/deleteModal';

const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "37rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "2%",
    padding: "5% 0"
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "2%",
    padding: "5% 0"
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-around",
  },
  
  newclass1:{
    position: "absolute",
    width: "unset",
    background: "none",
    left: "8%",
    top: "27%",
  },
  newclass2:{
    position: "absolute",
    width: "unset",
    background: "none",
    left: "2%",
    top: "27%",
  },
};
function CreateNewProduct() {
  const code = (Math.random()*1000000).toFixed(0);
  const specCode = (Math.random()*1000000).toFixed(0);
  const params = useParams();
  const history = useHistory();
  const token = localStorage.getItem('token');
  let productId;
  const theme = useTheme();
  if(params.productId[0]===':'){
    productId = params.productId.split(':')[1];
  }else{
    productId = params.productId;
  }
  const [images, setImages] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [specUnitsList, setspecUnitsList] = useState([]);
  const [hovered, sethovered] = useState('')
  const [specesList, setSpecesList] = useState([]);
  const [selectedSpec, setSelectedSpec] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteImgModal, setOpenDeleteImgModal] = useState(false);
  const [deletedImgId, setDeletedImgId] = useState(0);
  const [deletedProductId, setDeletedProductId] = useState(0);
  const [productData, setProductData] = useState({
    productCode: `product-${code}`,
    productName: "",
    productLocalName: "",
    productDescription: "",
    // parent: "",
    productPrice:"",
    productSubCategoryId: "",
    industryId: "",
  });
  const [newUnit, setNewUnit] = useState({
    productSpecId: "",
    productSpecValueId:"",
    productSpecValueAmount: "",
    unitCode: "",
    unitName: "",
    unitId: "",
  });
  const [specesData, setSpecesData] = useState({
    productSpecCode: `spec-${specCode}`,
    productSpecName: "",
    productSpecLocalName: "",
    productSpecDescription: "",
    productSpecTypeId:""
  });
  useEffect(() => {
    if (productId && productId !== "0") {
      fetchProductData();
    }
    // fetchParents();
    fetchIndustries();
    fetchSubCategories();
  }, []);
  const bg = require("./../../images/banner/bnr5.jpg");

  const fetchProductData = async () => {
    
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/product/${productId}`)
      
      const unitList=[];
      const res = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/specs/${productId}`)
      
      if(data && Object.keys(data).length){
        setSpecesList(res.data);
        const r = []
        for (let i = 0; i < res.data.length; i++) {
          r[i] = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${res.data[i].productSpecId}`)
          r[i].data.map((x) => {
              unitList.push(x);
            });
        }
        const convertProductData = {
          productCode: data.productCode,
          productName: data.productName,
          productLocalName: data.productLocalName,
          productDescription: data.productDescription,
          // parent: data.parent,
          productSubCategoryId: data.subCategory.productSubCategoryId,
          industryId: data.industry.industryId,
          productPrice:unitList.find((x) => x.unit.unitName === "price")
          ? unitList.find((x) => x.unit.unitName === "price")
          .productSpecValueAmount 
          : null
        };
        setProductData(convertProductData);
        // setProductData({convertProductData,productPrice:unitList.find((x) => x.unit.unitName === "price")
        // ? unitList.find((x) => x.unit.unitName === "price")
        //     .productSpecValueAmount 
        // : null})         
        setImages(unitList.filter(x=>x.unit.unitName === 'image').map(x=>({image:`${process.env.REACT_APP_IMG_URL}`+x.productSpecValueAmount,id:x.productSpecValueId})));
        
      }else{
        toast.error(res.error);
      }
  };
  // const fetchParents = async () => {
  //   const { status, data } = await axios.get(
  //     `${process.env.REACT_APP_BASE_URL}/Search/ProductParent`
  //   );
  //   if (status === 200) {
  //     setParentList(data);
  //   }
  // };
  const fetchIndustries = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Industry`)
      if(data && Object.keys(data).length){
        setIndustryList(data);
      }else{
        toast.error(error);
      }
  };
  const fetchSubCategories = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/ProductSubCategory`)
      if(data && Object.keys(data).length){
        setSubCategoryList(data);
      }else{
        toast.error(error);
      }
  };
  const handleChangeProducer = (event) => {
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };
  const priceRequests=async()=>{
    let specPrice;
    if(productData.productPrice){
      const code = (Math.random()*1000000).toFixed(0);
      
      const url = `${process.env.REACT_APP_BASE_URL}/ProductSpecType`
      const specResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      if(specResponse.data && Object.keys(specResponse.data).length){
        
        const specTypeId = specResponse.data.find(x=>x.productSpecTypeName === 'Pronix-System');
        let priceData
        let createPriceSpecRes
        if(specTypeId && specTypeId.productSpecTypeId){
          priceData = {
            productSpecCode: code,
            productSpecName: "price",
            productSpecLocalName: "price",
            productSpecDescription: "",
            productSpecTypeId:specTypeId.productSpecTypeId
          }
          createPriceSpecRes= await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,priceData);
        }
        else{
          const createSpecRes = await postRequest(url,{
            productSpecTypeCode: `Pronix_System_${code}`,
            productSpecTypeName: "Pronix-System",
            productSpecTypeLocalName: "Pronix-System"
          });
          if(createSpecRes.message==='ok'){
             createPriceSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,createSpecRes.data);
          }
        }
        const unitsRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Units`)
        
        const unitPrice = unitsRes.data.find(x=>x.name === 'price');
        if(unitPrice && unitPrice.id){
          if(createPriceSpecRes.message==='ok'){
            const res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:unitPrice.id});
            // setSpecesList(prevstate=>[...prevstate,{productSpecId:res.data}]);
            specPrice=res.data
            return specPrice;
          }
        }else{
          const createUnitRes  = await postRequest(`${process.env.REACT_APP_BASE_URL}/Unit`,{
            unitCode: `price_${code}`,
            unitName: "price",
            unitLocalName: "price",
            unitSubCategoryId: createPriceSpecRes.data,
          });

          if(createPriceSpecRes.message==='ok'){
            const res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,
            {productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:createUnitRes.data}); 
            
            specPrice=res.data;
            return specPrice
          }
        }
        
      }else{
        toast.error(specResponse.error);
      }

  }
  }
  const handleAddProduct=async()=>{
    let specPrice;
    if(productData.productPrice){
      const code = (Math.random()*1000000).toFixed(0);
      
      const url = `${process.env.REACT_APP_BASE_URL}/ProductSpecType`;
      const specResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      if(specResponse.data && Object.keys(specResponse.data).length){
        
        const specTypeId = specResponse.data.find(x=>x.productSpecTypeName === 'Pronix-System');
        let priceData
        let createPriceSpecRes
        if(specTypeId && specTypeId.productSpecTypeId){
          priceData = {
            productSpecCode: code,
            productSpecName: "price",
            productSpecLocalName: "price",
            productSpecDescription: "",
            productSpecTypeId:specTypeId.productSpecTypeId
          }
          createPriceSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,priceData);
          // createPriceSpecRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,priceData,
          // token?{ headers: {"Authorization" : `Bearer ${token}`} }:null);
        }
        else{
          const createSpecRes = await postRequest(url,{
            productSpecTypeCode: `Pronix_System_${code}`,
            productSpecTypeName: "Pronix-System",
            productSpecTypeLocalName: "Pronix-System"
          });
          if(createSpecRes.message==='ok'){
            createPriceSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,createSpecRes.data);
          }
        }
        const unitsRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Units`)        
        
        const unitPrice = unitsRes.data.find(x=>x.name === 'price');
        if(unitPrice && unitPrice.id){
          if(createPriceSpecRes.message==='ok'){
            const res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:unitPrice.id});
            // setSpecesList(prevstate=>[...prevstate,{productSpecId:res.data}]);
            specPrice=res.data
          }
        }else{
          const createUnitRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/Unit`,{
            unitCode: `price_${code}`,
            unitName: "price",
            unitLocalName: "price",
            unitSubCategoryId: createPriceSpecRes.data,
          });
          if(createPriceSpecRes.message==='ok'){
            const res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:createUnitRes.data});
            specPrice=res.data;
           
          }
        }
      }else{
        toast.error(specResponse.error);
      }
      
    // ///////////  important //////////////
    const r = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`)
      if(r.data && Object.keys(r.data).length){
      const productSpec = r.data.find(x=>x.productSpecValueId == specPrice);
      let specs=[];
      for(let i=0;i<specesList.length;i++){
        if(specesList[i].productSpecId){
          specs.push(specesList[i].productSpecId)
        }else{
          specs.push(specesList[i]);
        }
      } 
      specesList.push(productSpec.productSpecId);
      specs.push(productSpec.productSpecId);
      setSpecesList(specesList);
      productData.specs=specs;
      productData.parentId = 0;
      const finalResponse = await postRequest(`${process.env.REACT_APP_BASE_URL}/Product`,productData);
      if(finalResponse.message==='ok'){
        toast.success('product added');
        history.push('/products/page=1')
      }
      }else{
        toast.error(r.error);
      }
  }else{
    toast.error('price could not be empty');
  }
}

  const handleEditProduct=async()=>{
    let specPrice;
    if(productData.productPrice){
      const code = (Math.random()*1000000).toFixed(0);
      
      const url = `${process.env.REACT_APP_BASE_URL}/ProductSpecType`
      const specResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      if(specResponse.data && Object.keys(specResponse.data).length){
        
        const specTypeId = specResponse.data.find(x=>x.productSpecTypeName === 'Pronix-System');
        let priceData
        let createPriceSpecRes
        if(specTypeId && specTypeId.productSpecTypeId){
          priceData = {
            productSpecCode: code,
            productSpecName: "price",
            productSpecLocalName: "price",
            productSpecDescription: "",
            productSpecTypeId:specTypeId.productSpecTypeId
          }
          createPriceSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,priceData);
        }
        else{
          const createSpecRes  = await postRequest(url,{
            productSpecTypeCode: `Pronix_System_${code}`,
            productSpecTypeName: "Pronix-System",
            productSpecTypeLocalName: "Pronix-System"
          });
          
          if(createSpecRes.message==='ok'){
            createPriceSpecRes  = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,createSpecRes.data);
          }
        }
        const unitsRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Units`)
        
        const unitPrice = unitsRes.data.find(x=>x.name === 'price');
        if(unitPrice && unitPrice.id){
          if(createPriceSpecRes.message==='ok'){
            const res  = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:unitPrice.id});
            specPrice=res.data
          }
        }else{
          const createUnitRes  = await postRequest(`${process.env.REACT_APP_BASE_URL}/Unit`,{
            unitCode: `price_${code}`,
            unitName: "price",
            unitLocalName: "price",
            unitSubCategoryId: createPriceSpecRes.data,
          });
          if(createPriceSpecRes.message==='ok'){
            const res  = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createPriceSpecRes.data,productSpecValueAmount:productData.productPrice,unitId:createUnitRes.data});            
            specPrice=res.data;
            
          }
        }
        
      }else{
        toast.error(specResponse.error);
      }
  }
    const r = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`)
    const productSpec = r.data.find(x=>x.productSpecValueId == specPrice);
    let specs=[];
    if(r.data && Object.keys(r.data).length){

      // const productSpec = r.data.find(x=>x.productSpecValueId == specesList);
      for(let i=0;i<specesList.length;i++){
        if(specesList[i].productSpecId){
          specs.push(specesList[i].productSpecId)
        }else{
          specs.push(specesList[i])
        }
      } 
      // specesList.push(productSpec.productSpecId);
      setSpecesList(specs);
      productData.specs=specs;
      productData.parentId = 0;
      // const finalResponse = await axios.post('${process.env.REACT_APP_BASE_URL}/Product',productData);
      // if(finalResponse.status===200){
      //   toast.success('product added');
      // }
    }
    
    const temp = productData;
    // for(let i=0;i<specesList.length;i++){
    //   if(specesList[i].productSpecId){
    //     specs.push(specesList[i].productSpecId)
    //   }else{
    //     specs.push(specesList[i]);
    //   }
    // } 
    temp.specs = specs;
    temp.productId = productId;
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Product/edit`,temp);
    
    if(message === 'ok'){
      toast.success('product edited successfully!');
      history.push('/products/page=1')
    }
  }
  // const handleChangeFile=(e)=>{
  //   formData.append('file',e.target.files[0]);
  //   console.log(formData)
  // }

  const handleChangeFile=async(e)=>{
    const formData = new FormData();
    formData.append('file',e.target.files[0]);    
    const response = await postRequest(`${process.env.REACT_APP_BASE_URL}/Product/Image`,formData);
    if(response.message === 'ok'){
      const imgUrl = response.data.dbPath;
      setImages(prevstate=>[...prevstate,`${process.env.REACT_APP_IMG_URL}`+response.data.dbPath]);
      const code = (Math.random()*1000000).toFixed(0);
      const specResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      let specTypeId;
      let createImageSpecRes;
      if(specResponse.data && Object.keys(specResponse.data).length){
        
        specTypeId = specResponse.data.find(x=>x.productSpecTypeName === 'Pronix-System');
        let imageData={}
        if(specTypeId.productSpecTypeId){
          imageData = {
            productSpecCode: code,
            productSpecName: "image",
            productSpecLocalName: "image",
            productSpecDescription: "",
            productSpecTypeId:specTypeId.productSpecTypeId
          }
          createImageSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,imageData);
        }else{
          const createSpecRes  = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`,{
            productSpecTypeCode: "1001",
            productSpecTypeName: "image",
            productSpecTypeLocalName: "image"
          });
          if(createSpecRes.message==='ok'){
            createImageSpecRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,createSpecRes.data);            
          }
        }        
        const unitsRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Units`)        
        const unitImage = unitsRes.data.find(x=>x.name === 'image');
        let res
        if(unitImage.id){
          if(createImageSpecRes.message==='ok'){
            const specRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/specs/${productId}`)
            
            if(specRes.data.find(x=>x.productSpecName === 'image')){
              const imgUnit = await getRequest(`${process.env.REACT_APP_BASE_URL}/Unit`);

              res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:specRes.data.find(x=>x.productSpecName === 'image').productSpecId,productSpecValueAmount:imgUrl,unitId:imgUnit.data.find(x=>x.unitName === 'image').unitId})
              // res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:createImageSpecRes.data,productSpecValueAmount:imgUrl,unitId:unitImage.id});            
            }
          }
        }else{
          const createUnitRes = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,
          {
            unitCode: "100101",
            unitName: "image",
            unitLocalName: "image",
            unitSubCategoryId: createImageSpecRes.data,
          });            

          if(createImageSpecRes.message==='ok'){
            res = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,
            {productSpecId:createImageSpecRes.data,productSpecValueAmount:imgUrl,unitId:createUnitRes.data}); 
          }
        }
        if(res.message==='ok'){
          const r = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`)
          if(r.data && Object.keys(r.data).length){
            
            const productSpec = r.data.find(x=>x.productSpecValueId == res.data);

              // const specs = specesList.map(x=>x.productSpecId);
              const specs=productSpec?.productSpecId;
              if(specs){
                setSpecesList(prevstate=>[...prevstate,specs]);
              }                  
          }
          }else{
            toast.error('something went wrong!')
          }
      }else{
        toast.error(specResponse.error);
      }
      
      }
    }
    const handleDeleteModal=(deletedProduct)=>{
      setDeletedProductId(deletedProduct);
      setOpenDeleteModal(true);
    }
    const handleDeleteImage=(id)=>{
      // const {error,data}= await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue/delete/${id}`);
      // if(data === ""){
      //   toast.success('image deleted successfully!');
      //   window.location.reload();
      // }else if(error){
      //   toast.error(error)
      // }
      setDeletedImgId(id);
      setOpenDeleteImgModal(true);
    }
  return (
    <div>
      <DeleteModal openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} parameterName='this product' deleteURL={`${process.env.REACT_APP_BASE_URL}/Product/delete/${deletedProductId}`} destination={'/'} setIsSuccessful={null}/>
      <DeleteModal openDeleteModal={openDeleteImgModal} setOpenDeleteModal={setOpenDeleteImgModal} parameterName='this Image' deleteURL={`${process.env.REACT_APP_BASE_URL}/ProductSpecValue/delete/${deletedImgId}`} destination={''} setIsSuccessful={null}/>
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Define Product</h1>
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li><Link to="/">Home</Link></li>
                        <li>Define Product</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <Box
        component="form"
        sx={selectedSpec ? styles.containerBox3 : styles.containerBox2}
      >
        <Box>
          <Box
            sx={{
              backgroundColor: "#ccc",
              borderRadius: "0.5rem 0.5rem 0rem 0rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
              <Typography sx={selectedSpec ? styles.newclass2 : styles.newclass1} variant="h5" color="initial">
              <span style={{
                fontWeight: "bold",
                color: "#343a40"
              }}>Product</span>
            </Typography>
          </Box>
          <Box sx={selectedSpec ? styles.box3 : styles.box2}>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                name="productCode"
                required
                value={productData.productCode}
                error={!productData.productCode}
                onChange={handleChangeProducer}
              />
              <TextField
                variant="outlined"
                label="Name"
                name="productName"
                required
                value={productData.productName}
                error={!productData.productName}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                required
                error={!productData.productLocalName}
                name="productLocalName"
                value={productData.productLocalName}
                onChange={handleChangeProducer}
              />

              <TextField
                variant="outlined"
                label="description"
                name="productDescription"
                multiline={true}
                maxRows={4}
                value={productData.productDescription}
                onChange={handleChangeProducer}
              />
            </div>

            <div style={styles.innerBox}>
              {/* <Box sx={{ minWidth: 211 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, value) =>
                    setProductData({ ...productData, parent: value })
                  }
                  options={parentList.map((x) => x.name)}
                  sx={{ width: 211 }}
                  renderInput={(params) => (
                    <TextField {...params} label="parent" />
                  )}
                />
              </Box> */}
              <Box sx={{ minWidth: 211 }}>
                <FormControl fullWidth>
                  <InputLabel>industry</InputLabel>
                  <Select
                    label="industry"
                    required
                    error={!productData.industryId}
                    name="industryId"
                    value={productData.industryId}
                    onChange={handleChangeProducer}
                  >
                    {industryList.map((state, index) => (
                      <MenuItem key={index} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 211 }}>
                <FormControl fullWidth>
                  <InputLabel>sub category</InputLabel>
                  <Select
                    label="subCategory"
                    error={!productData.productSubCategoryId}
                    required
                    name="productSubCategoryId"
                    value={productData.productSubCategoryId}
                    onChange={handleChangeProducer}
                  >
                    {subCategoryList.map((category, index) => (
                      <MenuItem key={index} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            {/* <div style={styles.innerBox}>
              
              <div style={{ width: "211px" }}></div>
            </div> */}
            <div style={styles.innerBox}>
              <div>
              <Box
                  sx={{
                    width: 211,
                    height: 211,
                    boxShadow: "0px 0px 4px 2px #ddd",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  
                  <IconButton
                    color="primary"
                    component="label"
                    
                  >
                    <input type="file" onChange={(e)=>handleChangeFile(e)} style={{display:'none'}}/>
                    <CloudUploadIcon sx={{ fontSize: "4rem" }} />
                  </IconButton>
                  Upload Image
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row",marginTop:'5px' }}>
                  {images.map((image, index) => (
                    <div key={index}
                      className='imageList'
                      onMouseEnter={()=>sethovered(index)}
                      onMouseLeave={()=>sethovered('')}
                    >
                       <img
                        src={image.image}
                        alt=""
                        style={{
                          width: "5rem",
                          height: "4rem",
                        }}
                      />
                        {index === hovered?
                        <IconButton aria-label="" className="imgBtn"
                        onClick={()=>handleDeleteImage(image.id)}
                          sx={{
                            backgroundColor: '#00000025'
                          }}
                          >
                          <Delete/>
                        </IconButton>
                        :null}  
                      
                     
                      <div>
                        
                        {/* <IconButton onClick={() => onImageRemove(index)}>
                          <Delete style={{ color: "#e53935",fontSize:'1rem' }} />
                        </IconButton> */}
                      </div>
                    </div>
                  ))}
                </Box>

              </div>
              {/* <input type="file" onChange={handleChangeFile} /> */}
              <TextField
                variant="outlined"
                label="Price"
                type="number"
                error={!productData.productPrice}
                name="productPrice"
                value={productData.productPrice}
                onChange={handleChangeProducer}
              />
            </div>

            <div style={styles.innerBox}>
              <div style={{ width: "211px" }}></div>
              <ButtonGroup variant="contained">
                {productId && productId !== "0"?
                  <Button variant="contained" color="danger" sx={{ color: "#efefef" }} 
                  onClick={()=>handleDeleteModal(productId)}>
                    Delete
                    <Delete/>
                  </Button>
                :<Button color="danger" sx={{ color: "#efefef" }} onClick={
                  ()=>{
                    setProductData({
                      productCode: `product-${code}`,
                      productName: "",
                      productLocalName: "",
                      productDescription: "",
                      productPrice: "",
                      productSubCategoryId: "",
                      industryId: "",
                  });
                  setSpecesData({
                    productSpecCode: `spec-${specCode}`,
                    productSpecName: "",
                    productSpecLocalName: "",
                    productSpecDescription: "",
                    productSpecTypeId:""
                  })
                  setNewUnit({
                    productSpecId: "",
                    productSpecValueId:"",
                    productSpecValueAmount: "",
                    unitCode: "",
                    unitName: "",
                    unitId: "",
                  })
                  }
                }>
                  cancel
                  <Close />
                </Button>
                }
                {productId && productId !== "0"?
                
                <Button
                  color="edit"
                  sx={{ color: "#efefef" }}
                  onClick={handleEditProduct}
                >
                  edit
                  <Add />
                </Button>
                :
                <Button
                  color="primary"
                  sx={{ color: "#efefef" }}
                  onClick={handleAddProduct}
                  disabled={!productData.productCode || !productData.productName || !productData.productLocalName || !productData.industryId || !productData.productSubCategoryId}
                >
                  add
                  <Add />
                </Button>
                }
              </ButtonGroup>
            </div>
          </Box>
        </Box>
        <DefineSpeces
          specUnitsList={specUnitsList}
          setspecUnitsList={setspecUnitsList}
          specesList={specesList}
          selectedSpec={selectedSpec}
          setSelectedSpec={setSelectedSpec}
          setSpecesList={setSpecesList}
          newUnit={newUnit}
          setNewUnit={setNewUnit}
          specesData={specesData}
          setSpecesData={setSpecesData}
        />
      </Box>
    </div>
  );
}

export default CreateNewProduct;
