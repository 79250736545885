import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { AccountCircle, Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DeleteModal from '../components/deleteModal';
import UnitSubCategory from '../components/unitSubCategory';
import Units from '../components/units';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/sideBar';
import { Link } from 'react-router-dom';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      // gap: "2%",
      alignItems: "center",
      
      height: "82vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
    //   width:'33rem',
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
      width:'34rem',
      // width:'33rem',
      // width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "space-around",
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
      marginBottom:'0.5rem'
    },
    btn:{
      margin:'1rem 1rem'
    }
  };
  const bg = require("./../../images/banner/bnr5.jpg");
function UnitType() {
    const [unitCategoryList, setUnitCategoryList] = useState([]);
    const [deletedUnitCategory, setDeletedUnitCategory] = useState(-1);
    const [openModal, setOpenModal] = useState(false);
    const [deleteSuccessfull, setDeleteSuccessfull] = useState(false);
    const [unitSubCategoryList, setUnitSubCategoryList] = useState([]);
    const [editedUnitCategory, setEditedUnitCategory] = useState(-1);
    const [unitList, setUnitList] = useState([]);
    const [selectedUnitCategory, setSelectedUnitCategory] = useState(-1);
    const [selectedUnitSubCategory, setSelectedUnitSubCategory] = useState(-1);
    const code = (Math.random() * 1000000).toFixed(0);
    const [openUserModal, setOpenUserModal] = useState(false)
    const [unitCategory, setUnitCategory] = useState({
        unitCategoryCode: `unitCategory-${code}`,
        unitCategoryName: "",
        unitCategoryLocalName: ""
    });
    const token = localStorage.getItem('token');
    let decode={};
    if(token){
      decode = jwtDecode(token);
    }
    const fetchUnitCategorys=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory`);
        if(data && Object.keys(data).length){
            setUnitCategoryList(data);
        }
    }
    const handleChangeProducer = (event) => {
        setUnitCategory({
          ...unitCategory,
          [event.target.name]: event.target.value,
        });
    };
    const handleEditProducer=async()=>{
      const currentUnitCategory= {
        ...unitCategory,
        unitCategoryId:selectedUnitCategory,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory/edit`,currentUnitCategory)
      if(message ==='ok'){
        unitCategoryList[
          unitCategoryList.findIndex(
            (x) => x.unitCategoryId === selectedUnitCategory
          )
        ] = unitCategory;
        setUnitCategoryList(unitCategoryList);
        setSelectedUnitCategory(-1);
        
        setUnitCategory({
          unitCategoryCode: `unitCategory-${code}`,
          unitCategoryName: "",
          unitCategoryLocalName: "",
        });
        toast.success('UnitCategory edited successfully!');
      }
    }
    useEffect(() => {
      if(deleteSuccessfull){
        setUnitCategoryList(prevstate=>[...prevstate.filter(x=>x.unitCategoryId !== deletedUnitCategory)])
        setDeleteSuccessfull(false);
        setDeletedUnitCategory(-1);
      }
    }, [deleteSuccessfull])
    const handleAddProducer=async()=>{
        const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory`,unitCategory);
        if(message === 'ok'){
            toast.success('UnitCategory Added Successfully!');
            setUnitCategoryList((prevState)=>[...prevState,unitCategory]);
            setUnitCategory({
              unitCategoryCode: `unitCategory-${code}`,
              unitCategoryName: "",
              unitCategoryLocalName: ""
            })
        }
    }
    const handleDeleteUnitCategory = async(id) => {
      setOpenModal(true);
      setDeletedUnitCategory(id);        
    };
    const showCountries= async(unitCategoryId)=>{
      setSelectedUnitCategory(unitCategoryId);
      setSelectedUnitSubCategory(-1);
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory/subcategories/${unitCategoryId}`);
      if(data && Object.keys(data).length){
        setUnitSubCategoryList(data);
      }else{
        setUnitSubCategoryList([]);
      }
    }
    useEffect(() => {
      fetchUnitCategorys();
    }, []);
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpenUserModal(open);
    };
    
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Unit Type</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Unit Type</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {decode.role === 'SuperAdmin'?
            <Button color='primary' sx={styles.btn}  variant='contained' onClick={toggleDrawer( true)} >user options <AccountCircle/></Button>
            :null}
        <Box sx={styles.containerBox}>
          <Box sx={styles.box1}>
            <div style={{display:'flex',width:'100%',justifyContent:'initial'}}>
              <Typography
                sx={{
                  position:'relative',
                  top:'-1.2rem',
                  left:'1rem',
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                UnitCategory
              </Typography>
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="unitCategoryCode"
                value={unitCategory.unitCategoryCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="unitCategoryName"
                value={unitCategory.unitCategoryName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="unitCategoryLocalName"
                sx={{width:'170px'}}
                value={unitCategory.unitCategoryLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {editedUnitCategory !==-1?(
            <div style={styles.innerBox}>
              <ButtonGroup variant="contained">
                <Button color="danger" sx={{ color: "#efefef" }} 
                  onClick={()=>{
                      setUnitCategory({
                          unitCategoryCode: "",
                          unitCategoryName: "",
                          unitCategoryLocalName: ""
                      });
                      setSelectedUnitCategory(-1);
                  }}
                >
                  cancel <Close />
                </Button>
                <Button
                  color="edit"
                  sx={{ color: "#efefef" }}
                  onClick={handleEditProducer}
                >
                  edit <Edit />
                </Button>
              </ButtonGroup>
              <Box sx={{width:'170px'}}></Box>
            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{width:'170px'}}>
                  <ButtonGroup  variant="contained">
                    <Button
                      color="primary"
                      sx={{ color: "#efefef" }}
                      onClick={handleAddProducer}
                      disabled={
                        !unitCategory.unitCategoryCode ||
                        !unitCategory.unitCategoryLocalName ||
                        !unitCategory.unitCategoryName
                      }
                    >
                      add <Add />
                    </Button>
                  </ButtonGroup>

                </Box>
                <Box sx={{width:'170px'}}></Box>
              </div>
            )}

            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
            <Table aria-label="simple table"  sx={{maxHeight:"48rem",}}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">UnitCategory Code</TableCell>
                  <TableCell align="center">UnitCategory Name</TableCell>
                  <TableCell align="center" >Edit</TableCell>
                  <TableCell align="center">remove</TableCell>
                  <TableCell align="center">subCategory</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitCategoryList?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.unitCategoryCode}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.unitCategoryName}
                    </TableCell>
                    <TableCell  align="center">
                      <IconButton
                        color="edit"
                        onClick={() =>{
                          setEditedUnitCategory(row.unitCategoryId)
                          setUnitCategory(unitCategoryList.find(
                            (x) =>
                              x.unitCategoryId ===
                              row.unitCategoryId
                          ))
                        }
                        }
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="danger"
                        onClick={()=>
                          handleDeleteUnitCategory(row.unitCategoryId)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={()=>
                          showCountries(row.unitCategoryId)
                        }
                      >
                        <Visibility  color={selectedUnitCategory === row.unitCategoryId?'primary':'inherit'}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
          <SideBar open={openUserModal} toggleDrawer={toggleDrawer} />
           {selectedUnitCategory !==-1  ?
          <UnitSubCategory selectedUnitCategory={selectedUnitCategory} setSelectedUnitSubCategory={setSelectedUnitSubCategory} selectedUnitSubCategory={selectedUnitSubCategory} unitSubCategoryList={unitSubCategoryList} setUnitSubCategoryList={setUnitSubCategoryList} setUnitList={setUnitList} />
          :null}
            {
              selectedUnitSubCategory!==-1 ?
              <Units unitList={unitList} selectedUnitSubCategory={selectedUnitSubCategory}  setUnitList={setUnitList}/>
              :null 
            }
                    {/*{
            selectedState!==-1 ?
            <CityLocation selectedState={selectedState} cityList={cityList}  setCityList={setCityList} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>
            :null
          } */}
        </Box>
        {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="Country" deleteURL={`${process.env.REACT_APP_BASE_URL}/UnitCategory/delete/${deletedUnitCategory}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessfull}/>
            :null}
        <Footer/>
    </div>
  )
}

export default UnitType