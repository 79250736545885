import React from 'react';
//import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function ProductGallerySlider({ imageList, setSelectedImg, setOpenModal }) {
    const noImagFound = require("../../images/our-work/chemicals/No_Image_Available.jpg");
    const productModal = require('../../images/our-work/chemicals/productImageModel.png');
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false
    };
    const handleClickOnImg = (imageLink) => {
        setSelectedImg(imageLink);
        setOpenModal(true);
    }
    return (
        <Fragment>
            <Slider {...settings} className="m-b5 primary btn-style-2" id="sync1">
                {imageList.length ? imageList.map((item, id) => (
                    <div className="item">
                        <div className="mfp-gallery">
                            <div className="dlab-box">
                                <div className="p-5" onClick={() => handleClickOnImg(`${process.env.REACT_APP_IMG_URL}` + item.productSpecValueAmount)}>
                                    <img src={`${process.env.REACT_APP_IMG_URL}` + item.productSpecValueAmount}
                                        alt="" style={{ width: '100%', borderRadius: '7px' }}
                                    />
                                    <div className="overlay-bx">
                                        <div className="overlay-icon">
                                            <span data-exthumbimage="images/product/item2/item1.jpg" data-src="images/product/item2/item1.jpg" className="check-km" title="Image 1 Title will come here">
                                                <i className="ti-fullscreen"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) :
                    <div className="item">
                        <div className="mfp-gallery">
                            <div className="dlab-box">
                                <div className="dlab-thum-bx dlab-img-overlay1 ">
                                    <img src={noImagFound} alt="" style={{ width: '100%' }} />
                                    <div className="overlay-bx">
                                        <div className="overlay-icon">
                                            <span data-exthumbimage="images/product/item2/item1.jpg" data-src="images/product/item2/item1.jpg" className="check-km" title="Image 1 Title will come here">
                                                <i className="ti-fullscreen"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </Slider>

        </Fragment>
    )

}
export default ProductGallerySlider;