import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';

const MagazineSidebar = ({magazineLists,setSearchWord}) => {
    const [searchBox, setSearchBox] = useState('');
    const history = useHistory();
    const handleSearch=(e)=>{
        e.preventDefault();
        setSearchWord(searchBox);
    }
    return ( 
        <div className="col-lg-3" style={{zIndex:0}}>
            <Button color='secondary' variant='contained' sx={{marginBottom:'2rem'}} onClick={()=>history.push('/magazine/0')}>add first magazine</Button>
            <aside  className="side-bar sticky-top">
                <div className="widget">
                    <h5 className="widget-title style-1">Search</h5>
                    <div className="search-bx style-1">
                        <form role="search" method="post" onSubmit={handleSearch}>
                            <div className="input-group">
                                <input name="text" className="form-control" placeholder="Enter your keywords..." value={searchBox} onChange={(e)=>setSearchBox(e.target.value)} type="text"/>
                                <span className="input-group-btn">
                                    <button type="submit" className="fa fa-search text-primary"></button>
                                </span> 
                            </div>
                        </form>
                    </div>
                </div>
                <div className="widget recent-posts-entry">
                    <h5 className="widget-title style-1">Recent Posts</h5>
                    <div className="widget-post-bx">
                        {
                            magazineLists.slice(0,3).map(x=>
                                <div className="widget-post clearfix" key={x.id}>
                                <div className="dlab-post-media"> 
                                    <img src={`${process.env.REACT_APP_IMG_URL}`+x.picture} width="200" height="143" alt=""/> 
                                </div>
                                <div className="dlab-post-info">
                                    <div className="dlab-post-meta">
                                        <ul>
                                            <li className="post-date"> <strong>{x.serverDateTime.split(' ')[2]} {x.serverDateTime.split(' ')[1]}</strong> <span> {x.serverDateTime.split(' ')[3]}</span> </li>
                                            <li className="post-author"> By <Link >{x.user}</Link> </li>
                                        </ul>
                                    </div>
                                    <div className="dlab-post-header">
                                        <h6 className="post-title" ><Link to={`/magazine/:${x.id}`} >{x.title}</Link></h6>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                        
                    </div>
                </div>
            </aside>
        </div>
    );
}
 
export default MagazineSidebar;