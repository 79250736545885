import {
    Button,
    TextField,
    IconButton,
    Box,
    Autocomplete,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Fab,
    ButtonGroup, Typography,
  } from "@mui/material";
  import React, { useState, Fragment } from "react";
  import { Add, Close, Delete, Edit,Visibility } from "@mui/icons-material";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Paper from "@mui/material/Paper";
  import { toast, ToastContainer } from "react-toastify";
  import axios from "axios";
import { getRequest } from "../../request";

  const styles = {
    box2: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width:'45rem',
      display: "flex",
      flexDirection:'column',
    },
    box3: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      width:'37rem',
      display: "flex",
      flexDirection: "column",
    },
    unitBox:{
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      padding:'0.5rem'
    },
    unit:{
      display: "flex",
      flexDirection: "row",
      justifyContent:'space-around',
    },
    innerBox: {
      display: "grid",
      width: "100%",
      padding:'1rem',
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
    },
    table2:{
      margin: '0rem 5rem',width:'auto'
    },
    table3:{margin: '0rem 3.5rem',width:'auto'}
  };
function DefineSubCategories({unitSubCategoryList,setUnitSubCategoryList,selectedUnitCategory}) {
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState({
    unitCode: "",
    unitName: "",
    unitLocalName: ""})
  const [selectedSubCat, setselectedSubCat] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [isShowUnits, setIsShowUnits] = useState(false);
  const [unitSubCategory, setUnitSubCategory] = useState({
    unitSubCategoryCode: "",
    unitSubCategoryName: "",
    unitSubCategoryLocalName: "",
  });
  const handleChange = (event) => {
    setUnitSubCategory({ ...unitSubCategory, [event.target.name]: event.target.value });
  };
  const handleChangeUnit = (event) => {
    setUnit({ ...unit, [event.target.name]: event.target.value });
  };
  const handleRemoveSubCategory = (code) => {
    setUnitSubCategoryList((prevstate) => [...prevstate.filter((x) => x.unitSubCategoryCode !== code)]);
  };
  const handleRemoveUnit = (code) => {
    setUnits((prevstate) => [...prevstate.filter((x) => x.unitCode !== code)]);
  };

  const handleEditUnit=()=>{
    units[(units.findIndex((x)=>x.unitCode === selectedUnit))] = unit;
    const list = units;
    setUnits(list);
    setSelectedUnit('');
    setUnit({
      unitCode: "",
      unitName: "",
      unitLocalName: ""})
  }

  
  const handleEditSubCategory = () => {
    unitSubCategoryList[(unitSubCategoryList.findIndex((x)=>x.unitSubCategoryCode === selectedSubCat))] = unitSubCategory;
    const list = unitSubCategoryList;
    setUnitSubCategoryList(list);
    setselectedSubCat('');
    setUnitSubCategory({
      unitSubCategoryCode: "",
      unitSubCategoryName: "",
      unitSubCategoryLocalName: "",
    })
  };

  const handleShowUnits = async(id,row) =>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/UnitSubCategory/units/${id}`)
      if(data && Object.keys(data).length){
        setUnits(data);
        setIsShowUnits(true);
      }else{
        toast.error(error);
      }
  }
  return (
    <Fragment>
      {selectedUnitCategory &&  unitSubCategoryList.length?
      <Box >
        <Box sx={{backgroundColor:'#ccc',borderRadius:'0.5rem 0.5rem 0rem 0rem',width:'100%',display:'flex',justifyContent:'center'}}><Typography variant="h5" color="initial"><span>Sub Categories</span></Typography></Box>
        <Box sx={selectedUnitCategory? styles.box3:styles.box2}>
            
            <div style={styles.innerBox} >
                <TextField
                    variant="outlined"
                    label="Code"
                    name="unitSubCategoryCode"
                    value={unitSubCategory.unitSubCategoryCode}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    label="Name"
                    name="unitSubCategoryName"
                    value={unitSubCategory.unitSubCategoryName}
                    onChange={handleChange}
                />
            </div>  
            <div style={styles.innerBox}>
                <TextField
                    variant="outlined"
                    label="localName"
                    name="unitSubCategoryLocalName"
                    value={unitSubCategory.unitSubCategoryLocalName}
                    onChange={handleChange}
                />
                <div style={{width:'211px'}}></div>
            </div>
            <div style={styles.innerBox} >
                <ButtonGroup>
                  {
                    selectedSubCat?null:
                    <Button variant="contained" color="primary" sx={{color:'#fff'}} onClick={()=>setUnitSubCategoryList([...unitSubCategoryList,unitSubCategory])}>
                      add <Add/>
                    </Button>
                  }
                  {selectedSubCat?
                      <Button variant="contained" color="edit" sx={{color:'#fff'}} onClick={handleEditSubCategory}>
                          edit <Edit/>
                      </Button>
                  :null}
                  <Button variant="contained" sx={{bgcolor: "#e91e63",color:'#fff'}} onClick={()=>{
                    setselectedSubCat('');
                    setUnitSubCategory({
                      unitSubCategoryCode: "",
                      unitSubCategoryName: "",
                      unitSubCategoryLocalName: "",
                    })}
                    }>
                    cancel <Close/>
                  </Button>

                </ButtonGroup>
                <div style={{width:'211px'}}></div>
            </div>
        
        <div style={styles.innerBox}>
        <div style={{width:'211px',float:'right',display:'flex',justifyContent:'end'}}>
        </div>
            
        </div>
        {unitSubCategoryList.length ? (
          <TableContainer sx={selectedUnitCategory?styles.table3:styles.table2} component={Paper}>
            <Table sx={{ width: '95%',margin:'0.5rem' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>code</TableCell>
                  <TableCell align="right">name</TableCell>
                  <TableCell align="right">edit</TableCell>
                  <TableCell align="right">remove</TableCell>
                  <TableCell align="right">show units</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitSubCategoryList.map((row) => (
                  <TableRow
                    key={row.unitSubCategoryId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.unitSubCategoryCode}
                    </TableCell>
                    <TableCell align="right">{row.unitSubCategoryName}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => {
                        setselectedSubCat(row.unitSubCategoryCode);
                        setUnitSubCategory(unitSubCategoryList.find(x=>x.unitSubCategoryCode === row.unitSubCategoryCode))
                      }}>
                        <Edit style={{ color: "#009688" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label=""
                        onClick={() => handleRemoveSubCategory(row.unitSubCategoryCode)}
                      >
                        <Delete style={{ color: "#e53935" }}/>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label=""
                        onClick={()=>handleShowUnits(row.unitSubCategoryId,row.unitSubCategoryCode)}
                      >
                        <Visibility/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        </Box>
        
      </Box>:null
      }
      {isShowUnits && units.length ? (
        <Box>

        <Box sx={{backgroundColor:'#ccc',borderRadius:'0.5rem 0.5rem 0rem 0rem',width:'100%',display:'flex',justifyContent:'center'}}><Typography variant="h5" color="initial"><span>Units</span></Typography></Box>
        <Box sx={selectedUnit? styles.box3:styles.box2}>
        <Box sx={styles.unitBox}>
          <div style={styles.innerBox} >
                <TextField
                    variant="outlined"
                    label="Code"
                    name="unitCode"
                    value={unit.unitCode}
                    onChange={handleChangeUnit}
                />
                <TextField
                    variant="outlined"
                    label="Name"
                    name="unitName"
                    value={unit.unitName}
                    onChange={handleChangeUnit}
                />
            </div>  
            <div style={styles.innerBox}>
                <TextField
                    variant="outlined"
                    label="localName"
                    name="unitLocalName"
                    value={unit.unitLocalName}
                    onChange={handleChangeUnit}
                />
                <div style={{width:'211px'}}></div>
            </div>
            <div style={styles.innerBox} >
                <ButtonGroup>
                  {
                    selectedUnit?null:
                    <Button variant="contained" color="primary" sx={{color:'#fff'}} onClick={()=>setUnits([...units,unit])}>
                      add <Add/>
                    </Button>
                  }
                  {selectedUnit?
                      <Button variant="contained" color='edit' sx={{ color:'#fff'}} onClick={handleEditUnit}>
                          edit <Edit/>
                      </Button>
                  :null}
                  <Button variant="contained" sx={{bgcolor: "#e91e63",color:'#fff'}} onClick={()=>{
                    setSelectedUnit('');
                    setUnit({
                      unitCode: "",
                      unitName: "",
                      unitLocalName: ""})}
                  }>
                    cancel <Close/>
                  </Button>

                </ButtonGroup>
                <div style={{width:'211px'}}></div>
            </div>
          
          {units.length ? (
            <div style={{marginTop:'2rem'}}>
              <TableContainer sx={selectedUnitCategory?styles.table3:styles.table2} component={Paper}>
                <Table sx={{ width: '95%',margin:'0.5rem' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.map((row) => (
                      <TableRow
                        key={row.unitId}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.unitCode}
                        </TableCell>
                        <TableCell align="right">{row.unitName}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => {
                            setSelectedUnit(row.unitCode);
                            setUnit(units.find(x=>x.unitCode === row.unitCode))}}>
                            <Edit style={{ color: "#009688" }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label=""
                            onClick={() => handleRemoveUnit(row.unitCode)}
                          >
                            <Delete style={{ color: "#e53935" }}/>
                          </IconButton>
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            
            </div>
          ) : null}
        </Box>
        </Box>
        </Box>
      ) : null}
    </Fragment>
  )
}

export default DefineSubCategories
