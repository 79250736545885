import OrganizationChart from "@dabeng/react-orgchart";
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRequest } from '../../request';
import "./treeStyle.css";

const colorList=['#7FBCD2','#E94560','#42855B','#D36B00','#554994','#FEDB39','#876445','#76BA99','#ABC9FF','#2F8F9D']
const style = {
    select:{
        select: {
            '&:before': {
                borderColor: '#fff',
            },
            '&:after': {
                borderColor: '#fff',
            }
        },
        icon: {
            fill: '#fff',
        },
    }
}
function TreeView() {
    const history = useHistory();
    const [treeData, setTreeData] = useState([]);
    const [finalTree, setFinalTree] = useState({});
    const [selected, setSelected] = useState(0);
    let count =0;
    let tree=[];
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        if(treeData.length){
            tree = treeData[selected];
            changeData(tree);
        }
    }, [treeData,selected])
    
    const fetchData=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/TreeView`)
        if(data && Object.keys(data).length){
            setTreeData(data);
        }else{
            toast.error(error);
        }
    }
    const handleChange = (event) => {
        setSelected(event.target.value);
      };
    const changeData=(treeData)=>{
        if(treeData.productGroupLocalName || treeData.productLocalName){
            if(treeData.prods.length){
                treeData.children=[];
                treeData.name = treeData.productGroupLocalName;
                for(let i=0;i<treeData.prods.length;i++){
                    treeData.prods[i].name = treeData.prods[i].productLocalName;
                    treeData.prods[i].color=colorList[count];
                    count++
                }
                treeData.children[0]={
                    type:true,
                    // productGroupParentId:treeData.productGroupParentId,
                    products:[
                        treeData.prods
                    ]
                }
            }
            if(treeData.children.length){
                for(let i=0;i<treeData.children.length;i++){
                    treeData.name = treeData.productGroupLocalName;
                    changeData(treeData.children[i]);
                }
            }
        }
        setFinalTree(tree);
    };

    const handleClickNode = (e)=>{
        if(e.children?.length || e.prods?.length){
            return
        }else{
            history.push(`/shop/${e.products[0][0].productId}`)
        }
    }

    const renderNode=({ nodeData })=>{
        return (
          <div>
            {nodeData.type?(
                nodeData.products[0].length === 1?(
                    <div title='click to product'  style={{display:'flex',flexDirection:'row',flexWrap:'wrap',cursor:'pointer'}}>
                        {nodeData.products[0].map((x,index)=>
                            <div key={index} style={{border:'2px solid #e78f22',borderRadius:'0.7rem',width:'6rem'}}>
                                <p key={index} style={{
                                    width:'100%',
                                    height:'50%',
                                    backgroundColor:'#e78f22',
                                    color:'white',
                                    borderRadius:'0.7rem 0.7rem 0rem 0rem',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    padding:'0 0.5rem'
                                    }}>{x.productName}</p>
                                <p style={{height:'50%'}}></p>
                            </div>
                        )}
                    </div>
                ):(
                    nodeData.products[0].length === 2?(
                        <div title='click to product' style={{width:'12rem',display:'flex',flexDirection:'row',flexWrap:'wrap',cursor:'pointer'}}>
                        {nodeData.products[0].map((x,index)=>
                            <div style={{border:'2px solid #e78f22',borderRadius:'0.7rem',width:'6rem'}}>
                                <p key={index} style={{
                                    width:'100%',
                                    backgroundColor:'#e78f22',
                                    color:'white',
                                    borderRadius:'0.7rem 0.7rem 0rem 0rem',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    padding:'0 0.5rem'
                                    }}>{x.productName}</p>
                                {/* <p style={{height:'50%'}}></p> */}
                            </div>
                            )}
                        </div>
                    ):(
                        <div  title='click to product' style={{width:'18rem',display:'flex',flexDirection:'row',flexWrap:'wrap',cursor:'pointer'}}>
                        {nodeData.products[0].map(x=>
                            <div style={{border:'2px solid #e78f22',borderRadius:'0.7rem',width:'6rem'}}>
                                <p style={{
                                    width:'100%',
                                    height:'50%',
                                    backgroundColor:'#e78f22',
                                    color:'white',
                                    borderRadius:'0.7rem 0.7rem 0rem 0rem',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    padding:'0 0.5rem'
                                    }}>{x.productName}</p>
                                <p style={{height:'50%'}}></p>
                            </div>
                            )}
                        </div>
                    )
                )
            ):
            <div style={{border:'2px solid black',display:'flex',flexDirection:'column',borderRadius:'0.7rem',minWidth:'6rem',maxWidth:'10rem'}}>
                <p style={{
                    width:'100%',
                    // height:'50%',
                    backgroundColor:'#1e1e1e',
                    color:'white',
                    borderRadius:'0.7rem 0.7rem 0rem 0rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding:'0 0.5rem'
                    }}>{nodeData.name}</p>
                <p style={{/*height:'50%',*/color:'black',padding:'0rem 1rem'}}>number of children:{nodeData.prods?nodeData.prods.length?nodeData.prods.length:nodeData.children.length:null}</p>
            </div>
          }
          </div> 
        );
      }
  return (
    <div>
        {/* <ButtonGroup sx={{margin:'1rem'}}>
                {treeData.map((x,index)=>
                    <Button variant="contained" color={index===selected?'primary':'secondary' } onClick={()=>setSelected(index)}>
                        {x.productGroupName}
                    </Button>    
                )}
        </ButtonGroup> */}
        
      <div id="treeWrapper" >
        <Box sx={{width:'100%',display:'flex',justifyContent:'space-around',bgcolor:'#e78f22',padding:'1rem 0rem',borderRadius:'0.7rem 0.7rem 0rem  0rem'}}>
            <Typography variant="h4" sx={{color:'#1e1e1e',marginTop:'4rem'}} color="initial">Products Tree View</Typography>
            <FormControl sx={{ width: 250 ,margin:'1rem',marginTop:'4rem',position:'relative'}}>
                <InputLabel id="demo-simple-select-label" >Select group</InputLabel>
                <Select
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selected}
                label="Select Category"
                onChange={handleChange}
                >
                {
                    treeData.map((row,index)=>
                        <MenuItem key={index} value={index}>{row.productGroupName}</MenuItem>
                    )
                }
                </Select>
            </FormControl>
        </Box>
        <OrganizationChart
            datasource={finalTree}
            onClickNode={(e)=>handleClickNode(e)}
            chartClass="tree"
            NodeTemplate={renderNode}
            pan={true}
            zoom='10'
            containerClass='MainTree'
        />
       </div>
    </div>
  )
}

export default TreeView
