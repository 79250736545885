import React, { Suspense, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { homeSliderContent2Mobile, pronixProducts } from '../element/SliderContent';
import Footer from '../layout/footer5';
import { Header12 } from '../layout/header12';
//import HomeSlider from '../element/homeslider1'
import jwtDecode from 'jwt-decode';
import '../../css/plugins.css';
import '../../css/skin/skin-1.css';
import '../../css/style.css';
import '../../css/templete.min.css';
import { homeSliderMSK } from '../element/SliderContent';
import HomeSlider1 from '../element/homeslider1';
import ScrollToTop from '../element/scrollToTop';
// import ProjectSlider1 from '../element/projectSlider1';
// import MainProducts from '../layout/mainProducts';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from "react-responsive";
import { toast } from 'react-toastify';
import { getRequest } from '../../request';
import Loading from "../components/loading";
import PriceAtHome from '../element/priceAtHome';

// const bg = require('../../images/background/bg2.jpg' )
const bg2 = require('../../images/background/bg10.jpg')
// const bg4 = require('../../images/main-slider/slide8.jpg' )
// const bg5 = require('../../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg');
const style = {
    myTitle: {
        marginBottom: "5px",
        fontSize: '35px',
        lineHeight: '45px',
        fontWeight: '600px',
        cursor: 'pointer',
        '&:hover': {
            color: 'red'
        }
    }
}

function Home() {
    const history = useHistory();
    const theme = useTheme();
    const [products, setProducts] = useState([]);
    const [rootProducts, setRootProducts] = useState([]);
    const [magazineList, setMagazineList] = useState([]);
    const [categories, setcategories] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchAllProducts();
        // fetchRootProducts();
        fetchCategories();
        fetchPriceTable();
        // fetchMagazines();
        fetchBlogs();
    }, []);
    const HomeBusiness = React.lazy(() => import('./home-business-introduction'));
    const TopProducts = React.lazy(() => import('../element/topProducts'));
    const CounterSection = React.lazy(() => import('../element/counterSection'));
    const Team = React.lazy(() => import('../element/team1'));
    const BlogSlider = React.lazy(() => import('../element/blogSlider1'));

    const isDesktop = useMediaQuery({
        query: "(min-width: 1224px)"
    });

    const isTablet = useMediaQuery({
        query: "((min-width:650px) and (max-width: 900px))"
    });

    const isMobile = useMediaQuery({
        query: "((min-width:390px) and (max-width: 650px))"
    });
    const isLittleMobile = useMediaQuery({
        query: "(max-width: 390px)"
    });

    const token = localStorage.getItem("token");
    let decode = {};
    if (token) {
        decode = jwtDecode(token);
    }
    const fetchAllProducts = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`)
        if (data && Object.keys(data).length) {
            setProducts(data);
        } else {
            toast.error(error);
        }
    }
    const fetchRootProducts = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Industry`)
        if (data && Object.keys(data).length) {
            data.map((x, index) => x.image = pronixProducts[index].image);
            setRootProducts(data);
        } else {
            toast.error(error);
        }
    }
    const fetchCategories = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory`)
        const x = []
        if (data && Object.keys(data).length) {
            for (let i = 0; i < data.length; i++) {
                x.push({
                    id: data[i].productCategoryId,
                    title: data[i].productCategoryName,
                    image: pronixProducts[i].image
                })
            }
            setcategories(x);
        } else {
            toast.error(error);
        }
    }

    const fetchMagazines = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Article`);
        if (data && Object.keys(data).length) {
            setMagazineList(data);
        } else {
            toast.error(error);
        }
    }
    const fetchBlogs = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost`);
        if (data && Object.keys(data).length) {
            const list = data;
            let d;
            for (let i = 0; i < list.length; i++) {
                d = new Date(list[i].updateDateTime);
                list[i].date = d.toString();
            }
            setBlogList(list.reverse());
        } else {
            toast.error(error);
        }
    }
    const fetchPriceTable = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/prices`);
        if (data && Object.keys(data).length) {
            for (let i = 0; i < data.length; i++) {
                if (data[i][0].type) {
                    for (let j = 0; j < data[i].length; j++) {
                        data[i][j].customerPrice = data[i][j].customerPrice?.toLocaleString();
                    }
                }
            }
            setPriceData(data.filter(x => x[0].type));
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } else {
            toast.error(error);
        }
    }
    return (
        <div className="skin-1">
            <Header12 />
            <HomeSlider1
                data={homeSliderMSK}
                mobileData={homeSliderContent2Mobile}
                contentWrapperCls="sliderStyle2"
                btnText="Get a Quote"
                hideBtn={false}
            />
            <div className="page-content mskBackground">
                <div >
                    <Suspense fallback={<div>
                        <Loading type="cylon" color="#fff" />
                    </div>}>
                        <TopProducts products={products} />
                    </Suspense>

                    {priceData.length ?
                        <Suspense fallback={<div></div>}>
                            <PriceAtHome rows={priceData} />
                        </Suspense> :
                        null
                    }
                    <div className="d-flex  about-home">
                        <div className='col-12 col-lg-6 w-100 d-flex flex-column' style={{
                            justifyContent: "center",
                            paddingInline: "100px",
                            alignItems: "center", color: "#fff"
                        }}>
                            <h1 className='pb-2 text-white' style={{
                                fontSize: "6vh",
                                borderBottom: "3px solid #fff", color: "#fff"
                            }} >About Us</h1>
                            <h3 className='text-white' style={{
                                fontSize: "3vh",
                            }}>MSK Trading is a pioneer company which is active in several Commercial fields such as:
                                <ul style={{
                                    fontSize: "2vh",
                                }} className='text-white'>
                                    <li>
                                        Oil
                                        Products
                                    </li>
                                    <li>
                                        Petrochemicals
                                    </li>
                                    <li>Oil and Gas Industry</li>
                                </ul>
                            </h3>
                            <button className={isDesktop ? 'aboutBtnStyle' : isTablet ? 'aboutBtnStyleTablet' : 'aboutBtnStyleMobile'} onClick={() => history.push('/aboutUs')}>About Us</button>

                        </div>

                        <div className='aboutStyle col-12 col-sm-6 d-none d-sm-block'></div>

                    </div>
                    <div style={{
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '18rem'
                    }}>

                        {/* <div className={isMobile ? 'aboutStyleMobile' : isLittleMobile ? 'aboutStyleLittleMobile' : 'aboutStyle'}>
                        </div> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '5rem 0rem' }}>
                        <div id='news' className="section-full content-inner wow fadeIn newsSection" data-wow-duration="2s" data-wow-delay="0.4s" style={
                            isTablet ?
                                {
                                    width: '90%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.13)',
                                    backdropFilter: 'blur(12px)',
                                    borderRadius: '4rem',
                                    paddingBottom: '4rem',
                                    height: '100%'
                                } :
                                {
                                    width: '80%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.13)',
                                    borderRadius: '4rem',
                                    paddingBottom: '4rem',
                                    backdropFilter: 'blur(12px)'
                                }} >
                            <div className="container" style={isTablet ? { marginTop: '-7rem' } : null}>
                                <div className="section-head text-center">
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h2
                                            className="myTitle"
                                            style={{ color: '#fff', borderBottom: '4px solid #fff' }}
                                            onMouseEnter={(e) => {
                                                e.target.style.color = '#000';
                                                e.target.style.borderBottom = '4px solid #000';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.color = '#fff';
                                                e.target.style.borderBottom = '4px solid #fff';
                                            }}
                                            onClick={() => history.push('/blogList')}
                                        >Latest News</h2>
                                    </div>
                                    {decode.role === 'SuperAdmin' ? <Link className="site-button btnhover14" to={'/blog/0'}>start new blog</Link> : null}
                                </div>
                                <div style={{ marginTop: '5rem' }}>
                                    <Suspense fallback={<div></div>}>
                                        <BlogSlider blogList={blogList} />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer hideContactInfo displayNewsLetter />
            </div>
            <ScrollToTop className="icon-up" />
        </div>
    )
}

export default Home;

