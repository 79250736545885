import React from 'react';
// import { Link } from 'react-router-dom';

//import Slider from "react-animated-slider";
//import "react-animated-slider/build/horizontal.css";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Parallax,  Navigation} from 'swiper';
import "../../css/slider-animations.css";
import "swiper/css";
import "../../css/homeslider.css";
import { useMediaQuery } from "react-responsive"; 

SwiperCore.use([Parallax, Autoplay,  Navigation]);


  
  

function HomeSlider1(props){
	const navigationNextRef = React.createRef(null);
	const navigationPrevRef = React.createRef(null);
	const sliderContent = props.data;
	const mobileSliderContent = props.mobileData;
    const {btnText,hideBtn,show2Btn,btn2Text,contentWrapperCls} = props
    const contentCls = contentWrapperCls || ""
    const btnCls = props.btnCls || ""
	
	const isDesktop = useMediaQuery({
		query: "(min-width: 1224px)"
	  });
	  const isTablet = useMediaQuery({
		  query: "((min-width:650px) and (max-width: 900px))"
	  });
  
	  const isMobile = useMediaQuery({
		query: "(max-width: 440px)"
	  });
	
	function handleClickSlider (item){
		window.open(
			"https://wa.me/97566879137"
		)
	}
	
        
        
        
        return (
            <>
				<Swiper className="home-slider2"	
					speed= {1500}
					parallax= {true}
					loop={true}
					
					// autoplay = {{
					// 	delay: 3000,
					// }}					
					onSwiper={(swiper) => {
						// Delay execution for the refs to be defined
						setTimeout(() => {
						  // Override prevEl & nextEl now that refs are defined
						  swiper.params.navigation.prevEl = navigationPrevRef.current
						  swiper.params.navigation.nextEl = navigationNextRef.current

						  // Re-init navigation
						  swiper.navigation.destroy()
						  swiper.navigation.init()
						  swiper.navigation.update()
						})
					}}
					 
					modules={[Autoplay, Navigation]}
				>	

					{isMobile? 
					mobileSliderContent.map((item, index) => (						
						<SwiperSlide key={index} className="slider-content" style={{ background: `url('${item.image}') no-repeat center center` }}>
							<div className={`inner ${contentCls}`}>
								{item.title && <h1 data-swiper-parallax="-500">{item.title}</h1> }
								{item.description && <p data-swiper-parallax="-1000">{item.description}</p>}
								{ hideBtn ? "" (
									null
									) : (
										item.image.includes('BITUMEN')?
										<button data-swiper-parallax="-1500"  onClick={()=>handleClickSlider(item)} 
										className='btnSliderBitumen'>bitumen</button>:
										item.image.includes('SULPHUR')?
										<button className='btnSliderSulfur' data-swiper-parallax="-1500" onClick={()=>handleClickSlider(item)} 
										>sulphur</button>:null
									)
								}
							</div>
						</SwiperSlide>	
					))
					:sliderContent.map((item, index) => (						
						<SwiperSlide key={index} className="slider-content" style={{ background: `url('${item.image}') no-repeat center center` }}>
							<div className={`inner ${contentCls}`}>
								{item.title && <h1 data-swiper-parallax="-500">{item.title}</h1> }
								{item.description && <p data-swiper-parallax="-1000">{item.description}</p>}
								{ hideBtn ? "" (
									null
									) : (
										item.image.includes('BITUMEN')?
										<button data-swiper-parallax="-1500"  onClick={()=>handleClickSlider(item)} 
										className='btnSliderBitumen'>bitumen</button>:
										item.image.includes('SULPHUR')?
										<button className='btnSliderSulfur' data-swiper-parallax="-1500" onClick={()=>handleClickSlider(item)} 
										>sulphur</button>:null
									)
								}
							</div>
						</SwiperSlide>	
					))}	
					<div className="swiper-button" style={{zIndex : 1}}>
						<div className="swiper-button-prev previousButton" ref={navigationPrevRef}><i className="las la-angle-left"></i></div>
						<div className="swiper-button-next nextButton" ref={navigationNextRef}><i className="las la-angle-right"></i></div>
					</div>	
				</Swiper>

            </>
        )
    
}
export default HomeSlider1;