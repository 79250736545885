import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

function ProfileSideBar({ toggleDrawer }) {
  const history = useHistory();
  const handleClickItems = (text) => {
    switch (text) {
      case "Industry":
        history.push("/industries/0");
        break;
      case "Spec Types":
        history.push("/spec/0");
        break;
      case "Agent":
        history.push("/agentList");
        break;
      case "Product Category":
        history.push("/productCategories/0");
        break;
      case "Producer":
        history.push("/producerList");
        break;
      case "All Orders":
        history.push("/orders");
        break;
      case "Users Profile":
        history.push("/profiles");
        break;
      case "UnitType":
        history.push("/unitType");
        break;
      case "Locations":
        history.push("/locations");
        break;
      case "ProductGroups":
        history.push("/productGroups");
        break;
      case "ProducerGroups":
        history.push("/producerGroups");
        break;
      default:
        break;
    }
  };
  return (
    <Box
      sx={{ width: "15rem" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          "Industry",
          "Spec Types",
          "Agent",
          "UnitType",
          "Locations",
          "Product Category",
          "Producer",
          "All Orders",
          "Users Profile",
          "ProductGroups",
          "ProducerGroups",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => handleClickItems(text)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ProfileSideBar;
