import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import {
  Divider,
  IconButton,
  Typography,
  Button,
  ButtonGroup,
} from "@mui/material";
import {
  Add,
  CloudDownload,
  CloudUpload,
  Delete,
} from "@mui/icons-material";
import { toast } from "react-toastify";
// import PostMagazine from "../components/postMagazine";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import DeleteModal from "../components/deleteModal";
import { getRequest, postRequest } from "../../request";

const bg = require("../../images/banner/bnr3.jpg");
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "1rem 10rem",
  },
  dataInsertion: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr",
    justifyContent: "space-between",
  },
  imageProps: {
    width: 211,
    height: 211,
    boxShadow: "0px 0px 4px 2px #ddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "1rem",
  },
  magazineContainer:{
    width:'70vw',
    display:'flex',
    justifyContent:'space-around',
    alignItems:'center',
  },
  magazineText:{
    width:'85%',
    display:'flex',
    height:'40rem',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems:'center'
  }
};

function MagazineSingle() {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem("token");
  const magazineCreateDate = new Date();
  //   const [postId, setPostId] = useState(0);
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const [magazine, setMagazine] = useState({
    title: "",
    picture: "",
    description: "",
    fileLink:"",
    userId:decode.uid ? decode.uid : "",
    serverDateTime:magazineCreateDate.toISOString(),
  });
  const params = useParams();
  let magazineId;
  if (params.magazineId[0] === ":") {
    magazineId = params.magazineId.split(":")[1];
  } else {
    magazineId = params.magazineId;
  }

  const handleChangeFile = async (e) => {
    if (magazine.picture === "") {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Article/cover`,formData);
      if (message === 'ok') {
        // upload image
        toast.success('magazine cover added successfully!');
        const imgUrl =
          data.dbPath;
        setMagazine({ ...magazine, picture: imgUrl });
      }
    } else {
      toast.error("you have already uploaded image!");
    }
    /////////////////////////////////////////////////
  };
  const handleUploadPDF =async(e)=>{
    const formData = new FormData();
    const fileType =e.target.files[0].name.split('.');
    if(fileType[fileType.length-1].toLowerCase()==='pdf'){
      formData.append("file", e.target.files[0]);
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Article/file`,formData);
      if (message === 'ok') {
        // upload image
        const imgUrl = data.dbPath;
        toast.success('PDF file uploaded successfully!')
        setMagazine({ ...magazine, fileLink: imgUrl });
      }
    }
  }
  const handleAddMagazine = async () => {
    const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Article`,magazine);
    if(message==='ok'){
      toast.success('Magazine Added Successfully!');
      history.push('/');
    }else{
      toast.success('something went wrong');
    }
  };
  const fetchMagazine = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Article/${magazineId}`);
    if(data && Object.keys(data).length){
      setMagazine({
        title: data.title,
        picture: data.picture,
        description: data.description,
        fileLink:data.fileLink,
        userId:data.user,
        serverDateTime:data.serverDateTime
      });
    }
  };
  // const deleteMagazine = async () => {
  //   const { status } = await axios.get(
  //     `${process.env.REACT_APP_BASE_URL}/MagazinePost/delete/${magazineId}`
  //   );
  //   if (status === 200) {
  //     history.push("/");
  //   }
  // };
  
  
  useEffect(() => {
    if (magazineId !== "0") {
      fetchMagazine();
    }
  }, []);

  return (
    <>
      <DeleteModal
        openDeleteModal={openModal}
        setOpenDeleteModal={setOpenModal}
        parameterName="this magazine"
        deleteURL={`${process.env.REACT_APP_BASE_URL}/Article/delete/${magazineId}`}
        destination={"/"}
      />
      <Header12/>
      <div className="page-content bg-white">
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Magazine</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Magazine</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {magazineId !== '0'?(
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', height:'82vh',}}>
            <div style={styles.magazineContainer}>
              <img src={`${process.env.REACT_APP_IMG_URL}`+magazine.picture} 
              style={{
                height:'40rem',
                width:'35rem',
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px'
                }} alt="" 
              />
                <Box sx={{width:'40rem',display:'flex',justifyContent:'center'}}>
                  <Box sx={styles.magazineText}>
                  <div className="dlab-post-meta">
                    <ul>
                      
                        {/* <li className="post-date">
                          {" "}
                          <strong>
                            {blogDate[2]} {blogDate[1]}
                          </strong>{" "}
                          <span> {blogDate[3]}</span>{" "}
                        </li> */}
                      
                        <li className="post-author" style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
                        <div>By</div> 
                          {" "}
                           <Link>{magazine.userId}</Link>{" "}
                        </li>
                      
                    </ul>
                  </div>
                  <Typography variant="h4">{magazine.title}</Typography>
                  <Typography variant="body1" sx={{textAlign: 'justify', textAlignLast: 'center'}}>{magazine.description}</Typography>
                  <ButtonGroup variant="contained" >
                    <Button  color="primary" sx={{color:'#fff'}} onClick={()=>window.open(`${process.env.REACT_APP_IMG_URL}`+magazine.fileLink)}>
                      download magazine <CloudDownload/>
                    </Button>
                    <Button variant="contained" color="danger" sx={{color:'#fff'}} onClick={()=>setOpenModal(true)}>
                      delete magazine <Delete/>
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </div>
          </div>
        ):(
          <Box sx={styles.dataInsertion}>
          {decode.role === "SuperAdmin" ? (
              <Box sx={{display:'flex',flexDirection:'row'}}>
                <div style={{ margin: "2rem", display:'flex', flexDirection:'column',width:'100%' }}>
                  <Typography variant="h5">Add New Magazine</Typography>                  
                  <TextField
                    label="Title"
                    sx={{ width: "80%",marginBottom:'1rem',textAlign:'justify'}}
                    value={magazine.title}
                    onChange={(e) =>
                      setMagazine({ ...magazine, title: e.target.value })
                    }
                  />
                  <TextField
                    label="description"
                    sx={{ width: "80%",marginBottom:'1rem' }}
                    multiline
                    maxRows={6}
                    minRows={3}
                    value={magazine.description}
                    onChange={(e) =>
                      setMagazine({ ...magazine, description: e.target.value.trim() })
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ color: "#fff",width:'80%',marginBottom:'1rem' }}
                    onClick={handleAddMagazine}
                    disabled={
                      !magazine.title|| 
                      !magazine.picture||
                      !magazine.fileLink
                    }
                  >
                    Add Magazine <Add />
                  </Button>
                </div>
                <Divider orientation="vertical"/>
              </Box>
              ) :null}
              
              
              <Box sx={{display: "flex", width: "100%"}}>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                      }}
                    >
                      <Box sx={styles.imageProps}>
                        <IconButton color="primary" component="label">
                          <input
                            type="file"
                            onChange={(e) => handleChangeFile(e)}
                            style={{ display: "none" }}
                          />
                          <CloudUpload sx={{ fontSize: "4rem" }} />
                        </IconButton>
                        Upload Magazine Cover
                      </Box>
                      
                    </Box>
                    <Box sx={{ width: "60%" }}>
                      {magazine.picture ? (
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}`+magazine.picture}
                          alt=""
                          style={{
                            width: "5rem",
                            height: "4rem",
                            margin: "1rem",
                            borderRadius: "3px",
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Divider orientation="vertical"/>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                }}
              >
                <Box sx={styles.imageProps}>
                  <IconButton color="primary" component="label">
                    <input
                      type="file"
                      onChange={(e) => handleUploadPDF(e)}
                      style={{ display: "none" }}
                    />
                    <CloudUpload sx={{ fontSize: "4rem" }} />
                  </IconButton>
                  Upload Magazine PDF
                </Box>
                <Typography variant="body1">{magazine.fileLink}</Typography>
              </Box>
        </Box>
        )}
      </div>
      <Footer />
    </>
  );
}
export default MagazineSingle;
