import { Add, Check, Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../request";
import DefineSubCategories from "../components/defineSubCategories";
const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "30rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto ",
    justifyContent: "space-around",
  },
  types: {
    backgroundColor: "#ccc",
    borderRadius: "0.5rem 0.5rem 0rem 0rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};
const bg = require("./../../images/banner/bnr5.jpg");

function Measures() {
  const [unitCategory, setUnitCategory] = useState({
    unitCategoryCode: "",
    unitCategoryName: "",
    unitCategoryLocalName: "",
  });
  const [unitCategoryList, setUnitCategoryList] = useState([]);
  const [selectedUnitCategory, setSelectedUnitCategory] = useState("");
  const [unitSubCategoryList, setUnitSubCategoryList] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory`)
      if(data && Object.keys(data).length){
        setUnitCategoryList(data);
      }else{
        toast.error(error);
      }
  };
  const handleChangeProducer = (event) => {
    setUnitCategory({
      ...unitCategory,
      [event.target.name]: event.target.value,
    });
  };
  const handleRemoveSpec=(code)=>{
    setUnitCategoryList(prevstate=>[...prevstate.filter(x=>x.unitCategoryCode !== code)])
  }
  const handleEditCategory = async (id) => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/UnitCategory/subCategories/${id}`)
      if(data && Object.keys(data).length){
        setUnitSubCategoryList(data);
        setSelectedUnitCategory(id);
      }else{
        toast.error(error);
      }
  };
  return (
    <div>
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
          <div className="container">
              <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Measures</h1>
                  <div className="breadcrumb-row">
                      <ul className="list-inline">
                          <li><Link to="/">Home</Link></li>
                          <li>Measures</li>
                      </ul>
                  </div>
              </div>
          </div>
        </div>
      <Box
        component="form"
        sx={selectedUnitCategory ? styles.containerBox3 : styles.containerBox2}
      >
        <Box>
          <Box sx={styles.types}>
            <Typography variant="h5" color="initial">
              <span>Category</span>
            </Typography>
          </Box>
          <Box sx={selectedUnitCategory ? styles.box3 : styles.box2}>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                name="unitCategoryCode"
                value={unitCategory.categoryCode}
                onChange={handleChangeProducer}
              />
              <TextField
                variant="outlined"
                label="Name"
                name="unitCategoryName"
                value={unitCategory.categoryName}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                name="unitCategoryLocalName"
                value={unitCategory.categoryLocalName}
                onChange={handleChangeProducer}
              />
              <div style={{ width: "211px" }}></div>
            </div>
            <div style={styles.innerBox}>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "white" }}
                onClick={() =>
                  setUnitCategoryList((prevstate) => [
                    ...prevstate,
                    unitCategory,
                  ])
                }
              >
                Add
                <Add />
              </Button>
              <div style={{ width: "211px" }}></div>
            </div>
            <Box sx={{ width: "100%", padding: "0rem 3rem" }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ width: "95%", margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {unitCategoryList.map((row) => (
                      <TableRow
                        key={row.code}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.unitCategoryCode}
                        </TableCell>
                        <TableCell align="right">
                          {row.unitCategoryName}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              handleEditCategory(row.unitCategoryId)
                            }
                          >
                            <Edit style={{ color: "#009688" }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label=""
                            onClick={() => handleRemoveSpec(row.unitCategoryCode)}
                          >
                            <Delete style={{ color: "#e53935" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <div style={styles.innerBox}>
              <Box></Box>
              <ButtonGroup variant="contained">
                <Button
                  color="primary"
                  sx={{ color: "#efefef" }}
                  onClick={() => console.log(unitCategory)}
                >
                  confirm
                  <Check />
                </Button>
                <Button color="danger" sx={{ color: "#efefef" }}>
                  cancel
                  <Close />
                </Button>
              </ButtonGroup>
            </div>
          </Box>
        </Box>
        <DefineSubCategories
          unitSubCategoryList={unitSubCategoryList}
          setUnitSubCategoryList={setUnitSubCategoryList}
          selectedUnitCategory={selectedUnitCategory}
        />
      </Box>
    </div>
  );
}

export default Measures;
