import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { AddShoppingCart, Close, Download, Edit } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Fade, IconButton, Modal, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import { getRequest } from '../../request';
import Loading from "../components/loading";
import ProductSubCatSlider from '../components/productSubCatSlider';
import ProductGallerySlider from '../element/productGallerySlider';
import Footer from '../layout/footer5';
import { Header12 } from '../layout/header12';


// import 'react-tabs/style/react-tabs.css';

const bg = require("./../../images/banner/bnr5.jpg");
const style = {
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 4,
        bgcolor: 'background.paper',
    },
    // imgModal:{
    //     display:'flex',
    //     justifyContent:'center',
    //     alignItems:'center'
    // }
}
function ShowProduct() {
    const history = useHistory();
    const theme = useTheme();
    const params = useParams();
    const [imageList, setimageList] = useState([]);
    const [product, setProduct] = useState({});
    const [applicationId, setApplicationId] = useState(0);
    const [specs, setSpecs] = useState([]);
    const [valueData, setValueData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productProducers, setProductProducers] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');
    const token = localStorage.getItem("token");
    let decode = {};
    if (token) {
        decode = jwtDecode(token);
    }

    const fetchData = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/${params.productId}`)
        if (data && Object.keys(data).length) {
            setProduct(data);
            setTimeout(() => {
                setIsLoading(true);
                getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/products/${data.productSubCategoryId}`).then(relatedRes => {
                    if (relatedRes.data && Object.keys(relatedRes.data).length) {
                        setTimeout(() => {
                            if (relatedRes.data.length > 10) {
                                setRelatedProducts(relatedRes.data.slice(0, 10));
                            } else {
                                setRelatedProducts(relatedRes.data);
                            }
                        }, 4000);
                    } else {
                        toast.error(relatedRes.error);
                    }
                })
            }, 1500);
        } else {
            toast.error(error);
        }
    };
    const fetchSpecs = async () => {
        const unitList = [];
        const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/specs/${params.productId}`)
        if (response.data && Object.keys(response.data).length) {
            setSpecs(response.data);
            setApplicationId(response.data.filter(x => x.productSpecName === 'application' || x.productSpecName === 'applications')[0]?.productSpecId);
            const res = [];
            for (let i = 0; i < response.data.length; i++) {
                res[i] = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${response.data[i].productSpecId}`)
                res[i].data.map((x) => {
                    unitList.push(x);
                });
            }
        } else {
            toast.error(response.error);
        }
        setValueData(unitList);
        setimageList(unitList.filter((x) => x.unit.unitName === "image"));
    };
    const fetchProductsProducers = async () => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/producers/${params.productId}`)
        if (data && Object.keys(data).length) {
            setProductProducers(data);
        } else {
            toast.error(error);
        }

    };
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleDownload = () => {
        axios({
            url: selectedImg,
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg');
            document.body.appendChild(link);
            link.click();
        })
    }

    useEffect(() => {
        fetchData();
        fetchSpecs();
        fetchProductsProducers();
    }, []);


    const StyledTableHead = styled(TableHead)({
        '& .MuiTableCell-root': {
            borderRadius: "0 !important",
            backgroundRepeat: 'no-repeat'
        },
    });


    return (
        <div style={{
            backgroundColor: "#000"
        }}>
            <Header12 />
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundColor: "#000", color: "#fff" }}>
            </div>
            <div className="page-content">
                {isLoading ?
                    <div className="section-full content-inner" style={{ paddingTop: '5rem' }}>
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-md-5 col-lg-5 col-sm-12 m-b30">
                                    <ProductGallerySlider imageList={imageList} setSelectedImg={setSelectedImg} setOpenModal={setOpenModal} />
                                </div>
                                <div className="col-md-7 col-lg-7 col-sm-12" style={{ zIndex: 0 }}>
                                    <form method="post" className="cart sticky-top">
                                        <div className="dlab-post-title">
                                            <h4 className="post-title text-white">
                                                {product.productName}</h4>
                                            <p className="m-b10 text-white" style={{ textAlign: 'justify' }}>
                                                {product.productDescription}
                                            </p>
                                            <div className="dlab-divider bg-gray tb15">
                                                <i className="icon-dot c-square"></i>
                                            </div>
                                        </div>
                                        <div className="relative">
                                            {valueData.find((x) => x.unit.unitName === "price")
                                                ?
                                                <div>
                                                    <h3 className="m-tb10"> {valueData.find((x) => x.unit.unitName === "price")
                                                        .productSpecValueAmount + " $"}</h3>
                                                    <div className="dlab-divider bg-gray tb15">
                                                        <i className="icon-dot c-square"></i>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>

                                        <ButtonGroup>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    history.push(`/registerOrder/:${product.productId}`)
                                                }
                                            >
                                                inquiry
                                                <AddShoppingCart />
                                            </Button>
                                            {decode.role === "SuperAdmin" ? (
                                                <Button
                                                    variant="contained"
                                                    color='edit'
                                                    sx={{ color: '#fff' }}
                                                    onClick={() =>
                                                        history.push(`/productProperties/:${product.productId}`)
                                                    }
                                                >
                                                    Edit
                                                    <Edit />
                                                </Button>
                                            ) : null}
                                        </ButtonGroup>
                                    </form>
                                </div>
                            </div>
                            <div className="row m-b30">
                                <div className="col-lg-12">
                                    <div className="dlab-tabs product-description tabs-site-button" >
                                        <Tabs style={{
                                            backgroundColor: '#fff',
                                            // backdropFilter: 'blur(12px)',
                                            borderRadius: '0.3rem',
                                        }}>
                                            <TabList className="nav nav-tabs m-0">
                                                <Tab selectedClassName="tab-active">
                                                    <Link style={{ width: '20rem' }} >
                                                        <i className="fa fa-cog"></i> Product Details
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link style={{ width: '20rem' }}>
                                                        <i className="fa fa-globe"></i> Product Producers
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link style={{ width: '20rem' }}>
                                                        <i className="fa fa-photo"></i> Product Applications
                                                    </Link>
                                                </Tab>
                                            </TabList>


                                            <TabPanel className="tab-pane p-0">
                                                {productProducers.length ?
                                                    <TableContainer

                                                        component={Paper}
                                                    >
                                                        <Table aria-label="simple table">
                                                            <StyledTableHead >
                                                                <TableRow>
                                                                    <TableCell sx={{ color: '#fff' }}>Producer Code</TableCell>
                                                                    <TableCell sx={{ color: '#fff' }}>Producer Name</TableCell>
                                                                    <TableCell sx={{ color: '#fff' }}>producer Address</TableCell>
                                                                </TableRow>
                                                            </StyledTableHead>
                                                            {productProducers.map((producer, index) =>
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th": {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {producer.producerCode}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {producer.producerName}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {producer.addressLine1}
                                                                    </TableCell>
                                                                </TableRow>)}
                                                        </Table>
                                                    </TableContainer>
                                                    : null}
                                            </TabPanel>
                                            <TabPanel className="tab-pane ">
                                                <ul className="list-check primary">
                                                    {valueData.filter(x => x.productSpecId === applicationId).map((spec, index) =>
                                                        <li key={index}>{spec.productSpecValueAmount}</li>
                                                    )}
                                                </ul>

                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {relatedProducts.length ? <h5 className="m-b20 text-white">Related Products</h5> : null}
                                    <ProductSubCatSlider products={relatedProducts} />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <Box
                        sx={{
                            height: "100vh",
                            width: "100vw",
                            bgcolor: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Loading type="cylon" color="#000" />
                    </Box>
                }


            </div>
            <Footer hideContactInfo />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
            >
                <Fade in={openModal}>
                    <Box sx={style.modalStyle}>
                        <IconButton
                            color='default'
                            onClick={() => setOpenModal(false)}
                            sx={{ position: "absolute", }}>
                            <Close sx={{ color: '#c5c5c5', fontSize: '3rem' }} />
                        </IconButton>
                        {/* <Box sx={style.imgModal}> */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                borderRadius: '50%',
                                backgroundColor: 'rgb(205 205 205 / 61%)',
                                padding: '1rem',
                                cursor: 'pointer'
                            }}
                            href={selectedImg}
                            onClick={handleDownload}
                        >
                            <Tooltip title="download image" placement="top">
                                <Download sx={{ color: '#545454', fontSize: '3rem' }} />
                            </Tooltip>
                        </div>
                        <img
                            src={selectedImg}
                            alt=''
                            style={{ width: '100%' }} />
                        {/* </Box> */}
                    </Box>
                </Fade>
            </Modal>
        </div>
    )

}
export default ShowProduct;