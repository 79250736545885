import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
    IconButton
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRequest } from '../../request';



function PriceAtHome({ rows }) {
    const history = useHistory();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(0);
    useEffect(() => {
        setCategoryList(rows.map((x, index) => ({
            id: index,
            type: x[0].type
        })))
    }, []);

    const isDesktop = useMediaQuery({
        query: "(min-width: 1224px)"
    });
    const isTablet = useMediaQuery({
        query: "((min-width:650px) and (max-width: 900px))"
    });

    const isMobile = useMediaQuery({
        query: "((min-width:390px) and (max-width: 650px))"
    });
    const isLittleMobile = useMediaQuery({
        query: "(max-width: 390px)"
    });
    const handleChangeForwardPage = () => {
        setPage(page + 1);
        console.log(rows[selectedCategory])
    };
    const handleChangePrevPage = () => {
        setPage(page - 1);
        console.log(rows[selectedCategory])
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const styles = {
        paper: { width: "100%", backgroundColor: 'transparent', boxShadow: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '6rem' },
        tableContainerDesktop: { width: '75rem', borderRadius: '1.5rem 1.5rem 0rem 0rem' },
        headerDesktop: { borderTop: 'none', borderRadius: '1.5rem 1.5rem 0rem 0rem', marginBottom: '0px', width: '75rem', height: '10rem', border: '1px solid #fff', borderBottom: 'none' },
        tableContainerTablet: { width: '40rem', borderRadius: '1.5rem 1.5rem 0rem 0rem' },
        headerTablet: { borderTop: 'none', borderRadius: '1.5rem 1.5rem 0rem 0rem', marginBottom: '0px', width: '40rem' },
        tableContainerPhone: { width: '100%', borderRadius: '1.5rem 1.5rem 0rem 0rem' },
        headerPhone: { borderTop: 'none', borderRadius: '1.5rem 1.5rem 0rem 0rem', marginBottom: '0px', width: '100%' },
        tableHeaderTitle: { width: 'fit-content', bgcolor: '#7fa5e2', padding: '0.7rem 6rem', borderRadius: '1.5rem 1.5rem 0rem 0rem' },
        tableHeader: { display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' },
        tableBody: { maxHeight: '440px', overflowY: "scroll" },
        tableBodyPhone: { maxHeight: '440px', overflowY: "scroll", width: '24rem' },
        footerDesktop: { borderTop: 'none', width: '75rem', bgcolor: 'rgba(0, 50, 130, 0.26)', color: '#fff', border: '1px solid #fff' },
        footerTablet: { borderTop: 'none', width: '40rem', bgcolor: 'rgba(0, 50, 130, 0.26)', color: '#fff', border: '1px solid #fff' },
        footerPhone: { borderTop: 'none', width: '100%', bgcolor: 'rgba(0, 50, 130, 0.26)', color: '#fff', border: '1px solid #fff' },
        myTitle: {
            marginBottom: "5px", fontSize: '35px', lineHeight: '45px', fontWeight: '600px', cursor: 'pointer',
            '&:hover': {
                color: 'red'
            }
        }
    }
    const routeToProductPage = async (productDetail) => {
        const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/ProductByName/${productDetail}`)
        if (data && Object.keys(data).length) {
            history.push(`/singleProduct/${data.productId}`)
        } else {
            toast.error('data not found');
        }
    }
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };
    const handleRightBtn = () => {
        setPage(0);
        const category = categoryList.findIndex(x => x.type === rows[selectedCategory][0]?.type);
        if (category >= categoryList.length - 1) {
            setSelectedCategory(0);
        } else {
            setSelectedCategory(category + 1)
        }
    }
    const handleLeftBtn = () => {
        setPage(0);
        const category = categoryList.findIndex(x => x.type === rows[selectedCategory][0]?.type);
        if (category === 0) {
            setSelectedCategory(categoryList.length - 1);
        } else {
            setSelectedCategory(category - 1);
        }
    }
    return (

        <div >
            <Box sx={isTablet ? { display: 'flex', justifyContent: 'center', margin: '5rem 0rem' } : { display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                    width: '95%',
                    backgroundColor: 'rgba(255, 255, 255, 0.13)',
                    borderRadius: '4rem',
                    paddingBottom: '4rem',
                    backdropFilter: 'blur(12px)'

                }}>
                    <div className="section-head text-center ">
                        {/* <p 
                    // style={styles.myTitle}
                        onClick={()=>history.push('/prices')}
                        onMouseEnter={(e) => {
                            e.target.style.color = theme.palette.primary.main;
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.color = '#000';
                        }}
                    >Price Tables</p> */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h2 style={{ marginTop: '5rem', color: '#fff', borderBottom: '4px solid #fff', width: 'fit-content', textAlign: 'center', cursor: 'pointer' }}
                                onClick={() => history.push('/prices')}
                                onMouseEnter={(e) => {
                                    e.target.style.color = '#000';
                                    e.target.style.borderBottom = '4px solid #000';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.color = '#fff';
                                    e.target.style.borderBottom = '4px solid #fff';
                                }}>PRICE TABLES</h2>
                        </div>
                        <p style={{ color: '#fff' }}>You Can Visit Our Price Data To Found Our Product Details & Updated Prices</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ margin: '1rem 0rem' }}>
                            <h4 style={{ margin: '1rem 0rem', color: '#efefef', textAlign: 'center' }}>Select Category</h4>
                            <div className='selectCategoryImgContainer'>
                                <img src={require("../../images/msk/categorySelector.png")} alt="" />
                                <div className='selectCategoryImgOverlay'>
                                    <div style={{ backgroundColor: '#0B82C6', marginRight: '0.2rem', cursor: 'pointer', borderRadius: '3px' }}
                                        onClick={handleLeftBtn}>
                                        <ChevronLeft />
                                    </div>
                                    {rows[selectedCategory][0]?.type}
                                    <div style={{ backgroundColor: '#0B82C6', marginLeft: '0.2rem', cursor: 'pointer', borderRadius: '3px' }}
                                        onClick={handleRightBtn}>
                                        <ChevronRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='table-container'>
                            <table style={{ width: '90%' }}>
                                <tr className='priceHeaderTR'>

                                    <th className='tableBodyTH'>
                                        Product</th>
                                    {isMobile || isLittleMobile ?
                                        null :
                                        <th className='tableBodyTH'>
                                            Type</th>
                                    }
                                    <th className='tableBodyTH'>
                                        Min Quantity</th>
                                    {isMobile || isLittleMobile || isTablet ? null :
                                        <th className='tableBodyTH'>
                                            Packing</th>
                                    }
                                    <th className='tableBodyTH'>
                                        Delivery Term</th>
                                    {isMobile || isLittleMobile ? null :
                                        <th className='tableBodyTH'>
                                            Market</th>
                                    }
                                    {
                                        isMobile || isLittleMobile ? null :
                                            <th className='tableBodyTH'>
                                                Price</th>
                                    }
                                </tr>
                                {rows[selectedCategory]?.slice(page * 5, (page + 1) * 5).map(row =>
                                    <tr key={row.id}
                                        className='PriceTableRow'>
                                        <td className='tableBodyTD'>{row.product}</td>
                                        {isMobile || isLittleMobile ? null :
                                            <td className='tableBodyTD'>{row.type ? row.grade : '-'}</td>
                                        }
                                        <td className='tableBodyTD'>{row.mInQuantity ? row.mInQuantity : '-'}</td>
                                        {isMobile || isLittleMobile || isTablet ? null :
                                            <td className='tableBodyTD'>{row.packing ? row.packing : '-'}</td>
                                        }
                                        <td className='tableBodyTD'>{row.deliveryTerm ? row.deliveryTerm : '-'}</td>
                                        {isMobile || isLittleMobile ? null :
                                            <td className='tableBodyTD'>{row.market}</td>
                                        }
                                        {isMobile || isLittleMobile ? null :
                                            <td className='tableBodyTD'>{row.offeredPrice === '0' || !row.offeredPrice ? <span style={{ fontSize: '18px' }}>-</span> : Math.round(row.offeredPrice)}</td>
                                        }
                                    </tr>
                                )}
                                <tr className='PriceTableFooter' style={{ backgroundColor: "rgb(40, 79, 193, 40%)" }}>
                                    <td className='tableBodyTD'></td>
                                    <td className='tableBodyTD'></td>
                                    {isDesktop ?
                                        <td className='tableBodyTD'></td>
                                        : null}
                                    {isMobile || isLittleMobile ?
                                        <td className='tableBodyTD'>
                                            {page <= 0 ? null :
                                                <IconButton aria-label=""
                                                    sx={{
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                        color: '#efefef'
                                                    }}
                                                    onClick={handleChangePrevPage}>
                                                    <ChevronLeft />
                                                </IconButton>
                                            }
                                            <span style={{ padding: '0px 0.5rem' }}>
                                                page:
                                                {
                                                    page + 1
                                                }
                                            </span>
                                            {
                                                ((page + 1) * 5) < rows[selectedCategory].length ?
                                                    <IconButton aria-label=""
                                                        sx={{
                                                            backgroundColor: 'rgba(255, 255, 255, 0.28)',
                                                            color: '#efefef'
                                                        }}
                                                        onClick={handleChangeForwardPage}>
                                                        <ChevronRight />
                                                    </IconButton> : null
                                            }
                                        </td>
                                        : null}
                                    {isMobile || isLittleMobile ? null :
                                        <td className='tableBodyTD'></td>
                                    }
                                    {isMobile || isLittleMobile ? null :
                                        <td className='tableBodyTD'></td>
                                    }
                                    {isMobile || isLittleMobile ? null :
                                        <td className='tableBodyTD'>
                                            {page <= 0 ? null :
                                                <IconButton aria-label=""
                                                    sx={{
                                                        backgroundColor: 'rgba(255, 255, 255, 0.28)',
                                                        color: '#efefef'
                                                    }}
                                                    onClick={handleChangePrevPage}>
                                                    <ChevronLeft />
                                                </IconButton>
                                            }
                                            <span style={{ padding: '0px 0.5rem' }}>
                                                page:
                                                {
                                                    page + 1
                                                }
                                            </span>
                                            {
                                                ((page + 1) * 5) < rows[selectedCategory].length ?
                                                    <IconButton aria-label=""
                                                        sx={{
                                                            backgroundColor: 'rgba(255, 255, 255, 0.28)',
                                                            color: '#efefef'
                                                        }}
                                                        onClick={handleChangeForwardPage}>
                                                        <ChevronRight />
                                                    </IconButton> : null
                                            }
                                        </td>
                                    }
                                    {isMobile || isLittleMobile ? null :
                                        <td className='tableBodyTD'></td>
                                    }
                                </tr>
                            </table>
                        </div>

                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default PriceAtHome