import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { Add, Close, Edit } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import ProducerAgents from "../components/producerAgents";
import ProducerProducts from "../components/producerProducts";
import ProducerIndustries from "../components/producerIndustries";
import { toast } from "react-toastify";
import {getRequest, postRequest} from '../../request';
import { Link, useHistory, useParams } from "react-router-dom";

const styles = {
  containerBox: {
    display: "grid",
    gridTemplateColumns: "3fr 1.5fr 1.5fr 1.5fr",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
    margin: "0rem 5rem",
  },
  box: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
  },
  box1: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "40rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-around",
  },
};
const bg = require("./../../images/banner/bnr5.jpg");
function Producer() {
  const params = useParams();
  const history = useHistory();
  const [cityList, setCityList] = useState([]);
  const [continentList, setContinentList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [producerdataChanged, setProducerdataChanged] = useState({
    continent:false,
    country:false,
    state:false
  })
  const token = localStorage.getItem("token");
  let decode;
  if (token) {
    decode = jwtDecode(token);
  }
  const code = (Math.random() * 1000000).toFixed(0);
  const [producerData, setProducerData] = useState({
    producerCode: `Producer-${code}`,
    producerName: "",
    producerRegisterNumber: "",
    mobileNumebr: "",
    tellNumber1: "",
    tellNumber2: "",
    siteURL: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    continentId: "",
    continent: "",
    countryId: "",
    country: "",
    addressCityId: "",
    city:"",
    stateId: "",
    state: "",
  });
  useEffect(() => {
    fetchContinent();
    fetchDetails();
    if(params.producerId!=='0'){
      fetchSelectedProducer()
    }
  }, []);
  useEffect(() => {
    if (producerData.continent && continentList.length) {
      const continentId = continentList.find(
        (x) => x.continentName == producerData.continent
        ).continentId;
      if(producerData.continent && producerdataChanged.continent){
        setProducerData({
          ...producerData,
          continentId: continentId,
          country: "",
          state: "",
          addressCityId: "",
          city:""
        });
      }
      //   setProducerData({ ...producerData,country:"", state: "", city: "" });
      fetchCountry(continentId);
    } else {
      setCountryList([]);
    }
  }, [producerData.continent,continentList]);
  useEffect(() => {
    if (producerData.country && countryList.length ) {
      
      const countryId = countryList.find(
        (x) => x.countryName == producerData.country
      ).countryId;
      if(producerData.country && producerdataChanged.country){
        setProducerData({
          ...producerData,
          countryId: countryId,
          state: "",
          addressCityId: "",
          city:""
        });
      }
      fetchState(countryId);
    } else {
      setStateList([]);
    }
  }, [producerData.country,countryList]);
  useEffect(() => {
    
    if (producerData.state && stateList.length) { 
      const stateId = stateList.find(
        (x) => x.stateName == producerData.state
      ).stateId;
      if(producerData.state && producerdataChanged.state){
        setProducerData({ ...producerData, stateId: stateId, addressCityId: "",city:"" });
      }
      fetchCities(stateId);
    } else {
      setCityList([]);
    }
  }, [producerData.state,stateList]);
  useEffect(() => {
    if(producerData.city && cityList.length){
        const cityId = cityList.find(
            (x) => x.cityName == producerData.city
        ).cityId;
        setProducerData({...producerData,addressCityId:cityId.toString()})
    }
  }, [producerData.city])

  const fetchSelectedProducer= async()=>{
    const agentRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Producer/agents/${params.producerId}`)
      if(agentRes.data && Object.keys(agentRes.data).length){
        setSelectedAgents(agentRes.data.map(x=>({agentId:x.agentId, agentName:x.agentName})));
      }else{
        toast.error(agentRes.error);
      }
    
    const productRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Producer/products/${params.producerId}`)
    if(agentRes.data && Object.keys(agentRes.data).length){
      setSelectedProducts(productRes.data.map(x=>({productId:x.productId,productName:x.productName})));
    }else{
      toast.error(agentRes.error);
    }
    
    const industryRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/Producer/industries/${params.producerId}`)
    if(agentRes.data && Object.keys(agentRes.data).length){
      setSelectedIndustries(industryRes.data.map(x=>({industryId:x.industryId,industryName:x.industryName})));
        }else{
      toast.error(agentRes.error);
    }
    const {status,data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Producer/${params.producerId}`)
    if(agentRes.data && Object.keys(agentRes.data).length){
      setProducerData({...producerData,
        producerCode:data.producerCode,
        producerName:data.producerName,
        producerRegisterNumber:data.producerRegisterNumber,
        tellNumber1:data.tellNumber1,
        tellNumber2:data.tellNumber2,
        mobileNumebr:data.mobileNumebr,
        email:data.email,
        siteURL:data.siteURL,
        addressCityId:data.addressCityId,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        city:data.city.cityName,
        state:data.city.state.stateName,
        stateId:data.city.state.stateId,
        country:data.city.state.country.countryName,
        countryId:data.city.state.country.countryId,
        continent:data.city.state.country.continent.continentName,
        continentId:data.city.state.country.continent.continentId
      })
        }else{
      toast.error(agentRes.error);
    }
    
  }
  const fetchDetails = async () => {
    const agentsData = await getRequest(`${process.env.REACT_APP_BASE_URL}/Agent`)
      if(agentsData.data && Object.keys(agentsData.data).length){
        setAgentList(agentsData.data);
      }else{
        toast.error(agentsData.error);
      }
    const productData = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`)
      if(productData.data && Object.keys(productData.data).length){
        setProductsList(productData.data);
      }else{
        toast.error(productData.error);
      }
    const industryData = await getRequest(`${process.env.REACT_APP_BASE_URL}/Industry`)
      if(industryData.data && Object.keys(industryData.data).length){
        setIndustryList(industryData.data);
      }else{
        toast.error(industryData.error);
      }
  };
  const fetchCities = async (stateId) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/City`)
      if(data && Object.keys(data).length){
        setCityList(data.filter((x) => x.stateId == stateId));
      }else{
        toast.error(error);
      }
  };
  const fetchContinent = async () => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Continent`)
      if(data && Object.keys(data).length){
        setContinentList(data);
      }else{
        toast.error(error);
      }
  };
  const fetchCountry = async (continentId) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Country`)
      if(data && Object.keys(data).length){
        setCountryList(data.filter((x) => x.continentId == continentId));
      }else{
        toast.error(error);
      }
  };
  const fetchState = async (countryId) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Country/states/${countryId}`)
      if(data && Object.keys(data).length){
        setStateList(data.filter((x) => x.countryId == countryId));
      }else{
        toast.error(error);
      }
  };

  const handleChangeProducer = (event) => {
    setProducerData({
      ...producerData,
      [event.target.name]: event.target.value,
    });
    switch (event.target.name) {
      case 'continent':
        setProducerdataChanged({...producerdataChanged,continent:true})
        break;
      case 'country':
        setProducerdataChanged({...producerdataChanged,country:true})
        break;
      case 'state':
        setProducerdataChanged({...producerdataChanged,state:true})
        break;
      default:
        break;
    }
  };

  const handleAddProducer = async() => {
    const emailValidation = producerData.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const mobileValidation = producerData.mobileNumebr.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    if(emailValidation && mobileValidation && emailValidation.length && mobileValidation.length){
        const tempData ={
            producerCode:producerData.producerCode,
            producerName:producerData.producerName,
            producerRegisterNumber:producerData.producerRegisterNumber,
            tellNumber1:producerData.tellNumber1,
            tellNumber2:producerData.tellNumber2,
            mobileNumebr:producerData.mobileNumebr,
            email:producerData.email,
            siteURL:producerData.siteURL,
            addressCityId:producerData.addressCityId,
            addressLine1:producerData.addressLine1,
            addressLine2:producerData.addressLine2
        };
        
        tempData.agents = selectedAgents.map(x=>+x.agentId);
        tempData.industries = selectedIndustries.map(x=>+x.industryId);
        tempData.products =selectedProducts.map(x=> +x.productId);
        const response = await postRequest(`${process.env.REACT_APP_BASE_URL}/Producer`,tempData)
    }else{
      setProducerData({...producerData,email:''});
      toast.error('invalid email address');
    }
  };
  const handleCancel=()=>{
    setAgentList([]);
    setProductsList([]);
    setIndustryList([]);
    setProducerData({
        producerCode: "",
        producerName: "",
        registerNumber: "",
        mobileNumebr: "",
        tellNumber1: "",
        tellNumber2: "",
        siteURL: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        continentId: "",
        continent: "",
        countryId: "",
        country: "",
        addressCityId: "",
        stateId: "",
        state: "",
      })
    }
    const handleEditProducer=async()=>{
      const tempData ={
          producerId:+params.producerId,
          producerCode:producerData.producerCode,
          producerName:producerData.producerName,
          producerRegisterNumber:producerData.producerRegisterNumber,
          tellNumber1:producerData.tellNumber1,
          tellNumber2:producerData.tellNumber2,
          mobileNumebr:producerData.mobileNumebr,
          email:producerData.email,
          siteURL:producerData.siteURL,
          addressCityId:producerData.addressCityId,
          addressLine1:producerData.addressLine1,
          addressLine2:producerData.addressLine2
      };
      tempData.agents = selectedAgents.map(x=>+x.agentId);
      tempData.industries = selectedIndustries.map(x=>+x.industryId);
      tempData.products =selectedProducts.map(x=> +x.productId);
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Producer/edit`,tempData);
      if(message === 'ok'){
          toast.success('producer edited successfully!');
          history.push('/producerList');
      }
    }
  return (
    <Fragment>
      <Header12 />
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Producer</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Producer</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      <Box component="form" sx={styles.containerBox}>
        <Box sx={styles.box1}>
          <Box sx={{width:'100%',display:'flex',justifyContent:'initial'}}>
            <Typography
              sx={{
                position:'relative',
                fontSize: "1.5rem",
                fontWeight: "bold",
                top:'-2rem',
                left:'1rem'
              }}
            >
              Producer
            </Typography>
          </Box>
          
          <div style={styles.innerBox}>
            <TextField
              size="small"
              variant="outlined"
              label="Code"
              name="producerCode"
              value={producerData.producerCode}
              onChange={handleChangeProducer}
            />
            <TextField
              required
              size="small"
              variant="outlined"
              label="Name"
              name="producerName"
              error={!producerData.producerName}
              value={producerData.producerName}
              onChange={handleChangeProducer}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              size="small"
              variant="outlined"
              label="Register Number"
              name="registerNumber"
              type='number'
              value={producerData.registerNumber}
              onChange={handleChangeProducer}
            />
            <TextField
              required
              size="small"
              variant="outlined"
              label="Mobile"
              name="mobileNumebr"
              type='number'
              error={!producerData.mobileNumebr}
              value={producerData.mobileNumebr}
              onChange={handleChangeProducer}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              size="small"
              variant="outlined"
              label="Telephone1"
              name="tellNumber1"
              type='number'
              value={producerData.tellNumber1}
              onChange={handleChangeProducer}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Telephone2"
              name="tellNumber2"
              type='number'
              value={producerData.tellNumber2}
              onChange={handleChangeProducer}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              size="small"
              variant="outlined"
              label="WebSite"
              name="siteURL"
              value={producerData.siteURL}
              onChange={handleChangeProducer}
            />
            <TextField
              required
              size="small"
              variant="outlined"
              label="Email"
              type='email'
              name="email"
              error={!producerData.email}
              value={producerData.email}
              onChange={handleChangeProducer}
            />
          </div>
          <div style={styles.innerBox}>
            <Box sx={{ minWidth: 211 }}>
              <FormControl fullWidth>
                <InputLabel id="continent">Continent</InputLabel>
                <Select
                  required
                  size="small"
                  label="Continent"
                  error={!producerData.continent}
                  name="continent"
                  value={producerData.continent}
                  onChange={handleChangeProducer}
                >
                  {continentList.map((continent, index) => (
                    <MenuItem key={index} value={continent.continentName}>
                      {continent.continentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 211 }}>
              <FormControl fullWidth>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  required
                  size="small"
                  label="Country"
                  name="country"
                  error={!producerData.country}
                  value={producerData.country}
                  onChange={handleChangeProducer}
                >
                  {countryList.map((Country, index) => (
                    <MenuItem key={index} value={Country.countryName}>
                      {Country.countryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div style={styles.innerBox}>
            <Box sx={{ minWidth: 211 }}>
              <FormControl fullWidth>
                <InputLabel id="state">state</InputLabel>
                <Select
                  required
                  size="small"
                  label="state"
                  name="state"
                  error={!producerData.state}
                  value={producerData.state}
                  onChange={handleChangeProducer}
                >
                  {stateList.map((state, index) => (
                    <MenuItem key={index} value={state.stateName}>
                      {state.stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 211 }}>
              <FormControl fullWidth>
                <InputLabel id="city">City</InputLabel>
                <Select
                  required
                  error={!producerData.city}
                  size="small"
                  label="City"
                  name="city"
                  value={producerData.city}
                  onChange={handleChangeProducer}
                >
                  {cityList.map((city, index) => (
                    <MenuItem key={index} value={city.cityName}>
                      {city.cityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div style={styles.innerBox}>
            <TextField
              size="small"
              label="address1"
              multiline
              name="addressLine1"
              value={producerData.addressLine1}
              onChange={handleChangeProducer}
              maxRows={4}
              minRows={2}
            />
            <TextField
              size="small"
              label="address2"
              name="addressLine2"
              value={producerData.addressLine2}
              onChange={handleChangeProducer}
              multiline
              maxRows={4}
              minRows={2}
            />
          </div>
          {params.producerId !=='0'?(
            <div style={styles.innerBox}>
            <Box sx={{ minWidth: 211 }}></Box>
            <ButtonGroup variant="contained">
              <Button color="danger" sx={{ color: "#efefef" }} 
                onClick={()=>history.push('/producerList')}
              >
                cancel <Close />
              </Button>
              <Button
                color="edit"
                sx={{ color: "#efefef" }}
                onClick={handleEditProducer}
              >
                edit <Edit />
              </Button>
            </ButtonGroup>
          </div>
          ):(
            <div style={styles.innerBox}>
              <Box sx={{ minWidth: 211 }}></Box>
              <ButtonGroup variant="contained">
                <Button color="danger" sx={{ color: "#efefef" }} 
                  onClick={handleCancel}
                >
                  cancel <Close />
                </Button>
                <Button
                  color="primary"
                  sx={{ color: "#efefef" }}
                  onClick={handleAddProducer}
                  disabled={
                    !producerData.producerName ||
                    !producerData.email ||
                    !producerData.mobileNumebr ||
                    !producerData.continent ||
                    !producerData.country ||
                    !producerData.state ||
                    !producerData.addressCityId
                  }
                >
                  add <Add />
                </Button>
              </ButtonGroup>
            </div>
          )}
          
        </Box>
        <Box sx={styles.box}>
          <Typography
            sx={{ position:'relative', fontSize: "1.5rem", fontWeight: "bold",top:'-1rem',left:'1rem' }}
          >
            Agents
          </Typography>
          <ProducerAgents
            agentsList={agentList}
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
          />
        </Box>
        <Box sx={styles.box}>
          <Typography
            sx={{ position:'relative', fontSize: "1.5rem", fontWeight: "bold",top:'-1rem',left:'1rem' }}
          >
            Products
          </Typography>
          <ProducerProducts
            productsList={productsList}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </Box>
        <Box sx={styles.box}>
          <Typography
            sx={{ position:'relative', fontSize: "1.5rem", fontWeight: "bold",top:'-1rem',left:'1rem' }}
          >
            Industries
          </Typography>
          <ProducerIndustries
            industryList={industryList}
            selectedIndustries={selectedIndustries}
            setSelectedIndustries={setSelectedIndustries}
          />
        </Box>
      </Box>
      <Footer />
    </Fragment>
  );
}

export default Producer;
