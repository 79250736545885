import React,{useEffect,useState} from 'react'
import Footer5 from '../layout/footer5'
import {Header12} from '../layout/header12'
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import Loading from "../components/loading";
import { AccountCircle, Add, Delete, Visibility } from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import DeleteModal from '../components/deleteModal';
import {getRequest} from '../../request';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/sideBar';

const styles = {
    container:{
      margin:'2rem 25rem 0rem 25rem',
      height:'85vh'
    },
    btn:{
      margin:'2rem 0rem'
    }
  }
  const bg = require("./../../images/banner/bnr5.jpg");

function AgentsList() {
    const theme = useTheme();
    const [agents, setAgents] = useState([]);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deletedAgent, setDeletedAgent] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false)
    const token = localStorage.getItem('token');
    let decode={};
    if(token){
      decode = jwtDecode(token);
    }
    useEffect(() => {
      fetchAgents();
    }, [])
    const fetchAgents =async()=>{
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Agent`)
      if(data && Object.keys(data).length){
        setAgents(data);
      }else{
        toast.error(error);
      }
    }
    const handleRemoveSpec= async(id)=>{
        setDeletedAgent(id);  
        setOpen(true);
    }

    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpenUserModal(open);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
          <div className="container">
              <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Agent List</h1>
                  <div className="breadcrumb-row">
                      <ul className="list-inline">
                          <li><Link to="/">Home</Link></li>
                          <li>Agent List</li>
                      </ul>
                  </div>
              </div>
          </div>
        </div>
        <DeleteModal deleteURL={`${process.env.REACT_APP_BASE_URL}/Agent/delete/`} openDeleteModal={open} setOpenDeleteModal={setOpen} parameterName='Agent'/>
        {agents?.length? (
        <Box sx={styles.container}>
          <Box sx={{width:'100%',display:'flex',justifyContent:'space-between'}}>
            <Button variant='contained' sx={styles.btn} onClick={()=>history.push('/agents/0')}>add new agent <Add/></Button>
            {decode.role === 'SuperAdmin'?
            <Button color='secondary' sx={styles.btn} variant='contained' onClick={toggleDrawer( true)} >user options<AccountCircle/></Button>
            :null}
          </Box>
          <TableContainer
          sx={{maxHeight:'78vh'}}
          component={Paper}
          >
          <Table aria-label="simple table">
            <TableHead sx={{backgroundColor:theme.palette.primary.main}}>
              <TableRow>
                <TableCell sx={{color:'#fff'}} align='center'>Agent Code</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Agent Name</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>City</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Remove</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Show Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agents?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align='center' component="th" scope="row">
                    {row.agentCode}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.agentName}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.city.cityName}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    <IconButton
                      aria-label=""
                      onClick={() => handleRemoveSpec(row.productSpecCode)}
                    >
                      <Delete style={{ color: theme.palette.danger.main }} />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    <IconButton
                        aria-label=""
                        onClick={() => history.push(`/agents/${row.agentId}`)}
                    >
                      <Visibility/>
                    </IconButton>
                  </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {agents.length>5?
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={agents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />:null}
        </TableContainer>

        </Box>
        ):(
            <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "82.3vh",
          }}
        >
          <Loading type="cylon" color="#343a40" />
        </Box>
        )}
        <SideBar open={openUserModal} toggleDrawer={toggleDrawer} />
        <Footer5/>
    </div>
  )
}

export default AgentsList