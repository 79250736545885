import { TablePagination } from '@mui/material';
import React, { useState } from 'react';
import './style.css';
const ResponsiveTable = ({ rows, data, title }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (

        <div className='mb-10 border-table' style={{
            marginBottom: "100px", borderRadius: "25px",

        }}>
            <div style={{ overflowX: 'auto', margin: "auto", maxWidth: "90vw", display: "flex", flexDirection: "row" }}>
                <div className='mb-1' style={{
                    whiteSpace: 'nowrap'
                }}>
                    <p className='text-white' style={{ transform: "rotate(-90deg)", marginTop: "100%" }}>
                        {title || data[0].type}
                    </p>
                </div>
                <table >
                    <tr style={{ backgroundColor: "#3A4979", borderRadius: "10px", }}>
                        {rows.map((title, index) => (
                            <th key={index}>{title}</th>

                        ))}
                    </tr>
                    {Object.values(data)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                            return (
                                <tr className='mb-5'>
                                    <td>{item.product}</td>
                                    <td>{item.type}</td>
                                    <td>{item.mInQuantity}</td>
                                    <td>{item.packing}</td>
                                    <td>{item.deliveryTerm}</td>
                                    <td>{item.market}</td>
                                    <td>{item.offeredPrice}</td>
                                </tr>)
                        })}
                </table>
            </div>
            <TablePagination
                sx={{ marginRight: "50%" }}
                style={{ color: "#fff", width: "100%", marginRight: "50%" }}
                rowsPerPageOptions={[5, 10, 20, 40]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default ResponsiveTable;