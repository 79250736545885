import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Footer from '../layout/footer5';
import { Header12 } from '../layout/header12';


var bnr3 = require('./../../images/banner/bnr3.jpg');

// const styles={
//     text:{
//         overflow: "hidden",
//         position: "relative",
//         mixBlendMode: "hard-light",
//         '&:after':{
//             position: "absolute",
//             // content: "",
//             // left: '0px',
//             // top: '0px',
//             // height: '100%',
//             // width: '100%',
//             // // background: "linearGradient(transparent, gray)",
//             // // pointerEvents: 'none'
//         }
//     }
// }

function About2() {
    const [isShowMore, setIsShowMore] = useState(false);
    const services = [{
        title: "Market Analysis",
        details: "MSK Trading Company provides market analysis services thorough qualitative and quantitative assessment of the current market. We provide practical data of volume and value of the market, potential customer segmentations and regular buying patterns.",
    }, {
        title: "Logistic & Distribution",
        details: "MSK Trading Company already has the necessary relationships with the suppliers and producers to perform a role as a bulk breaker, product carrier handling and even container delivery at the Loading & Discharging ports bringing cargos within our international network.",
    }, {
        title: "Finance",
        details: "MSK Trading Company has a wide network of relationship with international prime banks and other financial associations in Europe, Middle/Far East which would be able to set up suitable financial instruments for its specific deals all over the world.",
    }, {
        title: "Petroleum & Petrochemical Products Supply",
        details: "MSK Trading Company in a full range of polymer/plastic raw materials, Chemicals, fertilizers, oil products and fuels through the Europe, CIS, Middle East, China, South east Asia and Africa. we offer various financial solutions to suppliers, to play more effective role in the market.",
    },
    ]
    return (
        <div style={{ backgroundColor: "#000", zIndex: -2 }}>
            <Header12 />
            <div className="page-content">
                <div className="dlab-bnr-inr bg-transparent" style={{ background: "transparent" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry" style={{
                            justifyContent: "center"
                        }}>
                            <h1 className="text-white" style={{ borderBottom: "7px solid #343a40", paddingBottom: "10px", fontSize: "36px", fontWeight: "normal" }} >About us</h1>
                        </div>
                    </div>
                </div>
                <div className="ray-product1 ray-product11" />
                <div className="ray-product2 ray-product22" />
                <div className="content-block">

                    <div className="section-full content-inner p-0 m-0">
                        <div className="container">
                            <div className="row align-items-center img-shaiping">
                                <div className="col-lg-6 col-md-12 m-b30 element" style={{ padding: '60px 60px 0 120px', height: "100%", borderRadius: "0 45px 45px 0" }}>
                                    <div className="our-story" >
                                        <span className=''>OUR STORY</span>
                                        <h2 className="title text-white"><b>MSK</b><br />Trading Company<br /></h2>
                                        <h4 className="title mb-5 text-white">MSK Trading is a pioneer company which is active in variety of Commercial fields such as:</h4>
                                        <ul style={{ marginLeft: '1rem', }}>
                                            <li ><h6 className="title text-white ">Oil Products including Bitumen, Base Oil, RPO, Slack Wax etc.</h6></li>
                                            <li ><h6 className="title text-white">Petrochemicals including HDPE, LDPE, Urea, PVC etc.</h6></li>
                                            <li ><h6 className="title text-white">Oil and Gas Industry Equipment and Instrument.</h6></li>
                                        </ul>
                                        {isShowMore ? (
                                            <p className={`paragText text-white`}>
                                                We cooperate with the largest worldwide manufacturers and genuine producers in various industries such as oil, gas,
                                                petrochemicals and mining. We work together with our local and international partners to support the development of
                                                best companies across the globe. Our mission is to attract and attain customers by continuously supply of best quality products,
                                                and by creating value for our customers through research and development and commitment to highest standards. As part of this mission,
                                                we provide information and clarity on technical issues. We are proud to be the acknowledged partner of choice for our principles, customers,
                                                business partners
                                            </p>
                                        ) : ''}

                                        <Button variant='outlined' style={{ color: 'white', borderColor: '#fff', backgroundColor: "rgba(255, 255, 255, 0.5)", borderRadius: "28px", }} onClick={() => setIsShowMore(!isShowMore)}>{isShowMore ? 'Show Less' : 'Show More'}</Button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 m-b30 our-story-thum" style={{ padding: '20px', height: "100%", borderRadius: "25px 0 0 25px" }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container " style={{ marginTop: "900px", marginBottom: "100px" }}>
                        <div className="dlab-bnr-inr-entry" style={{
                            justifyContent: "center"
                        }}>
                            <h1 className="text-white" style={{ borderBottom: "7px solid #fff", paddingBottom: "10px", fontWeight: "normal" }} >Our Services</h1>
                        </div>
                        <div className='services-cards '>
                            {services.map((item, index) => (
                                <div className=''>
                                    <div className='m-auto services-card text-white p-5' >
                                        <h1 className="text-right display-2 p-0 m-0" > {index + 1}</h1>
                                        <h5 className='display-5'>{item.title}</h5>
                                        <p style={{ fontSize: "12px", lineHeight: "13px" }}>{item.details}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default About2;