import {  Delete, Edit } from '@mui/icons-material';
import { TextField, Typography, ButtonGroup, Button } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../../request';
const style={
  modalBody:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    height:'14rem'
  }
}

function EditGroupComponent({editSelected}) {
    const [group, setGroup] = useState({
        productGroupId:editSelected.id,
        productGroupParentId:'',
        productGroupCode:'',
        productGroupName:'',
        productGroupLocalName:''
    });
    useEffect(() => {
      fetchSelectedGroup()
    }, [])
    const fetchSelectedGroup=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/${editSelected.id}`);
        if(data && Object.keys(data).length){  
            setGroup({
                productGroupId:data.productGroupId,
                productGroupCode:data.productGroupCode,
                productGroupName:data.productGroupName,
                productGroupLocalName:data.productGroupLocalName,
                productGroupParentId:data.productGroupParentId
            })
        }else{
            toast.error(error)
        }
    }
    const handleChange = (event) => {
        setGroup({
          ...group,
          [event.target.name]: event.target.value,
        });
    };
    const handleEditGroup=async()=>{
      const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/edit`,group);
      if(message==='ok'){
          toast.success('group added successfully!');
          window.location.reload();
      }
    }

    const handleRemoveGroup=async()=>{
      const {error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/delete${editSelected.id}`);
      if(error){
          toast.error(error);
      }else{
          toast.success('group deleted successfully!');
          window.location.reload();
      }
    }
  return (
    <div>
      <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant='h5'>Edit Group</Typography>
        <Typography>group:{editSelected.name}</Typography>
      </Box>
        <Box sx={style.modalBody}>
          <TextField variant='outlined' size='small' label='groupCode'  name='productGroupCode' value={group.productGroupCode} onChange={handleChange}/>
          <TextField variant='outlined' size='small' label='groupName' name='productGroupName'  value={group.productGroupName} onChange={handleChange}/>
          <TextField variant='outlined' size='small' label='groupLocalName' name='productGroupLocalName' value={group.productGroupLocalName} onChange={handleChange}/>
          <ButtonGroup variant="contained" sx={{width:'fit-content'}}>
            <Button variant='contained' color='edit'  sx={{color:'#fff'}} onClick={handleEditGroup}>Edit<Edit/></Button>
            <Button variant='contained' color='danger' sx={{color:'#fff'}} onClick={handleRemoveGroup}>Remove<Delete/></Button>
          </ButtonGroup>
        </Box>
    </div>
  )
}

export default EditGroupComponent