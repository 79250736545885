import {
  Add,
  CloudUpload,
  DeleteForever,
  Image,
  TextSnippet
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import DeleteModal from "../components/deleteModal";
import PostBlog from "../components/postBlog";
import Footer from "../layout/footer5";
import { Header12 } from "../layout/header12";

const bg = require("../../images/banner/bnr3.jpg");
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "1rem 10rem",
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // margin: "1rem 10rem",
  },
  dataInsertion: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    justifyContent: "space-between",
  },
  dataInsertionMobile: {
    display: "grid",
    gridTemplateColumns: "auto",
    // justifyContent: "space-between",
  },
  imageProps: {
    width: 211,
    height: 211,
    boxShadow: "0px 0px 4px 2px #ddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "1rem",
  },
};

function BlogSingle() {
  const history = useHistory();
  const [blogKey, setBlogKey] = useState("");
  const [sectionType, setSectionType] = useState(1); //image=1,text=0
  const [counter, setCounter] = useState(0);
  const token = localStorage.getItem("token");
  //   const [postId, setPostId] = useState(0);
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogKeyWords, setBlogKeyWords] = useState([]);
  const [blogSection, setBlogSection] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState({
    title: "",
    image: "",
    text: "",
    keyWords: [],
  });
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const params = useParams();
  let blogId;
  if (params.blogId[0] === ":") {
    blogId = params.blogId.split(":")[1];
  } else {
    blogId = params.blogId;
  }

  const handleChangeFile = async (e) => {
    if (blog.image === "") {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data, message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogSection/Image`, formData);
      if (message === 'ok') {
        // upload image
        const imgUrl =
          data.dbPath;
        setBlog({ ...blog, image: imgUrl });
      }
    } else {
      toast.error("you have already uploaded image!");
    }
    /////////////////////////////////////////////////
  };
  const handleAddBlog = async () => {
    if (counter === 0) {
      const postCreateDate = new Date();

      const blogData = {
        title: blog.title,
        userId: decode.uid ? decode.uid : "",
        createDateTime: postCreateDate.toISOString(),
        updateDateTime: postCreateDate.toISOString(),
      };
      const blogres = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost`, blogData)

      if (blogres.message === 'ok') {
        setCounter(parseInt(counter) + 1);

        const postData = {
          postId: blogres.data,
          type: sectionType,
          order: counter,
          value: sectionType === 0 ? blog.text : blog.image,
          createDateTime: postCreateDate.toISOString(),
          updateDateTime: postCreateDate.toISOString(),
        };
        const { data, message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogSection`, postData)
        if (message === 'ok') {
          toast.success("blog created successfully!");
          history.push(`/`);
        }
      }
    } else {
      const postData = {
        postId: blogId,
        type: sectionType,
        order: counter,
        value: sectionType === 0 ? blog.text : blog.image,
        createDateTime: "2022-09-13T05:45:19.741Z",
        updateDateTime: "2022-09-13T05:45:19.741Z",
      };
      const { message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogSection`, postData)
      if (message === 'ok') {
        toast.success("this section added to your post");
        setBlog({
          title: "",
          image: "",
          text: "",
          keyWords: [],
        });
        window.location.reload();
      }
    }
  };
  const fetchBlog = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/${blogId}`)
    if (data && Object.keys(data).length) {
      setBlogPosts(data);
      setTimeout(() => {
        setIsLoading(true);
      }, 2000);
      const blogRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/sections/${blogId}`)
      if (data && Object.keys(data).length) {
        setBlogSection(blogRes.data);
        if (data.length) {
          setCounter(Math.max(...blogRes.data.map((o) => o.order)) + 1);
        } else {
          setCounter(counter + 1)
        }
      } else {
        toast.error(error);
      }
      const blogKeyWordsRes = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/keywords/${blogId}`)
      if (data && Object.keys(data).length) {
        setBlogKeyWords(blogKeyWordsRes.data);
      }
    } else {
      toast.error(error);
    }
  };
  // const deleteBlog = async () => {
  //   const { status } = await axios.get(
  //     `${process.env.REACT_APP_BASE_URL}/BlogPost/delete/${blogId}`
  //   );
  //   if (status === 200) {
  //     history.push("/");
  //   }
  // };
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleAddKeyWord = async () => {
    const keys = [];
    blog.keyWords.map((x) =>
      keys.push({
        postId: parseInt(blogId),
        value: x,
      })
    );
    const { message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogKeyword`, keys)
    if (message === 'ok') {
      toast.success("keyWords added successfully!");
      setBlog({ ...blog, keyWords: [] });
    }
  };
  useEffect(() => {
    if (blogId !== "0") {
      fetchBlog();
    } else if (decode.role !== 'SuperAdmin') {
      history.push('/')
    }

  }, []);

  return (
    <div style={{
      background: "#000", color: "#fff"
    }}>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        parameterName="this blog"
        deleteURL={`${process.env.REACT_APP_BASE_URL}/BlogPost/delete/${blogId}`}
        destination={"/"}
      />
      <Header12 />
      <div className="page-content">
        <div
          className="overlay-black-middle" style={{ height: "130px" }}
        >
        </div>
        <Box sx={isDesktop ? styles.container : styles.mobileContainer}>
          {blogId == 0 && blogPosts.length ? null : (
            <PostBlog
              blogPosts={blogPosts}
              blogKeyWords={blogKeyWords}
              blogSection={blogSection}
            />
          )}
          {decode.role === 'SuperAdmin' ?
            <Box>
              {counter === 0 ? (
                <div style={{ marginTop: "2rem" }}>
                  <Typography variant="h5">Add New Blog</Typography>
                  {blogId != 0 ? (
                    <Button
                      variant="contained"
                      color="danger"
                      size="small"
                      sx={{ color: "#fff" }}
                      onClick={handleDelete}
                    >
                      delete blog <DeleteForever />
                    </Button>
                  ) : null}
                </div>
              ) : (
                <div style={{ marginTop: "2rem", paddingLeft: '1rem' }}>
                  <Typography variant="h5 text-white">Add New section</Typography>
                  <Button
                    variant="contained"
                    color="danger"
                    size="small"
                    sx={{ color: "#fff" }}
                    onClick={handleDelete}
                  >
                    delete blog <DeleteForever />
                  </Button>
                </div>
              )}

              <Box sx={isDesktop ? styles.dataInsertion : styles.dataInsertionMobile}>
                {counter === 0 ? (
                  <Box sx={{ width: "100%", display: "flex" }}>
                    <TextField
                      label="Title"
                      sx={{ width: "100%", margin: "2rem" }}
                      value={blog.title}
                      onChange={(e) =>
                        setBlog({ ...blog, title: e.target.value })
                      }
                    />
                    <Divider orientation="vertical" />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "1rem",
                  }}
                >
                  {counter !== 0 ? (
                    <Grid item sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          margin: "1rem 0rem",
                          display: "flex",
                        }}
                      >
                        <TextField
                          sx={{
                            width: "70%",
                            "& .MuiInputBase-input ": {
                              color: '#efefef'
                            },

                            "& .MuiSelect-select": {
                              color: '#efefef'
                            },
                            "& .MuiInputBase-root": {
                              color: '#efefef'
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: '#efefef',
                              borderColor: '#efefef !important'
                            },

                            "& .MuiFormLabel-root": {
                              color: '#efefef'
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: '#efefef'
                            },
                            "& .MuiSvgIcon-root ": {
                              color: "#fff"
                            },
                            "& .MuiFormLabel-root": {
                              color: '#efefef'
                            },
                            '& .MuiFormLabel-root ': {
                              "& .MuiAutocomplete-endAdornment": {
                                color: '#efefef'
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#fff',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#fff',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#fff',
                                },
                              },
                            },
                            "& .MuiInputBase-root": {
                              color: '#efefef'
                            },
                          }}
                          label="Key Word"
                          value={blogKey}
                          onChange={(e) => setBlogKey(e.target.value)}
                        />
                        <Box sx={{ width: "15%", paddingLeft: "0.2rem" }}>
                          <Fab
                            color="primary"
                            sx={{ color: "#fff" }}
                            aria-label="add"
                            onClick={() => {
                              setBlog({
                                ...blog,
                                keyWords: [...blog.keyWords, blogKey],
                              });
                              setBlogKey("");
                            }}
                          >
                            <Add />
                          </Fab>
                        </Box>
                      </Box>
                      {blog.keyWords.length ? (
                        <Box
                          sx={{
                            marginRight: "1rem",

                            borderRadius: "0.5rem",
                            width: "70%",
                          }}
                        >
                          <Paper
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: 'center',
                              flexWrap: "wrap",
                              listStyle: "none",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {blog.keyWords.map((data) => {
                              return (
                                <ListItem
                                  sx={{ width: "min-content" }}
                                  key={data.key}
                                >
                                  <Chip
                                    label={data}
                                    onDelete={() =>
                                      setBlog({
                                        ...blog,
                                        keyWords: [
                                          ...blog.keyWords.filter(
                                            (blog) => blog !== data
                                          ),
                                        ],
                                      })
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                          {blog.keyWords.length ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleAddKeyWord}
                            >
                              add keyword
                            </Button>
                          ) : null}
                        </Box>
                      ) : null}
                    </Grid>
                  ) : null}
                </Box>
              </Box>
              <Divider />
              <Box sx={isDesktop ? styles.dataInsertion : styles.dataInsertionMobile}>
                <Box sx={{ display: "flex" }}>
                  {/* ////////// */}
                  <List
                    sx={isDesktop ? {
                      width: "100%",
                      margin: "1rem 3rem",
                      bgcolor: "#f7f7f7",
                    } :
                      {
                        width: "100%",
                        bgcolor: "#f7f7f7",
                      }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        select your section
                      </ListSubheader>
                    }
                  >
                    <ListItemButton onClick={() => setSectionType(1)}>
                      <ListItemIcon>
                        <Image />
                      </ListItemIcon>
                      <ListItemText primary="Image" className="text-dark" />
                    </ListItemButton>
                    {counter !== 0 ? (
                      <ListItemButton onClick={() => setSectionType(0)}>
                        <ListItemIcon>
                          <TextSnippet />
                        </ListItemIcon>
                        <ListItemText primary="Text" className="text-dark" />
                      </ListItemButton>
                    ) : null}
                  </List>
                  <Divider orientation="vertical" />
                </Box>
                <Box sx={{ display: "flex", width: "100%" }}>
                  {sectionType === 1 ? (
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <Box sx={styles.imageProps}>
                          <IconButton color="primary" component="label">
                            <input
                              type="file"
                              onChange={(e) => handleChangeFile(e)}
                              style={{ display: "none" }}
                            />
                            <CloudUpload sx={{ fontSize: "4rem" }} />
                          </IconButton>
                          Upload Image
                        </Box>
                        {counter === 0 ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAddBlog}
                            disabled={!blog.title || !blog.image}
                            sx={{
                              color: "#fff",
                              width: "fit-content",
                              margin: "0rem 1rem",
                            }}
                          >
                            add section
                          </Button>
                        ) : null}
                        {counter !== 0 ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAddBlog}
                            disabled={!blog.text && !blog.image}
                            sx={{
                              color: "#fff",
                              width: "fit-content",
                              margin: "0rem 1rem",
                            }}
                          >
                            add section
                          </Button>
                        ) : null}
                      </Box>
                      <Box sx={{ width: "60%" }}>
                        {blog.image ? (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}` + blog.image}
                            alt=""
                            style={{
                              width: "5rem",
                              height: "4rem",
                              margin: "1rem",
                              borderRadius: "3px",
                            }}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  ) : sectionType === 0 ? (
                    <Box sx={{ width: "75%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",

                          "& .MuiSelect-select": {
                            color: '#efefef'
                          },
                          "& .MuiInputBase-root": {
                            color: '#efefef'
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            color: '#efefef',
                            borderColor: '#efefef !important'
                          },

                          "& .MuiFormLabel-root": {
                            color: '#efefef'
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: '#efefef'
                          },
                          "& .MuiSvgIcon-root ": {
                            color: "#fff"
                          },
                          "& .MuiFormLabel-root": {
                            color: '#efefef'
                          },
                          '& .MuiFormLabel-root ': {
                            "& .MuiAutocomplete-endAdornment": {
                              color: '#efefef'
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#fff',
                              },
                              '&:hover fieldset': {
                                borderColor: '#fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#fff',
                              },
                            },
                          },
                          "& .MuiInputBase-root": {
                            color: '#efefef'
                          },
                        }}
                      >
                        <TextField
                          label="Blog Text"
                          sx={{ width: "100%", margin: "1rem" }}
                          multiline
                          maxRows={6}
                          minRows={3}
                          value={blog.text}

                          style={{
                            "& .MuiSelect-select": {
                              color: '#efefef'
                            },
                            "& .MuiInputBase-root": {
                              color: '#efefef'
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: '#efefef',
                              borderColor: '#efefef !important'
                            },

                            "& .MuiFormLabel-root": {
                              color: '#efefef'
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: '#efefef'
                            },
                            "& .MuiSvgIcon-root ": {
                              color: "#fff"
                            },
                            "& .MuiFormLabel-root": {
                              color: '#efefef'
                            },
                            '& .MuiFormLabel-root ': {
                              "& .MuiAutocomplete-endAdornment": {
                                color: '#efefef'
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#fff',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#fff',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#fff',
                                },
                              },
                            },
                            "& .MuiInputBase-root": {
                              color: '#efefef'
                            },
                          }}
                          onChange={(e) =>
                            setBlog({ ...blog, text: e.target.value })
                          }
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={
                            (!counter === 0 || !blog.text) &&
                            (!counter !== 0 || !blog.text)
                          }
                          onClick={handleAddBlog}
                          sx={{
                            color: "#fff",
                            width: "fit-content",
                            margin: "0rem 1rem",
                          }}
                        >
                          add section
                        </Button>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box> : null
          }

        </Box>


      </div>
      <Footer />
    </div>
  );
}
export default BlogSingle;
