import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from 'react-router-dom';

// 
const noImagFound = require("../../images/our-work/chemicals/No_Image_Available.jpg");
function ProductSubCatSlider({products}){
    const history = useHistory();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: products.length<4?products.length:4,
        slidesToScroll: 1,
        autoplay: true,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1
			  }
			}
		]
    };
    return (
        <>
            <Slider {...settings} className="img-carousel-content primary btn-style-1">
                {products.slice(0,5).map((item, id) => (
                    <div className="item" style={{cursor:'pointer',height:'280px !important'}}>
                        <div className="item-box" style={{height:'280px !important'}}>
                            <div className="item-img" style={{height:'280px !important'}} >
                                <img src={item.images[0]?`${process.env.REACT_APP_IMG_URL}`+item.images[0]:noImagFound} alt="" style={{height:'280px !important'}} onClick={()=>history.push(`/singleProduct/${item.productId}`)}/>
                                {/* <div className="item-info-in">
                                    <ul>
                                        <li><Link to="/shop-cart"><i className="ti-shopping-cart"></i></Link></li>
                                        <li><Link to="#"><i className="ti-eye"></i></Link></li>
                                        <li><Link to="#"><i className="ti-heart"></i></Link></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="item-info text-center text-black p-a10">
                                <h6 onClick={()=>{
                                    history.push(`/singleProduct/${item.productId}`);
                                    window.location.reload();
                            }} className="item-title text-uppercase font-weight-500"><Link>{item.productName}</Link></h6>
                                {/* <ul className="item-review">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star-half-o"></i></li>
                                    <li><i className="fa fa-star-o"></i></li>
                                </ul>
                                <h4 className="item-price">	
                                    <del>{item.oldPrice}</del> 
                                    <span className="text-primary">{item.price}</span>
                                </h4> */}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}
export default ProductSubCatSlider;