import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DeleteModal from './deleteModal';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "81vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
      width:'26rem',
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
    //   width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width:'26rem',
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
      marginBottom:'0.5rem'
    },
  };

function CityLocation({selectedState,cityList, setCityList, selectedCity, setSelectedCity}) {
    const [deletedcityId, setDeletedcityId] = useState(-1);
    const [deleteSuccessful, setDeleteSuccessful] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const code = (Math.random() * 1000000).toFixed(0);
    const [city, setcity] = useState({
        cityCode: `city-${code}`,
        cityName: "",
        cityLocalName: ""
    });
    const handleChangeProducer = (event) => {
        setcity({
          ...city,
          [event.target.name]: event.target.value,
        });
    };
    const handleEditProducer=async()=>{
      
      const currentCity= {
        ...city,
        cityId:selectedCity,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/City/edit`,currentCity)
      if(message ==='ok'){
        cityList[
          cityList.findIndex(
            (x) => x.cityId === selectedCity
          )
        ] = city;
        setCityList(cityList);
        setSelectedCity(-1);
        setcity({
          cityCode: `city-${code}`,
          cityName: "",
          cityLocalName: "",
        });
        toast.success('City edited successfully!');
      }
    }
    useEffect(() => {
      if(deleteSuccessful){
        setCityList(prevstate=>[...prevstate.filter(x=>x.cityId !== deletedcityId)])
        setDeleteSuccessful(false);
        setDeletedcityId(-1);
      }
    }, [deleteSuccessful])
    const handleAddcity=async()=>{
      const tempData = {...city,stateId:selectedState}
      const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/City`,tempData);
        if(message === 'ok'){
            toast.success('City Added Successfully!');
            setCityList((prevState)=>[...prevState,city ]);
            setcity({
              cityCode: `city-${code}`,
              cityName: "",
              cityLocalName: ""
          })
        }
    }
    const handleRemovecity = async(id) => {
      setOpenModal(true);
      setDeletedcityId(id);        
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (
    <div>
        <Box sx={styles.box1}>
            <Typography
              sx={{
                marginRight: "20rem",
                marginTop: "-1.5rem",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              city
            </Typography>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="cityCode"
                value={city.cityCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="cityName"
                value={city.cityName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="cityLocalName"
                sx={{width:'170px'}}
                value={city.cityLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {selectedCity !==-1?(
              <div style={styles.innerBox}>
              <Box sx={{ minWidth: 211 }}>
                <ButtonGroup variant="contained">
                  <Button color="danger" sx={{ color: "#efefef" }} 
                    onClick={()=>{
                        setcity({
                            cityCode: "",
                            cityName: "",
                            cityLocalName: ""
                        });
                        setSelectedCity(-1);
                    }}
                  >
                    cancel <Close />
                  </Button>
                  <Button
                    color="edit"
                    sx={{ color: "#efefef" }}
                    onClick={handleEditProducer}
                  >
                    edit <Edit />
                  </Button>
                </ButtonGroup>
              </Box>
              <Box sx={{ minWidth: 211 }}></Box>
            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{ minWidth: 170 }}>
                  <ButtonGroup variant="contained">
                    <Button
                      color="primary"
                      sx={{ color: "#efefef" }}
                      onClick={handleAddcity}
                      disabled={
                        !city.cityCode ||
                        !city.cityLocalName ||
                        !city.cityName
                      }
                    >
                      add <Add />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box sx={{ minWidth: 170 }}></Box>
              </div>
            )}
          {
            cityList.length?
            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
              <Table aria-label="simple table"  sx={{maxHeight:"50rem",}}>
                <TableHead>
                  <TableRow>
                    <TableCell>city Code</TableCell>
                    <TableCell>city Name</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Remove</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cityList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.cityCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cityName}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="edit"
                          onClick={()=>{
                            setSelectedCity(row.cityId);
                            setcity(cityList.find(
                              (x) =>
                                x.cityId ===
                                row.cityId
                            ))
                          }
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="danger"
                          onClick={()=>handleRemovecity(row.cityId)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {cityList.length>5?
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={cityList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />:null}
            </TableContainer>
            :null
          }
          </Box>
          {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="city" deleteURL={`${process.env.REACT_APP_BASE_URL}/city/delete/${deletedcityId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
            :null}
    </div>
  )
}

export default CityLocation