import { useTheme } from "@emotion/react";
import { AccountCircle, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import Loading from "../components/loading";
import SideBar from "../components/sideBar";
import { Header12 } from "../layout/header12";

const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto ",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "37rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto ",
    justifyContent: "space-around",
    marginTop:'2rem'
  },
  types: {
    backgroundColor: "#ccc",
    borderRadius: "0.5rem 0.5rem 0rem 0rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  newclass1: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "32%",
    top: "21%",
  },
  newclass2: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "6%",
    top: "21%",
  },
};
const bg = require('../../images/banner/bnr5.jpg')
function UserProfile() {
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [open, setOpen] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const [userProps, setUserProps] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    phoneNumber:"",
    role:"",
    id:""
    // twoFactorEnabled: false,
    // phoneNumberConfirmed: false,
    // emailConfirmed: false,
    // lockoutEnabled: false,
  });
  useEffect(() => {
    fetchUserData();
    fetchOrders();
  }, []);
  const params = useParams();
  const handleChange = (event) => {
    setUserProps({
      ...userProps,
      [event.target.name]: event.target.value,
    });
  };
  const fetchOrders = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/Order/all`
    );
    if (data && Object.keys(data).length) {
      setOrderList(data.filter((x) => x.customerId === params.userId));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      toast.error(error);
    }
  };
  const fetchUserData = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/User/GetUser/${params.userId}`
    );
    if (data && Object.keys(data).length) {
      setUserProps({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userName: data.userName,
        phoneNumber:data.phoneNumber,
      });
    } else {
      toast.error(error);
    }
  };
  const handleEditUser = async () => {
    const postData = userProps;
    postData.id = params.userId;
    const { message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/User/EditProfile`,
      postData
    );
    if (message === "ok") {
      toast.error("User Edited Successfully!");
    }
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };
  return (
    <div>
      <Header12 />
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
          <div className="container">
              <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">User Profile</h1>
                  <div className="breadcrumb-row">
                      <ul className="list-inline">
                          <li><Link to="/">Home</Link></li>
                          <li>User Profile</li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      <div style={{ display: "flex", height: "82vh" }}>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-around",
            // height: "50%",
          }}
        >
          <div style={styles.innerBox}>
            <TextField
              variant="outlined"
              label="First Name"
              sx={{ width: "250px" }}
              name="firstName"
              value={userProps.firstName}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              label="Last Name"
              sx={{ width: "250px" }}
              name="lastName"
              value={userProps.lastName}
              onChange={handleChange}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              variant="outlined"
              label="Email"
              sx={{ width: "250px" }}
              name="email"
              value={userProps.email}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              label="UserName"
              sx={{ width: "250px" }}
              name="userName"
              value={userProps.userName}
              onChange={handleChange}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              variant="outlined"
              label="Phone Number"
              sx={{ width: "250px" }}
              name="phoneNumber"
              value={userProps.phoneNumber}
              onChange={handleChange}
            />
            <Box sx={{ width: "250px" }}>
            {decode.role === 'SuperAdmin'?
                <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                    value={userProps.role}
                    label="Role"
                    name='role'
                    onChange={handleChange}
                >
                    <MenuItem value='SuperAdmin'>Super Admin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Expert">Expert</MenuItem>
                    <MenuItem value="SuperVisor">Super Visor</MenuItem>
                </Select>
                </FormControl>
            :null}
            </Box>
          </div>
          {/* <div style={styles.innerBox}>
            <FormControlLabel
              sx={{ width: "250px" }}
              control={
                <Switch
                  checked={userProps.emailConfirmed}
                  name="emailConfirmed"
                  onChange={() =>
                    setUserProps({
                      ...userProps,
                      emailConfirmed: !userProps.emailConfirmed,
                    })
                  }
                />
              }
              label="Email Confirmed"
            />
            <FormControlLabel
              sx={{ width: "250px" }}
              control={
                <Switch
                  checked={userProps.phoneNumberConfirmed}
                  onChange={() =>
                    setUserProps({
                      ...userProps,
                      phoneNumberConfirmed: !userProps.phoneNumberConfirmed,
                    })
                  }
                />
              }
              label="Phone Number Confirmed"
            />
          </div>
          <div style={styles.innerBox}>
            <FormControlLabel
              sx={{ width: "250px" }}
              control={
                <Switch
                  checked={userProps.lockoutEnabled}
                  onChange={() =>
                    setUserProps({
                      ...userProps,
                      lockoutEnabled: !userProps.lockoutEnabled,
                    })
                  }
                />
              }
              label="Lockout Enabled"
            />
            <FormControlLabel
              sx={{ width: "250px" }}
              control={
                <Switch
                  checked={userProps.twoFactorEnabled}
                  onChange={() =>
                    setUserProps({
                      ...userProps,
                      twoFactorEnabled: !userProps.twoFactorEnabled,
                    })
                  }
                />
              }
              label="TwoFactor Enabled"
            />
          </div> */}
          <div style={styles.innerBox}>
            <Box sx={{ width: "250px" }}>
              <Button
                variant="contained"
                color="edit"
                sx={{ color: "#efefef" }}
                onClick={ handleEditUser}
              >
                edit
                <Edit />
              </Button>
            </Box>
            <Box sx={{ width: "250px" }}><FormControlLabel
              control={
                <Switch
                  checked={isChangePassword}
                  onChange={() =>
                    setIsChangePassword(!isChangePassword)
                  }
                />
              }
              label="Change Password"
            /></Box>
          </div>
          {isChangePassword?
            <Box>
                <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Typography sx={{color:theme.palette.danger.main, backgroundColor:'pink',padding:'0.5rem',width:'fit-content',borderRadius:'3px'}} variant='body1'><InfoOutlinedIcon/>password must be more than 8 digits & include numbers, characters and special Characters!</Typography>
                </Box>
                <div style={styles.innerBox}>
                    <Box sx={{width:'90%'}}>
                        <FormControl sx={{ m: 1, width: "250px" }} variant="outlined">
                            <InputLabel htmlFor="change-password">Change Password</InputLabel>
                            <OutlinedInput
                            id="change-password"
                            type={isShowPassword ? "text" : "password"}
                            //   value={signUpValues.password}
                            //   onChange={handleChangeSignUp("password")}
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                    setIsShowPassword(!isShowPassword)
                                    }
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {isShowPassword ? (
                                    <VisibilityOff />
                                    ) : (
                                    <Visibility />
                                    )}
                                </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{width:'250px'}}></Box>
                </div>
            </Box>
          :null}
        </Box>
        <Divider variant="middle" orientation="vertical" />
        <Box sx={{ width: "50%", padding: "2rem" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {orderList.length?
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Order List
            </Typography>
            :null}
            {decode.role === 'SuperAdmin'?
            <Button
              color="secondary"
              variant="contained"
              onClick={toggleDrawer(true)}
            >
              user options <AccountCircle />
            </Button>:null}
          </Box>
          {!isLoading ? (
            <Box sx={styles.container}>
              {orderList.length ? (
                <TableContainer
                  sx={{ maxHeight: "78vh", marginTop: "1rem" }}
                  component={Paper}
                >
                  <Table aria-label="simple table">
                    <TableHead
                      sx={{ backgroundColor: theme.palette.primary.main }}
                    >
                      <TableRow>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Product Code
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Product Name
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Product Amount
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Costumer
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          producer Name
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Producer Mobile
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Producer Email
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          City
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderList?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {row.product.productCode}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.product.productName}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.amount ? row.amount : row.qty}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.Costumer ? row.Costumer : ""}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.producer.producerName}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.producer.mobileNumebr}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.producer.email}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.city.cityName}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    height: "20rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5">No Order Found!</Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "82.3vh",
              }}
            >
              <Loading type="cylon" color="#343a40" />
            </Box>
          )}
        </Box>
      </div>
      <div></div>
      <SideBar open={open} toggleDrawer={toggleDrawer} />

    </div>
  );
}

export default UserProfile;
