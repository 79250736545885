// import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './markup/router';
import './plugins/flaticon/beer/flaticon.css';
import './plugins/flaticon/flaticon.css';
import './plugins/fontawesome/css/font-awesome.min.css';
import './plugins/line-awesome/css/line-awesome.min.css';


const theme = createTheme({
  palette: {
    primary: {
      main: '#3478ee'
    },
    secondary: {
      main: '#222831'
    },
    danger:{
      main: '#D61C4E'
    },
    edit:{
      main: '#17b151'
    },
  },
  typography: {
    fontFamily: [
      'Gantari'
    ].join(','),
  },
});

function App(){ 
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer position="bottom-left" newestOnTop />
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
