import React, { useEffect,Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Header12} from '../layout/header12';
import Footer5 from '../layout/footer5';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { useState } from 'react';
import MagazineSidebar from '../element/magazineSideBar';

const bg = require('../../images/banner/bnr5.jpg')

function MagazineList (){
	const [magazineLists, setMagazineLists] = useState([]);
	const [searchWord, setSearchWord] = useState('');
	const history = useHistory();
	useEffect(() => {
	  fetchMagazines();
	}, [])
	
	const fetchMagazines=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Article`);
        if(data && Object.keys(data).length){
			for(let i=data.length-1;i>=0;i--){
				const d=new Date(data[i].serverDateTime);
				const newData = {...data[i],serverDateTime:d.toString()}
				setMagazineLists((prevstate)=>[...prevstate,newData]);
			}
        }else{
            toast.error(error);
        }
    }
    return (
        <Fragment>
            <Header12/>
			<div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Magazine List</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Magazine List</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="content-area">
					<div className="container">
						<div className="row">
							<MagazineSidebar  magazineLists={magazineLists} setSearchWord={setSearchWord} />
							<div className="col-lg-9">
							{magazineLists.length?
								magazineLists.filter(x=>x.title.toLowerCase().includes(searchWord.toLowerCase())).map((x,item)=>(
									<div className="blog-post blog-md clearfix" key={item}>
										<div className="dlab-post-media dlab-img-effect zoom-slow"> 
											<img src={`${process.env.REACT_APP_IMG_URL}`+x.picture} alt="" style={{height:'15rem'}}/>
										</div>
										<div className="dlab-post-info">
											<div className="dlab-post-meta">
												<ul>
													<li className="post-date"> <strong>{x.serverDateTime.split(' ')[2]} {x.serverDateTime.split(' ')[1]}</strong> <span> {x.serverDateTime.split(' ')[3]}</span>  </li>
													<li className="post-author"> By <Link >{x.user}</Link> </li>
												</ul>
											</div>
											<div className="dlab-post-title">
												<h4 className="post-title">{x.title.width>50?<h4 className="post-title1"><Link to={`/magazine/${x.id}`}>{x.title.slice(0,50)} <b>{'   ...'}</b></Link></h4>:
												<h4 className="post-title1"><Link to={`/magazine/${x.id}`}>{x.title}</Link></h4>}</h4>
											</div>
											<div className="dlab-post-text">
												<p style={{maxHeight:'5.5rem',overflow:'hidden'}}>{x.description}</p>
											</div>
											<div className="dlab-post-readmore"> 
												<Link to={`/magazine/:${x.id}`} title="READ MORE" rel="bookmark" className="site-button">READ MORE
													<i className="ti-arrow-right"></i>
												</Link>
											</div>
										</div>
									</div>
								)):
								null
							}							
							</div>
						</div>
					</div>
                </div>
            </div>                   
            <Footer5/>                
        </Fragment>
    )
    
}
export default MagazineList;