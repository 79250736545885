import { HighlightOff } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import CommentReplying from "./commentReplying";


function PostBlog({ blogPosts, blogKeyWords, blogSection }) {
  const [blogDate, setBlogDate] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [repliedCommentId, setRepliedCommentId] = useState(-1);
  const [repliedComment, setRepliedComment] = useState({});
  // const [replayIterationCounter, setReplayIterationCounter] = useState(0)
  const postCreateDate = new Date();
  // let replayIterationCounter=0;
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const [comment, setComment] = useState({
    postId: 0,
    replayId: 0,
    userId: decode.uid ? decode.uid : "",
    value: "",
    createDateTime: postCreateDate.toISOString(),
    updateDateTime: postCreateDate.toISOString(),
  });

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    const { message } = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogComment`, comment);
    if (message === 'ok') {
      toast.success("comment has been sent");
      window.location.reload();
    }
  };
  const fetchComments = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/comments/${blogPosts.id}`)
    if (data && Object.keys(data).length) {
      setCommentList(data);
    } else {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (repliedCommentId !== -1) {
      fetchRepliedComment();
      setComment({ ...comment, replayId: repliedCommentId });
    }
  }, [repliedCommentId]);

  const fetchRepliedComment = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogComment/${repliedCommentId}`)
    if (data && Object.keys(data).length) {
      setRepliedComment(data);
    } else {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (blogPosts.createDateTime) {
      const date = new Date(blogPosts.createDateTime);
      setBlogDate(date.toString().split(" "));
    }
    if (blogPosts.id) {
      fetchComments();
      setComment({ ...comment, postId: blogPosts.id });
    }
  }, [blogPosts]);

  return (
    <div>
      <div className="blog-post blog-single">
        <div>
          <h4 className="post-title m-t0 text-white p-5">
            {blogPosts.title && (
              <h4>
                <CreateIcon />
                {blogPosts.title.charAt(0).toUpperCase() + blogPosts.title.slice(1)}</h4>
            )}
          </h4>
          <div className="d-flex row">
            <div className="col-lg-6 p-5 " >
              {blogPosts.photo ? (
                <img
                  style={{ marginBottom: "3rem", borderRadius: "15px", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                  // className='blog-header-img'
                  width={'100%'}
                  src={`${process.env.REACT_APP_IMG_URL}` + blogPosts.photo}
                  alt=""
                />

              ) : null}
            </div>
            <div className="col-lg-6 p-5">
              <div className="dlab-post-meta" style={{ paddingLeft: '0.2rem' }}>
                <ul>
                  {blogPosts.user ? (
                    <li className="post-author">
                      <SupervisedUserCircleIcon />
                      {" "}
                      By <Link>{blogPosts.user}</Link>{" "}
                    </li>
                  ) : null}
                  {blogDate.length ? (
                    <li className="post-date">
                      {" "}
                      <DateRangeIcon />
                      <strong>
                        {blogDate[2]} {blogDate[1]}
                      </strong>{" "}
                      <span> {blogDate[3]}</span>{" "}
                    </li>
                  ) : null}

                </ul>
              </div>

              {blogSection.length ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {blogSection
                    .filter((x) => x.order !== 0)
                    ?.map((x, index) => (
                      <div key={x.id}>
                        {x.type === 1 ? (
                          <img src={`${process.env.REACT_APP_IMG_URL}` + x.value} alt="" className='blog-header-img' />
                        ) : (
                          <div>
                            <p style={{ textAlign: "justify" }}>{x.value}</p>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          </div>

        </div>

        <div className="dlab-post-tags clear">
          <div className="post-tags">
            {blogKeyWords.length
              ? blogKeyWords.map((keyWord) => (
                <Link key={keyWord.id}>
                  {keyWord.value}{" "}
                </Link>
              ))
              : null}
          </div>
        </div>
      </div>
      {blogPosts.id ?
        <div className="clear" id="comment-list" style={{ padding: '0rem 0.5rem' }}>
          <div className="comments-area" id="comments">
            <h2 className="comments-title text-white">{commentList.length} Comments</h2>
            <div className="clearfix">
              <ol className="comment-list">
                {/* {continue here} */}
                {commentList
                  .filter((x) => x.replayId === 0)
                  ?.map((comment, index) => (
                    <CommentReplying
                      key={index}
                      comment={comment}
                      setRepliedCommentId={setRepliedCommentId}
                      commentList={commentList}
                    />
                  ))}

              </ol>

              <div className="comment-respond text-white" id="respond">
                <h4 className="comment-reply-title" id="reply-title">
                  {repliedCommentId !== -1 ? "Reply Comment" : "Leave a Comment"}{" "}
                  {repliedCommentId !== -1 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                        boxShadow: "0px 0px 2px 2px #ddd",
                        borderRadius: "5px",
                        bgcolor: "#efefef",
                      }}
                    >
                      <Typography component="h6">
                        {repliedComment.value}
                      </Typography>
                      <IconButton
                        aria-label=""
                        onClick={() => {
                          setRepliedCommentId(-1);
                          setRepliedComment({});
                          setComment({ ...comment, replayId: 0 });
                        }}
                      >
                        <HighlightOff sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </Box>
                  ) : null}
                  <small>
                    {" "}
                    <Link
                      style={{ display: "none" }}
                      to="#"
                      id="cancel-comment-reply-link"
                      rel="nofollow"
                    >
                      Cancel reply
                    </Link>{" "}
                  </small>{" "}
                </h4>
                <form
                  className="comment-form"
                  id="commentform"
                  onSubmit={handleSubmitComment}
                >
                  <p className="comment-form-comment">
                    <label for="comment">Comment</label>
                    <textarea
                      rows="8"
                      name="comment"
                      value={comment.value}
                      onChange={(e) =>
                        setComment({ ...comment, value: e.target.value })
                      }
                      placeholder="Comment"
                      id="comment"
                    ></textarea>
                  </p>
                  <p className="form-submit">
                    <input
                      type="submit"
                      value="Post Comment"
                      disabled={!comment.value}
                      className="submit"
                      id="submit"
                      name="submit"
                    />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        : null}
    </div>
  );
}

export default PostBlog;
