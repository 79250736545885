import React from "react";
import {
  Autocomplete,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

function ProducerIndustries({
  industryList,
  selectedIndustries,
  setSelectedIndustries,
}) {
  return (
    <div style={{margin:'0px 10px'}}>
      <Autocomplete
        title="Search Industries"
        disablePortal
        //   value={filteredItem.name}
        //   getOptionLabel={(agent) => agent.agentName}
        // getOptionSelected={(option, value) => option.name === value.name}
        onChange={(e, value) => {
          if (value) {
            setSelectedIndustries((prevstate) => [
              ...prevstate,
              {
                industryId: value.split(" ")[0],
                industryName: value.split(" ")[1],
              },
            ]);
          }
        }}
        options={industryList.map((x) => x.industryId + " " + x.industryName)}
        sx={{ width: "100%",paddingBottom: "2rem" }}
        renderInput={(params) => (
          <TextField {...params} label={`Search Industries...`} />
        )}
      />
      {selectedIndustries.length?(
      <TableContainer
        // sx={
        //   productProducers.length
        //     ? { width: "75%", margin: "0.5rem" }
        //     : { width: "100%", margin: "0.5rem" }
        // }
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Industry Id</TableCell>
              <TableCell>Industry Name</TableCell>
              <TableCell align="right">remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedIndustries?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.industryId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.industryName}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="danger"
                    onClick={() =>
                      setSelectedIndustries((prevstate) => [
                        ...prevstate.filter(
                          (x) => x.industryId != row.industryId
                        ),
                      ])
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ):null}
    </div>
  );
}

export default ProducerIndustries;
