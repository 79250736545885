import React from 'react';
import { Link } from 'react-router-dom';

import { Search } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Sidebar = ({ blogLists, setSearchWord, keyWords }) => {
    const [searchBox, setSearchBox] = useState('');
    const history = useHistory();
    const handleSearch = (e) => {
        e.preventDefault();
        setSearchWord(searchBox);
    }
    return (
        <div className="col-lg-3" style={{ zIndex: 0 }}>
            <aside className="side-bar sticky-top">
                <div className="widget">
                    <h5 className="widget-title style-1 textInBlog">Search</h5>
                    <div className="search-bx style-1">
                        <form role="search" method="post" onSubmit={handleSearch}>

                            {/* <span className="input-group-btn">
                                <button type="submit" className="fa fa-search text-primary"></button>
                            </span> 
                            <input name="text"  placeholder="Enter your keywords..." value={searchBox} onChange={(e)=>setSearchBox(e.target.value)} type="text"/> */}
                            <div className='searchBoxHeader searchInputBlog'>
                                <input type="text" placeholder='Search...' className='searchInput' id='searchInput' value={searchBox} onChange={(e) => setSearchBox(e.target.value)} />
                                <Search style={{ color: '#fff', cursor: 'pointer' }} onClick={handleSearch} />
                            </div>

                        </form>
                    </div>
                </div>
                <div className="widget recent-posts-entry" style={{ minWidth: "294px" }}>
                    <h5 className="widget-title style-1 textInBlog">Recent Posts</h5>
                    <div className="widget-post-bx">
                        {
                            blogLists.slice(0, 3).map(x =>
                                <div className="widget-post clearfix blogListBg" key={x.id}>
                                    <div className="dlab-post-media1">
                                        <img src={`${process.env.REACT_APP_IMG_URL}` + x.photo} width="200" height="143" alt="" />
                                    </div>
                                    <div className="dlab-post-info mx-2 px-2">
                                        <div className="dlab-post-meta">
                                            <ul>
                                                <li className="post-date"> <strong>{x.date.split(' ')[2]} {x.date.split(' ')[1]}</strong> <span> {x.date.split(' ')[3]}</span> </li>
                                                <li className="post-author"> By <Link >{x.user}</Link> </li>
                                            </ul>
                                        </div>
                                        <div className="dlab-post-header">
                                            <h6 className="post-title " style={{ cursor: 'pointer', color: '#efefef' }} onClick={() => history.push(`/blog/:${x.id}`)}>{x.title?.slice(0, 18)}...</h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>

                <div className="widget widget_tag_cloud radius">
                    <h5 className="widget-title style-1 textInBlog">Tags</h5>
                    <div className="tagcloud ">
                        {keyWords.length ? keyWords.map(keys =>
                            <Link >{keys}</Link>
                        ) : <Typography variant='h6' className='textInBlog' sx={{ fontWeight: 'bold' }}>NO TAG FOUND!</Typography>
                        }

                    </div>
                </div>
            </aside>
        </div>
    );
}

export default Sidebar;